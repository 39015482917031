import {
  ADD_TRANSPARENCY_FILE_LIST,
  DELETE_TRANSPARENCY_FILE_LIST,
  EDIT_TRANSPARENCY_FILE_LIST,
  GET_TRANSPARENCY_FILE_LIST,
} from "../../Const/ActionTypes";

const initialState = {
  listTransparency: [],
  transparencyFileToAdd: {
    id: null,
    communInformation: undefined, //done

    suspensionsSection: undefined, // done

    atuSection: undefined, // done

    postAndInscriptionSection: undefined, //done

    commentSection: undefined, // done

    delivranceAndPrescriptionCondSection: undefined, // done

    indicationSection: undefined, // done

    contextSection: undefined, // done

    medicalNeedSection: undefined, // done

    pertinentComparatorSection: undefined, // done

    availableDataAnalyseSection: undefined, // done

    discussionAndResumeSection: undefined, // done

    therapeuticStrategySection: undefined, // done

    ispSection: undefined, // done

    asmrSection: undefined, // done

    populationCibleSection: undefined, // done

    patientsNumberSection: undefined, // done

    reportSection: undefined, // done => Compte rendu

    contradictoryPhaseSection: undefined, // done

    itrSection: undefined, // done

    smrAsmrModification: undefined, // done

    keyDocumentsSection: undefined, // done

    realWorldDataSection: undefined, // done

    qualityOfLifeSection: undefined, // done

    indirectComparaisonSection: undefined, // done

    treatmentLineSection: undefined, // done

    rationaleCommentSection: undefined, // done

    evalutionLinks: undefined, // done

    textSection: undefined, // done

    comparatorsSection: undefined, // done

    contributionPatientSection: undefined, // NOT YET

    syntheseSection: undefined, // missing in Back-end
    smrSection: undefined, // not needed for the moment
  },
};

export default function transparency(state = initialState, action) {
  switch (action.type) {
    case GET_TRANSPARENCY_FILE_LIST: {
      return Object.assign({}, state, {
        listTransparency: action.payload,
      });
    }
    case EDIT_TRANSPARENCY_FILE_LIST: {
      return Object.assign({}, state, {
        listTransparency: state.listTransparency.map((element) =>
          element.id === action.payload.id ? action.payload : element
        ),

        transparencyFileToAdd: { id: null },
      });
    }
    case ADD_TRANSPARENCY_FILE_LIST: {
      return Object.assign({}, state, {
        listTransparency: [action.payload].concat(state.listTransparency),
        transparencyFileToAdd: action.payload,
      });
    }

    case DELETE_TRANSPARENCY_FILE_LIST: {
      return Object.assign({}, state, {
        listTransparency: [
          ...state.listTransparency.filter(
            (element) => element.id !== action.payload
          ),
        ],
      });
    }
    default:
      return state;
  }
}
