/* eslint import/no-anonymous-default-export: "off" */

import {
  ADD_FILE_CLINICAL_TRIAL,
  DELETE_FILE_CLINICAL_TRIAL,
  EDIT_FILE_CLINICAL_TRIAL,
  GET_CLINICATL_TRIALS_FILE_LIST,
  VERIFY_NCT,
} from "../../Const/ActionTypes";

const initialState = {
  listClinicalTrials: [],
  ClinicalTrialsFileToAdd: {
    id: null,
  },
  valideNCT: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CLINICATL_TRIALS_FILE_LIST: {
      return Object.assign({}, state, {
        listClinicalTrials: action.payload,
      });
    }

    case ADD_FILE_CLINICAL_TRIAL: {
      return Object.assign({}, state, {
        listClinicalTrials: [action.payload].concat(state.listClinicalTrials),
        ClinicalTrialsFileToAdd: action.payload,
      });
    }

    case EDIT_FILE_CLINICAL_TRIAL: {
      return Object.assign({}, state, {
        listClinicalTrials: [
          action.payload,
          ...state.listClinicalTrials.filter(
            (element) => element.id !== action.payload.id
          ),
        ],
        ClinicalTrialsFileToAdd: { id: null },
      });
    }

    case DELETE_FILE_CLINICAL_TRIAL: {
      return Object.assign({}, state, {
        listClinicalTrials: [
          ...state.listClinicalTrials.filter(
            (element) => element.id !== action.payload
          ),
        ],
      });
    }

    case VERIFY_NCT: {
      return Object.assign({}, state, {
        valideNCT: action.payload,
      });
    }

    default:
      return state;
  }
}
