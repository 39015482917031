/* eslint import/no-anonymous-default-export: "off" */

import {
  GET_CDF_FILE_LIST,
  ADD_FILE_CDF,
  EDIT_FILE_CDF,
  GET_USER_OPTIONS,
  EDIT_USER_OPTIONS,
  DELETE_FILE_CDF,
} from '../../Const/ActionTypes';

const initialState = {
  listCDF: [],
  cdfFileToAdd: {
    id: null,
    communInformation: undefined,
    economicRationaleSection: undefined,
    clinicalRationaleSection: undefined,
    scopeSection: undefined,
    decisionSection: undefined,
    keyDocumentsSection: undefined,
    conclusionsOnEvidenceSection: undefined,
    referencesSection: undefined,
    economicAnalysisSection: undefined,
    costOfTreatmentSection: undefined,
    budgetImpactSection: undefined,
    otherCommentSection: undefined,
    realWorldDataSection: undefined,
    qualityOfLifeSection: undefined,
    indirectComparaisonSection: undefined,
    rationaleCommentSection: undefined,
    secondBudgetImpactSection: undefined,
    economicEvaluationSection: undefined,
    treatmentLineSection: undefined,
  },
  userOptions: {
    id: undefined,
    userId: undefined,
    cdfSections: undefined,
    cdfHeader: undefined,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CDF_FILE_LIST: {
      return Object.assign({}, state, {
        listCDF: action.payload,
      });
    }
    case EDIT_FILE_CDF: {
      return Object.assign({}, state, {
        listCDF: [
          action.payload,
          ...state.listCDF.filter(element => element.id !== action.payload.id),
        ],
        cdfFileToAdd: {id: null},
      });
    }
    case ADD_FILE_CDF: {
      return Object.assign({}, state, {
        listCDF: [action.payload].concat(state.listCDF),
        cdfFileToAdd: action.payload,
      });
    }

    case GET_USER_OPTIONS: {
      return Object.assign({}, state, {
        userOptions: action.payload,
      });
    }
    case EDIT_USER_OPTIONS: {
      return Object.assign({}, state, {
        userOptions: {
          ...action.payload,
          cdfSections:
            action.payload.cdfSections === undefined
              ? state.userOptions.cdfSections
              : action.payload.cdfSections,
          cdfHeader:
            action.payload.cdfHeader === undefined
              ? state.userOptions.cdfHeader
              : action.payload.cdfHeader,
        },
      });
    }
    case DELETE_FILE_CDF: {
      return Object.assign({}, state, {
        listCDF: [
          ...state.listCDF.filter(element => element.id !== action.payload),
        ],
      });
    }
    default:
      return state;
  }
}
