/* eslint import/no-anonymous-default-export: "off" */

import {
    GET_PERU_FILE_LIST,
    ADD_FILE_PERU,
    EDIT_FILE_PERU,
    DELETE_FILE_PERU,
  } from "../../Const/ActionTypes";
  
  const initialState = {
    listPeru: [],
    peruFileToAdd: {
      id: null,
      communInformation: undefined,
      evalutionLinks: undefined,
      section2: undefined,
      section3: undefined,
      section4: undefined,
      rationaleCommentSection: undefined,
      nctNumbersSection: undefined,
      treatmentLineSection: undefined,
      realWorldDataSection: undefined,
      qualityOfLifeSection: undefined,
      indirectComparaisonSection: undefined,
      secondBudgetImpactSection: undefined,
      costSection: undefined,
    },
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_PERU_FILE_LIST: {
        return Object.assign({}, state, {
          listPeru: action.payload,
        });
      }
      case EDIT_FILE_PERU: {
        return Object.assign({}, state, {
          listPeru: state.listPeru.map((element) =>
            element.id === action.payload.id ? action.payload : element
          ),
          peruFileToAdd: { id: null },
        });
      }
      case ADD_FILE_PERU: {
        return Object.assign({}, state, {
          listPeru: [action.payload].concat(state.listPeru),
          peruFileToAdd: action.payload,
        });
      }
  
      case DELETE_FILE_PERU: {
        return Object.assign({}, state, {
          listPeru: [
            ...state.listPeru.filter((element) => element.id !== action.payload),
          ],
        });
      }
      default:
        return state;
    }
  }
  