/* eslint import/no-anonymous-default-export: "off" */

import {
  GET_TLV_FILE_LIST,
  ADD_FILE_TLV,
  EDIT_FILE_TLV,
  DELETE_FILE_TLV,
} from "../../Const/ActionTypes";

const initialState = {
  listTlv: [],
  tlvFileToAdd: {
    id: null,
    communInformation: undefined,
    economicRationaleSection: undefined,
    clinicalRationaleSection: undefined,
    scopeSection: undefined,
    decisionSection: undefined,
    keyDocumentsSection: undefined,
    conclusionsOnEvidenceSection: undefined,
    referencesSection: undefined,
    economicAnalysisSection: undefined,
    costOfTreatmentSection: undefined,
    budgetImpactSection: undefined,
    otherCommentSection: undefined,
    realWorldDataSection: undefined,
    qualityOfLifeSection: undefined,
    indirectComparaisonSection: undefined,
    rationaleCommentSection: undefined,
    secondBudgetImpactSection: undefined,
    // economicEvaluationSection: undefined,
    treatmentLineSection: undefined,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TLV_FILE_LIST: {
      return Object.assign({}, state, {
        listTlv: action.payload,
      });
    }
    case EDIT_FILE_TLV: {
      return Object.assign({}, state, {
        listTlv: state.listTlv.map((element) =>
          element.id === action.payload.id ? action.payload : element
        ),
        // listTlv: [
        //   action.payload,
        //   ...state.listTlv.filter(element => element.id !== action.payload.id),
        // ],
        tlvFileToAdd: { id: null },
      });
    }
    case ADD_FILE_TLV: {
      return Object.assign({}, state, {
        listTlv: [action.payload].concat(state.listTlv),
        tlvFileToAdd: action.payload,
      });
    }

    case DELETE_FILE_TLV: {
      return Object.assign({}, state, {
        listTlv: [
          ...state.listTlv.filter((element) => element.id !== action.payload),
        ],
      });
    }
    default:
      return state;
  }
}
