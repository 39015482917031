import React from "react";

const TransparencesFileManagment = React.lazy(() =>
  import(
    "../Components/FileManagement/France/Transparences/TransparencesFileManagment"
  )
);

const CategoryManagement = React.lazy(() =>
  import("../Components/DataManagement/CategoryManagement/CategoryManagement")
);

const DciManagment = React.lazy(() =>
  import("../Components/DataManagement/DciManagment/DciManagment")
);

const DiseaseManagment = React.lazy(() =>
  import("../Components/DataManagement/DiseaseManagement/DiseaseManagment")
);

const NewAgencyFileManagement = React.lazy(() =>
  import(
    "../Components/DataManagement/DrugCenterNewAgency/NewAgencyFileManagement"
  )
);

const HtaRoadMapManagement = React.lazy(() =>
  import(
    "../Components/DataManagement/HtaRoadMapManagement/HtaRoadMapManagement"
  )
);

const IngredientManagement = React.lazy(() =>
  import(
    "../Components/DataManagement/IngredientManagement/IngredientManagement"
  )
);

const SpecialityManagement = React.lazy(() =>
  import(
    "../Components/DataManagement/SpecialityManagement/SpecialityManagement"
  )
);

const TaskManagment = React.lazy(() =>
  import("../Components/DataManagement/TaskManagment/TaskManagment")
);

const CADTHFileManagement = React.lazy(() =>
  import("../Components/FileManagement/America/CADTH/CADTHFileManagement")
);

const FDAFileManagement = React.lazy(() =>
  import("../Components/FileManagement/America/FDA/FDAFileManagement")
);

const ICERFileManagment = React.lazy(() =>
  import("../Components/FileManagement/America/ICER/ICERFileManagment")
);

const PCODRFileManagement = React.lazy(() =>
  import("../Components/FileManagement/America/pCODR/PCODRFileManagement")
);

const PeruFileManagement = React.lazy(() =>
  import("../Components/FileManagement/America/PERU/PeruFileManagement")
);

const ACEFileMangement = React.lazy(() =>
  import("../Components/FileManagement/APAC/ACE/ACEFileMangement")
);

const HiraFileManagement = React.lazy(() =>
  import("../Components/FileManagement/APAC/HIRA/HiraFileManagement")
);

const NHSAFileManagment = React.lazy(() =>
  import("../Components/FileManagement/APAC/NHSA/NHSAFileManagment")
);

const NihtaFileManagement = React.lazy(() =>
  import("../Components/FileManagement/APAC/NIHTA/NihtaFileManagement")
);

const NMPAFileManagment = React.lazy(() =>
  import("../Components/FileManagement/APAC/NMPA/NMPAFileManagment")
);

const PBACFileManagment = React.lazy(() =>
  import("../Components/FileManagement/APAC/PBAC/PBACFileManagment")
);

const PMDAFileManagement = React.lazy(() =>
  import("../Components/FileManagement/APAC/PMDA/PMDAFileManagement")
);

const EconomicEvaluationManagement = React.lazy(() =>
  import(
    "../Components/FileManagement/EcoClinicalTemplates/EconomicEvaluation/EconomicEvaluationManagement"
  )
);

const ClinicalTrialsFileManagment = React.lazy(() =>
  import(
    "../Components/FileManagement/EcoClinicalTemplates/EssaisCliniquesAspiration/ClinicalTrialsFileManagment"
  )
);

const AotmitFileManagement = React.lazy(() =>
  import(
    "../Components/FileManagement/EuropeanFiles/AOTMIT/AotmitFileManagement"
  )
);

const AwmsgFileManagement = React.lazy(() =>
  import("../Components/FileManagement/EuropeanFiles/AWSMG/AwmsgFileManagement")
);

const CDFFileManagment = React.lazy(() =>
  import("../Components/FileManagement/EuropeanFiles/CDF/CDFFileManagment")
);

const EMAFileManagment = React.lazy(() =>
  import("../Components/FileManagement/EuropeanFiles/EMA/EMAFileManagment")
);

const GBAFileManagment = React.lazy(() =>
  import("../Components/FileManagement/EuropeanFiles/GBA/GBAFileManagment")
);

const InamiFileManagment = React.lazy(() =>
  import("../Components/FileManagement/EuropeanFiles/Inami/InamiFileManagment")
);

const IQWIGFileManagment = React.lazy(() =>
  import("../Components/FileManagement/EuropeanFiles/IQWIG/IQWIGFileManagment")
);

const ItalyFileManagement = React.lazy(() =>
  import("../Components/FileManagement/EuropeanFiles/Italy/ItalyFileManagement")
);

const NcpeFileManagement = React.lazy(() =>
  import("../Components/FileManagement/EuropeanFiles/Ncpe/NcpeFileManagement")
);

const NICEFileManagment = React.lazy(() =>
  import("../Components/FileManagement/EuropeanFiles/NICE/NICEFileManagment")
);

const NtcouncilFileManagement = React.lazy(() =>
  import(
    "../Components/FileManagement/EuropeanFiles/Ntcouncil/NtcouncilFileManagement"
  )
);

const SmcFileManagment = React.lazy(() =>
  import("../Components/FileManagement/EuropeanFiles/SMC/SmcFileManagment")
);

const SpainFileManagement = React.lazy(() =>
  import("../Components/FileManagement/EuropeanFiles/Spain/SpainFileManagement")
);

const TlvFileManagement = React.lazy(() =>
  import("../Components/FileManagement/EuropeanFiles/TLV/TlvFileManagement")
);

const ZinlFileManagement = React.lazy(() =>
  import("../Components/FileManagement/EuropeanFiles/Zinl/ZinlFileManagement")
);

const ANSMFileManagment = React.lazy(() =>
  import("../Components/FileManagement/France/ANSM/ANSMFileManagment")
);

const APACFileMangement = React.lazy(() =>
  import("../Components/FileManagement/France/APAC/APACFileMangement")
);

const BiosimilaireFileManagment = React.lazy(() =>
  import(
    "../Components/FileManagement/France/BIOSIMILAIRES/BiosimilaireFileManagment"
  )
);

const BoitePresentationFileManagment = React.lazy(() =>
  import(
    "../Components/FileManagement/France/BoitePresentation/BoitePresentationFileManagment"
  )
);

const CEESPFileManagment = React.lazy(() =>
  import("../Components/FileManagement/France/CEESP/CEESPFileManagment")
);

const IndicationsJOFileManagment = React.lazy(() =>
  import(
    "../Components/FileManagement/France/IndicationsJO/IndicationsJOFileManagment"
  )
);

const RTUFileManagment = React.lazy(() =>
  import("../Components/FileManagement/France/RTU/RTUFileManagment")
);

const TFRFileManagment = React.lazy(() =>
  import("../Components/FileManagement/France/TFR/TFRFileManagment")
);

const PricingAustralieFileManagment = React.lazy(() =>
  import(
    "../Components/FileManagement/PricingData/PricingAustralie/PricingAustralieFileManagment"
  )
);

const PricingBresilFileManagment = React.lazy(() =>
  import(
    "../Components/FileManagement/PricingData/PricingBresil/PricingBresilFileManagment"
  )
);

const PricingSpainFileManagment = React.lazy(() =>
  import(
    "../Components/FileManagement/PricingData/PricingEspain/PricingSpainFileManagment"
  )
);

const PricingGermanyFileManagment = React.lazy(() =>
  import(
    "../Components/FileManagement/PricingData/PricingGermany/PricingGermanyFileManagment"
  )
);

const PricingINAMIFileManagment = React.lazy(() =>
  import(
    "../Components/FileManagement/PricingData/PricingINAMI/PricingINAMIFileManagment"
  )
);

const PricingItalyFileManagment = React.lazy(() =>
  import(
    "../Components/FileManagement/PricingData/PricingItaly/PricingItalyFileManagment"
  )
);

const PricingJapanFileManagment = React.lazy(() =>
  import(
    "../Components/FileManagement/PricingData/PricingJapan/PricingJapanFileManagment"
  )
);

const PricingKoreaFileManagment = React.lazy(() =>
  import(
    "../Components/FileManagement/PricingData/PricingKorea/PricingKoreaFileManagment"
  )
);

const PricingSwedenFileManagment = React.lazy(() =>
  import(
    "../Components/FileManagement/PricingData/PricingSweden/PricingSwedenFileManagment"
  )
);

export const franceRoutes = [
  {
    name: "Transparency (FR)",
    path: "transparences",
    Component: TransparencesFileManagment,
  },
  {
    name: "CEESP (FR)",
    path: "ceesp",
    Component: CEESPFileManagment,
  },
  {
    name: "RTU (FR)",
    path: "rtu",
    Component: RTUFileManagment,
  },
  {
    name: "AP/AC (FR)",
    path: "atu_france",
    Component: APACFileMangement,
  },
  //   {
  //     name: "ATU - PostATU (FR)",
  //     path: "atu",
  //   },
  {
    name: "Boîte présentation",
    path: "boite_presentations",
    Component: BoitePresentationFileManagment,
  },
  {
    name: "ANSM",
    path: "ansm",
    Component: ANSMFileManagment,
  },
  {
    name: "Biosimilaires",
    path: "biosimilaires",
    Component: BiosimilaireFileManagment,
  },
  {
    name: "TFR",
    path: "tfr",
    Component: TFRFileManagment,
  },
  {
    name: "Indications JO",
    path: "indication_jo",
    Component: IndicationsJOFileManagment,
  },
  //   {
  //     name: "Gestion des articles",
  //     path: "",
  //   },
];

export const europeRoutes = [
  { name: "EMA (FR)", path: "ema", Component: EMAFileManagment },
  { name: "NICE (UK)", path: "nices", Component: NICEFileManagment },
  { name: "SMC (UK)", path: "smc", Component: SmcFileManagment },
  { name: "CDF (UK)", path: "cdf", Component: CDFFileManagment },
  { name: "TLV (SE)", path: "tlv", Component: TlvFileManagement },
  {
    name: "NT Council (SE)",
    path: "ntcouncil",
    Component: NtcouncilFileManagement,
  },
  { name: "ZINL (NL)", path: "zinl", Component: ZinlFileManagement },
  { name: "Spain", path: "spain", Component: SpainFileManagement },
  { name: "ITALY", path: "italy", Component: ItalyFileManagement },
  { name: "NCPE (IE)", path: "ncpe", Component: NcpeFileManagement },
  { name: "AWMSG", path: "awmsg", Component: AwmsgFileManagement },
  {
    name: "INAMI (BE)",
    path: "inami",
    Component: InamiFileManagment,
  },
  {
    name: "AOTMiT(Pol)",
    path: "aotmit",
    Component: AotmitFileManagement,
  },
  { name: "IQWIG (DE)", path: "iqwig", Component: IQWIGFileManagment },
  { name: "G-BA (DE)", path: "gba", Component: GBAFileManagment },
];

export const americaRoutes = [
  { name: "FDA (USA)", path: "fdas", Component: FDAFileManagement },
  { name: "US (ICER)", path: "usa_detail", Component: ICERFileManagment },
  { name: "CADTH (CA)", path: "cadth", Component: CADTHFileManagement },
  { name: "pCODR (CA)", path: "perc", Component: PCODRFileManagement },
  { name: "Peru", path: "peru", Component: PeruFileManagement },
];

export const apacRoutes = [
  { name: "PBAC (AU)", path: "pbac", Component: PBACFileManagment },
  { name: "ACE (SG)", path: "singapore", Component: ACEFileMangement },
  { name: "HIRA", path: "hira", Component: HiraFileManagement },
  { name: "NIHTA", path: "nihtas", Component: NihtaFileManagement },
  { name: "PMDA", path: "pmda", Component: PMDAFileManagement },
  //   { name: "TGA (AU)", path: "tga" },
  //   { name: "PTAC (NZ)", path: "" },
  { name: "NMPA (CN)", path: "nmpa", Component: NMPAFileManagment },
  { name: "NHSA (CN)", path: "nhsa", Component: NHSAFileManagment },
];

export const ecoClinicalRoutes = [
  {
    name: "Economic Evaluation",
    path: "economic_evaluation",
    Component: EconomicEvaluationManagement,
  },
  {
    name: "Clinical Trials",
    path: "essais_cliniques_aspiration",
    Component: ClinicalTrialsFileManagment,
  },
];

export const pricingRoutes = [
  {
    name: "Prix Germany",
    path: "prix_germany",
    Component: PricingGermanyFileManagment,
  },
  {
    name: "Prix Spain",
    path: "prix_spain",
    Component: PricingSpainFileManagment,
  },
  {
    name: "Prix Italy",
    path: "prix_italy",
    Component: PricingItalyFileManagment,
  },
  {
    name: "Prix Sweden",
    path: "prix_sweden",
    Component: PricingSwedenFileManagment,
  },
  {
    name: "Prix Australie",
    path: "prix_australie",
    Component: PricingAustralieFileManagment,
  },
  //   { name: "Prix New Zealand", path: "" },
  {
    name: "Prix Bresil",
    path: "prix_bresil",
    Component: PricingBresilFileManagment,
  },
  {
    name: "Prix INAMI",
    path: "prix_inami",
    Component: PricingINAMIFileManagment,
  },
  {
    name: "Prix Japan",
    path: "prix_japan",
    Component: PricingJapanFileManagment,
  },
  {
    name: "Prix Korea",
    path: "prix_korea",
    Component: PricingKoreaFileManagment,
  },
];

export const refApplicatifRoutes = [
  { name: "Tâches", path: "webboard/task", Component: TaskManagment },
  // { name: "Glossary", path: "glossary" },
  {
    name: "Gestion des ingrédients actifs",
    path: "list_dci_new",
    Component: IngredientManagement,
  },
  {
    name: "Gestion des catégories",
    path: "transparences_category",
    Component: CategoryManagement,
  },
  { name: "Gestion des DCI", path: "list_dci", Component: DciManagment },
  {
    name: "Gestion des spécialités",
    path: "list_name",
    Component: SpecialityManagement,
  },
  {
    name: "Aire thérapeutique",
    path: "disease",
    Component: DiseaseManagment,
  },
  // {
  //   name: "Gestion des mots clé",
  //   path: "keyword",
  // },
  {
    name: "Drug Center New Agency",
    path: "drug_center_new_agency",
    Component: NewAgencyFileManagement,
  },
  {
    name: "Hta roadmap",
    path: "hta_roadmap_list",
    Component: HtaRoadMapManagement,
  },
];
