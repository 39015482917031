import axios from 'axios';
import baseUrl from '../../Config/Config';
import {SIGNIN_USER_SUCCESS, SIGN_OUT_USER} from '../../Const/ActionTypes';

export const userSignIn = (user) => {
  return (dispatch) => {
    axios
      .post(`${baseUrl.baseUrl}/users/signin`, user)
      .then((res) => {
        localStorage.setItem('token', res.data.token);
        dispatch(getUserInfo(res.data.token));
      })
      .catch((err) => {
        dispatch({
          type: SIGN_OUT_USER,
          payload: false,
        });
      });
  };
};

export const getUserInfo = (token) => {
  return (dispatch) => {
    axios
      .get(`${baseUrl.baseUrl}/users/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: SIGNIN_USER_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: SIGN_OUT_USER,
          payload: false,
        });
      });
  };
};
