import axios from "axios";
import {
  SHOW_SUCCESS_MESSAGE,
  HIDE_SUCCESS_MESSAGE,
  SHOW_ERROR_MESSAGE,
  HIDE_ERROR_MESSAGE,
  GET_USER_OPTIONS,
  EDIT_USER_OPTIONS,
  GET_USER_LIST,
} from "../../Const/ActionTypes";
import baseUrl from "../../Config/Config";

export const getUserOptions = (userId) => {
  return (dispatch) => {
    axios
      .get(`${baseUrl.baseUrl}/user-options?filter[where][userId]=${userId}`)
      .then((res) => {
        if (res.data[0] !== undefined) {
          dispatch({
            type: GET_USER_OPTIONS,
            payload: res.data[0],
          });
        }
      })
      .catch((err) => {
        console.log("error message", err.message);
      });
  };
};
export const addUserOptions = (data, alerte = false) => {
  return (dispatch) => {
    if (data.id === undefined) {
      axios
        .post(`${baseUrl.baseUrl}/user-options`, data)
        .then((res) => {
          dispatch({
            type: EDIT_USER_OPTIONS,
            payload: res.data,
          });
          if (alerte) {
            dispatch({
              type: SHOW_SUCCESS_MESSAGE,
              payload: "Vos choix sont enregistrés avec succès",
            });
            setTimeout(() => {
              dispatch({ type: HIDE_SUCCESS_MESSAGE });
            }, 4000);
          }
        })
        .catch((err) => {
          console.log("error message", err.message);
          dispatch({
            type: SHOW_ERROR_MESSAGE,
            payload:
              "Merci  de réessayer ultérieurement , une erreur s'est produite de notre coté",
          });
          setTimeout(() => {
            dispatch({ type: HIDE_ERROR_MESSAGE });
          }, 4000);
        });
    } else {
      axios
        .patch(`${baseUrl.baseUrl}/user-options/` + data.id, data)
        .then((res) => {
          dispatch({
            type: EDIT_USER_OPTIONS,
            payload: data,
          });
          if (alerte) {
            dispatch({
              type: SHOW_SUCCESS_MESSAGE,
              payload: "Vos choix sont enregistrés avec succès",
            });
            setTimeout(() => {
              dispatch({ type: HIDE_SUCCESS_MESSAGE });
            }, 4000);
          }
        })
        .catch((err) => {
          dispatch({
            type: SHOW_ERROR_MESSAGE,
            payload:
              "Merci  de réessayer ultérieurement , une erreur s'est produite de notre coté",
          });
          setTimeout(() => {
            dispatch({ type: HIDE_ERROR_MESSAGE });
          }, 4000);
          console.log("error message", err.message);
        });
    }
  };
};
export const getUserList = (userId) => {
  return (dispatch) => {
    axios
      .get(`${baseUrl.baseUrl}/users`)
      .then((res) => {
        dispatch({
          type: GET_USER_LIST,
          payload: res.data.map((userEle) => {
            return {
              id: userEle.id,
              name: userEle.firstName + " " + userEle.lastName,
            };
          }),
        });
      })
      .catch((err) => {
        console.log("error message", err.message);
      });
  };
};
