import React from "react";
import { countriesList } from "../../../Const/selectListValues";
import DatePicker from "../../CommunComponents/DatePicker";

function AddUserGeneralInfos({
  values,
  handleChange,
  handleDateChange,
  handleChangeCheckBox,
}) {
  const Required = () => {
    return <span style={{ color: "red" }}>*</span>;
  };

  return (
    <div>
        <div>
          <div
            style={{
              margin: "20px 0px 0px 0px",
              color: "#E84F13",
              fontSize: "17px",
              fontFamily: "Proxima Nova",
              fontWeight: 700,
            }}
          >
            Profil
          </div>
          <div className="form-settings__columns form-settings__columns_start">
            <div className="form-settings__column">
              <div className="form-settings__name" style={{ width: "120px" }}>
                Nom <Required />
              </div>
              <input
                className="form-settings__input input input-big"
                type="text"
                value={values.userInfos.lastName}
                onChange={handleChange}
                name="lastName"
              />
            </div>
            <div className="form-settings__column">
              <div className="form-settings__name" style={{ width: "120px" }}>
                Prénom <Required />
              </div>
              <input
                className="form-settings__input input input-big"
                type="text"
                value={values.userInfos.firstName}
                onChange={handleChange}
                name="firstName"
              />
            </div>
          </div>
          <div className="form-settings__columns form-settings__columns_start">
            <div className="form-settings__column">
              <div className="form-settings__name" style={{ width: "120px" }}>
                Email <Required />
              </div>
              <input
                className="form-settings__input input input-big"
                type="text"
                value={values.userInfos.email}
                onChange={handleChange}
                name="email"
              />
            </div>
            <div className="form-settings__column">
              <div className="form-settings__name" style={{ width: "120px" }}>
                Occupation <Required />
              </div>
              <input
                className="form-settings__input input input-big"
                type="text"
                value={values.userInfos.occupation}
                onChange={handleChange}
                name="occupation"
              />
            </div>
          </div>
          <div className="form-settings__columns form-settings__columns_start">
            <div className="form-settings__column">
              <div className="form-settings__name" style={{ width: "120px" }}>
                Société 
              </div>
              <input
                className="form-settings__input input input-big"
                type="text"
                value={values.userInfos.society}
                onChange={handleChange}
                name="society"
              />
            </div>
            <div className="form-settings__column">
              <div className="form-settings__name" style={{ width: "120px" }}>
                Pays
              </div>
              <select
                className="form-settings__select select input-big"
                onChange={handleChange}
                value={values.userInfos.country}
                name="country"
              >
                <option key={0} value={""} />
                {countriesList.map((option, index) => (
                  <option key={index + 1} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div>
          <div
            style={{
              margin: "20px 0px 0px 0px",
              color: "#E84F13",
              fontSize: "17px",
              fontFamily: "Proxima Nova",
              fontWeight: 700,
            }}
          >
            Identifiant
          </div>
          <div className="form-settings__columns form-settings__columns_start">
            <div className="form-settings__column">
              <div className="form-settings__name" style={{ width: "120px" }}>
                Rôle <Required />
              </div>
              <select
                className="form-settings__select select input-big"
                onChange={handleChange}
                value={values.userInfos.role}
                name="role"
              >
                {["", "admin", "analyst"].map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-settings__column">
              <div className="form-settings__name" style={{ width: "120px" }}>
                Statut <Required />
              </div>
              <select
                className="form-settings__select select input-big"
                onChange={handleChange}
                value={values.userInfos.status}
                name="status"
              >
                {["", "active", "inactive"].map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="form-settings__columns form-settings__columns_start">
            <div className="form-settings__column">
              <div className="form-settings__name" style={{ width: "190px" }}>
                Date de début de la session <Required />
              </div>
              <DatePicker
                value={values.userInfos.dateSessionStart}
                onChange={(date) => handleDateChange("dateSessionStart", date)}
              />
            </div>
            <div className="form-settings__column">
              <div className="form-settings__name" style={{ width: "190px" }}>
                Date de fin de la session <Required />
              </div>
              <DatePicker
                value={values.userInfos.dateSessionEnd}
                onChange={(date) => handleDateChange("dateSessionEnd", date)}
              />
            </div>
          </div>

          <div className="form-settings__columns form-settings__columns_start">
            <div className="form-settings__column">
              <div className="form-settings__name" style={{ width: "120px" }}>
                Adresse IP {values.userInfos.ipAuthentified ? <Required /> : ""}
              </div>
              <input
                className="form-settings__input input input-big"
                type="text"
                value={values.userInfos.ipAddress}
                onChange={handleChange}
                name="ipAddress"
              />
            </div>
            <div className="form-settings__column">
              <div className="form-settings__name" style={{ width: "120px" }}>
                Langue <Required />
              </div>
              <select
                className="form-settings__select select input-big"
                onChange={handleChange}
                value={values.userInfos.language}
                name="language"
              >
                {["", "Français", "Anglais"].map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="form-settings__column">
          <div className="form-settings__label">Authentifer avec IP</div>
          <div className="form-settings__checker">
            <input
              className="checkbox _icon-checkbox"
              type="checkbox"
              checked={
                values.userInfos.ipAuthentified === "1" ||
                values.userInfos.ipAuthentified === 1
              }
              onChange={handleChangeCheckBox}
              name="ipAuthentified"
            />
          </div>
        </div>

        <div className="form-settings__columns form-settings__columns_start">
          <div className="form-settings__column">
            <div className="form-settings__label">Affecter des agences</div>
            <div className="form-settings__checker">
              <input
                className="checkbox _icon-checkbox"
                type="checkbox"
                checked={                 
                  values.userInfos.agencyAffected === true
                }
                onChange={handleChangeCheckBox}
                name="agencyAffected"
              />
            </div>
          </div>

          <div className="form-settings__column">
            <div className="form-settings__label">
              limiter l'accès à des fiches
            </div>
            <div className="form-settings__checker">
              <input
                className="checkbox _icon-checkbox"
                type="checkbox"
                checked={
                  values.userInfos.fileLimited === true ||
                  values.userInfos.fileLimited === "true"
                }
                onChange={handleChangeCheckBox}
                name="fileLimited"
              />
            </div>
          </div>
        </div>
    </div>
  );
}

export default AddUserGeneralInfos;
