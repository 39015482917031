import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
import { routerMiddleware, connectRouter } from "connected-react-router";

// -------------- IMPORT REDUCER LIST -------------------
import ActiveIngredientReducer from "./Reducers/ActiveIngredientReducer";
import AuthReducer from "./Reducers/AuthReducer";
import DciReducer from "./Reducers/DciReducer";
import DiseaseReducer from "./Reducers/DiseaseReducer";
import AlertReducer from "./Reducers/AlertReducer";
import SpecialityReducer from "./Reducers/SpecialityReducer";
import CategoryReducer from "./Reducers/CategoryReducer";
import TlvReducer from "./Reducers/TlvReducer";
import KeywordsReducer from "./Reducers/KeyWordsReducer";
import TaskReducer from "./Reducers/TaskReducer";
import NcpeReducer from "./Reducers/NcpeReducer";
import userOptionsReducer from "./Reducers/userOptionsReducer";
import UserReducer from "./Reducers/UserReducer";
import NtCouncilReducer from "./Reducers/NtCouncilReducer";
import ZinlReducer from "./Reducers/ZinlReducer";
import AWMSGReducer from "./Reducers/AWMSGReducer";
import AOTMITReducer from "./Reducers/AOTMITReducer";
import InamiReducer from "./Reducers/InamiReducer";
import CdfReducer from "./Reducers/CdfReducer";
import EmaReducer from "./Reducers/EmaReducer";
import SpainReducer from "./Reducers/SpainReducer";
import RTUReducer from "./Reducers/RTUReducer";
import SmcReducer from "./Reducers/SmcReducer";
import CEESPReducer from "./Reducers/CEESPReducer";
import ANSMReducer from "./Reducers/ANSMReducer";
import TFRReducer from "./Reducers/TFRReducer";
import NiceReducers from "./Reducers/NiceReducers";
import BoitePresentationReducer from "./Reducers/BoitePresentationReducer";
import PbacReducer from "./Reducers/PbacReducer";
import ItalyReducer from "./Reducers/ItalyReducer";
import ACEReducer from "./Reducers/ACEReducer";
import PmdaReducer from "./Reducers/PmdaReducer";
import HiraReducer from "./Reducers/HiraReducer";
import NIHTAReducer from "./Reducers/NIHTAReducer";
import FdaReducers from "./Reducers/FdaReducers";
import BiosimilaireReducer from "./Reducers/BiosimilaireReducer";
import IndicationJoReducer from "./Reducers/IndicationJoReducer";
import TransparencyReducer from "./Reducers/TransparencyReducer";
import NHSAReducer from "./Reducers/NHSAReducer";
import APACReducer from "./Reducers/APACReducer";
import pricingGermany from "./Reducers/PricingGermanyReducer";
import pricingSpain from "./Reducers/PricingSpainReducer";
import pricingKorea from "./Reducers/PricingKoreaReducer";
import pricingJapan from "./Reducers/PricingJapanReducer";
import NMPAReducer from "./Reducers/NMPAReducer";
import PricingSwedenReducer from "./Reducers/PricingSwedenReducer";
import EconomicEvaluationReducer from "./Reducers/EconomicEvaluationReducer";
import PricingBresilReducer from "./Reducers/PricingBresilReducer";
import pricingItaly from "./Reducers/PricingItalyReducer";
import pricingAustralie from "./Reducers/PricingAustralieReducer";
import PricingINAMIReducer from "./Reducers/PricingINAMIReducer";
import ICERReducer from "./Reducers/ICERReducer";
import iqwigReducer from "./Reducers/IqwigReducer";
import ClinicalTrialsReducer from "./Reducers/ClinicalTrials";
import StudiesReducer from "./Reducers/StudiesReducer";
import gbaReducer from "./Reducers/GbaReducer";
import pCODRReducer from "./Reducers/pCODRReducer";
import cadthReducer from "./Reducers/CADTHReducer";
import peruReducer from "./Reducers/PeruReducer";
import FileSettingReducer from "./Reducers/FileSettingReducer";
import HtaRoadMapReducer from "./Reducers/HtaRoadMapReducer";
import AgencyReducer from "./Reducers/AgencyReducer";
import UIReducer from "./Reducers/UIReducer";

import SMRReducer from "./Reducers/SMRReducer";
import PatientPopulationReducer from "./Reducers/PatientPopulationReducer";
import IqwigExtentProbabiliteReducer from "./Reducers/IqwigExtentProbabiliteReducer";
// --------------config store -----------------------------------------------------
const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
const middlewares = [routeMiddleware, thunk];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composedEnhancers = compose(applyMiddleware(...middlewares));
// --------------conbine Reducers -----------------------------------------------------

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    AlertReducer: AlertReducer,
    ActiveIngredientReducer,
    AuthReducer: AuthReducer,
    DciReducer: DciReducer,
    DiseaseReducer: DiseaseReducer,
    SpecialityReducer: SpecialityReducer,
    HtaRoadMapReducer: HtaRoadMapReducer,
    CategoryReducer: CategoryReducer,
    TlvReducer: TlvReducer,
    KeywordsReducer: KeywordsReducer,
    TaskReducer: TaskReducer,
    ZinlReducer: ZinlReducer,
    NcpeReducer: NcpeReducer,
    userOptionsReducer: userOptionsReducer,
    UserReducer: UserReducer,
    NtCouncilReducer: NtCouncilReducer,
    AWMSGReducer: AWMSGReducer,
    AOTMITReducer: AOTMITReducer,
    InamiReducer: InamiReducer,
    CdfReducer: CdfReducer,
    EmaReducer: EmaReducer,
    SpainReducer: SpainReducer,
    RTUReducer: RTUReducer,
    SmcReducer: SmcReducer,
    CEESPReducer: CEESPReducer,
    ANSMReducer: ANSMReducer,
    TFRReducer: TFRReducer,
    NiceReducers: NiceReducers,
    BoitePresentationReducer: BoitePresentationReducer,
    PbacReducer: PbacReducer,
    ItalyReducer: ItalyReducer,
    ACEReducer: ACEReducer,
    PmdaReducer: PmdaReducer,
    HiraReducer: HiraReducer,
    NIHTAReducer: NIHTAReducer,
    FdaReducers: FdaReducers,
    BiosimilaireReducer: BiosimilaireReducer,
    IndicationJoReducer: IndicationJoReducer,
    TransparencyReducer,
    APACReducer: APACReducer,
    PricingGermanyReducer: pricingGermany,
    PricingSpainReducer: pricingSpain,
    PricingKoreaReducer: pricingKorea,
    EconomicEvaluationReducer: EconomicEvaluationReducer,
    PricingJapanReducer: pricingJapan,
    NHSAReducer: NHSAReducer,
    ICERReducer: ICERReducer,
    NMPAReducer: NMPAReducer,
    PricingSwedenReducer: PricingSwedenReducer,
    PricingBresilReducer,
    PricingItalyReducer: pricingItaly,
    PricingAustralieReducer: pricingAustralie,
    PricingINAMIReducer,
    IqwigReducer: iqwigReducer,
    ClinicalTrialsReducer,
    StudiesReducer,
    GbaReducer: gbaReducer,
    pCODRReducer,
    CADTHReducer: cadthReducer,
    PeruReducer: peruReducer,
    FileSettingReducer: FileSettingReducer,
    AgencyReducer: AgencyReducer,
    UIReducer,
    SMRReducer,
    PatientPopulationReducer,
    IqwigExtentProbabiliteReducer
  });
// --------------create store -----------------------------------------------------

const configStore = () => {
  return createStore(rootReducer(history), composeEnhancers(composedEnhancers));
};

export default configStore;

export { history };
