import axios from "axios";
import {
  SHOW_SUCCESS_MESSAGE,
  HIDE_SUCCESS_MESSAGE,
  SHOW_ERROR_MESSAGE,
  HIDE_ERROR_MESSAGE,
  SET_FILE_STATUS,
  INCREMENT_COUNT,
  DECREMENT_COUNT,
} from "../../Const/ActionTypes";
import baseUrl from "../../Config/Config";
import { setFileStatus, setModalFileStatus } from "./FileSettingActions";

import { setLoading, unsetLoading } from "./UIActions";

export const deleteFile = (api, actionType, fileName, file) => {
  let fileId = file.id;
  return (dispatch) => {
    dispatch(setLoading());

    axios
      .delete(`${baseUrl.baseUrl}/${api}/${fileId}`)
      .then((res) => {
        if (res.data.delete === "success") {
          if (file.keyDocumentsSection !== undefined) {
            file.keyDocumentsSection.map((e) =>
              axios
                .delete(`${baseUrl.baseUrl}/buckets`, { data: { key: e.path } })
                .then((response) => {})
            );
          }

          dispatch({
            type: actionType,
            payload: fileId,
          });
          dispatch({
            type: SET_FILE_STATUS,
            payload: { status: "get" },
          });
          dispatch({
            type: SHOW_SUCCESS_MESSAGE,
            payload:
              "La fiche " +
              fileName +
              " ( ID : " +
              fileId +
              " ) est supprimée avec succès",
          });

          dispatch(decrementCount());

          setTimeout(() => {
            dispatch({ type: HIDE_SUCCESS_MESSAGE });
          }, 4000);
        }
      })
      .catch((err) => {
        let errorMsg =
          err.response === undefined
            ? "Merci  de réessayer ultérieurement , une erreur s'est produite lors de la suppression de fiche " +
              fileName
            : err.response.data.error.message;

        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: errorMsg,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      })
      .finally(() => {
        dispatch(unsetLoading());
      });
  };
};

export const countFilesTASK = (userId) => {
  return (dispatch) => {
    axios
      .get(`${baseUrl.baseUrl}/comments/count/${userId}`)
      .then((res) => {
        dispatch(
          setFileStatus({
            maxRowSended: res.data.commentCount.count ?? 0,
            maxRowReceived: res.data.commentRecipientCount.count ?? 0,
          })
        );
      })
      .catch((err) => {
        console.log("error message", err.message);
      });
  };
};

export const countFiles = (api, fromModal = false) => {
  return (dispatch) => {
    dispatch(setLoading());
    axios
      .get(`${baseUrl.baseUrl}/${api}/count`)
      .then((res) => {
        if (fromModal) {
          dispatch(
            setModalFileStatus({
              maxRowsModal: res.data?.count ?? 0,
            })
          );
        }
        dispatch(
          setFileStatus({
            maxRows: res.data?.count ?? 0,
          })
        );
      })
      .catch((err) => {
        console.log("error message", err.message);
      });
  };
};

export const findFileById = (api, id, redirect, isReferentiel = false) => {
  let path = `${baseUrl.baseUrl}/${api}?id=${id}`;
  let include;
  if (isReferentiel) {
    if (api === "indexations")
      include = [
        {
          relation: "childIndexations",
        },
        {
          relation: "parentIndexations",
        },
      ];

    if (api === "drug-categories")
      include = [
        {
          relation: "chemicalIngred",
        },
      ];
    let filter = JSON.stringify({
      include,
    });
    path = `${baseUrl.baseUrl}/${api}/${id}?filter=${filter}`;
  }

  return (dispatch) => {
    dispatch(setLoading(true));
    axios
      .get(path)
      .then((res) => {
        let foundFile = isReferentiel ? res.data : res.data[0];

        if (foundFile) {
          dispatch(
            setFileStatus({
              fileSlected: foundFile,
              status: "edit",
            })
          );
        } else {
          redirect();
        }
      })
      .catch((err) => {
        redirect();
      })
      .finally(() => {
        dispatch(unsetLoading(true));
      });
  };
};

export const getFiles = (api, action, limit, offset) => {
  let pagination = "?";

  if (limit) pagination = pagination + "limit=" + limit;

  if (offset) pagination = pagination + "&offset=" + offset;
  return (dispatch) => {
    dispatch(setLoading());
    axios
      .get(`${baseUrl.baseUrl}/${api}${pagination}`)
      .then((res) => {
        dispatch({
          type: action,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log("error message", err.message);
      })
      .finally(() => {
        dispatch(unsetLoading());
      });
  };
};

export const getDataManagement = (api, action, limit, offset) => {
  let include;
  if (api === "indexations")
    include = [
      {
        relation: "childIndexations",
      },
      {
        relation: "parentIndexations",
      },
    ];

  if (api === "drug-categories")
    include = [
      {
        relation: "chemicalIngred",
      },
    ];
  let filter = JSON.stringify({
    offset,
    limit,
    order: ["id DESC"],
    include,
  });

  return (dispatch) => {
    dispatch(setLoading());
    axios
      .get(`${baseUrl.baseUrl}/${api}?filter=${filter}`)
      .then((res) => {
        dispatch({
          type: action,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log("error message", err.message);
      })
      .finally(() => {
        dispatch(unsetLoading());
      });
  };
};

export const incrementCount = () => (dispatch) => {
  dispatch({ type: INCREMENT_COUNT });
};

export const decrementCount = () => (dispatch) => {
  dispatch({ type: DECREMENT_COUNT });
};
