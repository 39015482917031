import {
    ADD_FILE_PRICING_ITALY,
    DELETE_FILE_PRICING_ITALY,
    EDIT_FILE_PRICING_ITALY,
    GET_PRICING_ITALY_FILE_LIST,
  } from "../../Const/ActionTypes";
  
  const initialState = {
    listPricingItaly: [],
    pricingItalyFileToAdd: {
      id: null,
      communInformation: undefined,
      classification:undefined,
      pricingReimbursement: undefined,
      evalutionLinksSection: undefined,
    },
  };
  
  export default function pricingItaly(state = initialState, action) {
    switch (action.type) {
      case GET_PRICING_ITALY_FILE_LIST: {
        return Object.assign({}, state, {
          listPricingItaly: action.payload,
        });
      }
      case EDIT_FILE_PRICING_ITALY: {
        return Object.assign({}, state, {
          listPricingItaly: state.listPricingItaly.map((element) =>
            element.id === action.payload.id ? action.payload : element
          ),
  
          pricingItalyFileToAdd: { id: null },
        });
      }
      case ADD_FILE_PRICING_ITALY: {
        return Object.assign({}, state, {
          listPricingItaly: [action.payload].concat(state.listPricingItaly),
          pricingItalyFileToAdd: action.payload,
        });
      }
  
      case DELETE_FILE_PRICING_ITALY: {
        return Object.assign({}, state, {
          listPricingItaly: [
            ...state.listPricingItaly.filter(
              (element) => element.id !== action.payload
            ),
          ],
        });
      }
      default:
        return state;
    }
  }
  