import React, { Component } from "react";
import { connect } from "react-redux";
import {
  setFileHeaders,
  setAllFileHeaders,
} from "../../Redux/Actions/FileSettingActions";
import { addUserOptions } from "../../Redux/Actions/UserOptionsActions";

class SideNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectAllHeader: false,
    };
  }
  submitChoice = () => {
    let finalObject = {
      id: this.props.userOptions.id,
      userId: 0,
      [this.props.fileSetting.headersKey]: this.props.fileSetting.headers,
    };
    this.props.addUserOptions(finalObject, true);
    let sideMenu = document.querySelector(".side-menu");
    sideMenu.classList.remove("_active");
  };
  handleSelectAll = () => {
    if (this.state.selectAllHeader) {
      // l' admin va annuler la selection totale
      let oldHeaders =
        this.props.userOptions[this.props.fileSetting.headersKey];
      oldHeaders && this.props.setAllFileHeaders(oldHeaders);
    } else {
      // l' admin va  selectioner tout
      this.props.setAllFileHeaders(
        this.props.fileSetting.headers.map((e) => {
          return { ...e, checked: true };
        })
      );
    }
    this.setState({ selectAllHeader: !this.state.selectAllHeader });
  };
  resetChoice = () => {
    this.setState({
      selectAllHeader: false,
    });
    let oldHeaders = this.props.userOptions[this.props.fileSetting.headersKey];
    oldHeaders && this.props.setAllFileHeaders(oldHeaders);
  };

  render() {
    return (
      <div className="side-menu">
        <form action="#" className="side-menu__form">
          <div className="side-menu__buttons">
            <button
              className="side-menu__reset button button-empty _icon-reset"
              type="button"
              onClick={() => this.resetChoice()}
            >
              Reset
            </button>
            <button
              className="side-menu__submit button button-empty _icon-star"
              type="button"
              onClick={() => this.submitChoice()}
            >
              Enregistrer les colonnes favoris
            </button>
          </div>
          <ul className="side-menu__list">
            <li className="side-menu__item">
              <input
                id="side-selectioner"
                className="side-menu__checkbox checkbox _icon-checkbox"
                type="checkbox"
                checked={this.state.selectAllHeader}
                onClick={() => this.handleSelectAll()}
                readOnly
              />
              <label
                htmlFor="side-selectioner"
                className="side-menu__label side-menu__label_bold label"
              >
                Sélectionner tout
              </label>
            </li>
            {this.props.fileSetting.headers?.map((headerEle, index) => (
              <li className="side-menu__item" key={index}>
                <input
                  id="side-id"
                  className="side-menu__checkbox checkbox _icon-checkbox"
                  type="checkbox"
                  checked={headerEle.checked}
                  onChange={() => this.props.setFileHeaders(headerEle)}
                  readOnly
                />
                <label className="side-menu__label label">
                  {headerEle.label}
                </label>
              </li>
            ))}
          </ul>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fileSetting: state.FileSettingReducer,
    userOptions: state.userOptionsReducer.userOptions,
  };
};

export default connect(mapStateToProps, {
  setFileHeaders,
  addUserOptions,
  setAllFileHeaders,
})(SideNav);
