/* eslint import/no-anonymous-default-export: "off" */

import {
  SET_ALL_FILE_HEADERS,
  SET_FILE_HEADERS,
  SET_FILE_MODAL_HEADERS,
  SET_FILE_STATUS,
  SEELECT_FILE_MODAL_HEADERS,
  SEELECT_ALL_FILE_MODAL_HEADERS,
  SET_DUPLICATION_ID,
  INCREMENT_COUNT,
  DECREMENT_COUNT,
  SET_MODAL_FILE_STATUS,
  CLEAR_MODAL_FILE_STATUS,
} from "../../Const/ActionTypes";

const initialState = {
  currentFile: null,
  status: "get",
  sections: [],
  headers: [],
  headersKey: null,
  fileSlected: {},
  deleteAction: null,
  fileApi: null,
  page: 0,
  rowsPerPage: 20,
  submitAction: null,
  getAction: null,
  fileModalHeaders: [],
  handleOpenSection: null,
  titlesList: [],
  titlesDeList: [],
  filePath: "",
  duplicationId: null,
  maxRows: 0,
  maxRowReceived: 0,
  maxRowSended: 0,

  // Modal attributs section

  modalAttributes: {
    pageModal: 0,
    rowsPerPageModal: 20,
    fileApiModal: "",
    agencyLabelModal: "",
    headersModal: null,
    getActionTypeModal: null,
    maxRowsModal: 0,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_FILE_STATUS: {
      return Object.assign({}, state, {
        ...action.payload,
      });
    }

    case SET_FILE_MODAL_HEADERS: {
      return Object.assign({}, state, {
        fileModalHeaders: action.payload,
      });
    }

    case SET_FILE_HEADERS: {
      return Object.assign({}, state, {
        headers: state.headers.map((element) =>
          element.label === action.payload.label
            ? { ...element, checked: !element.checked }
            : element
        ),
      });
    }

    case SET_ALL_FILE_HEADERS: {
      return Object.assign({}, state, {
        headers: action.payload,
      });
    }

    case SEELECT_FILE_MODAL_HEADERS: {
      return Object.assign({}, state, {
        fileModalHeaders: state.fileModalHeaders.map((element) =>
          element.label === action.payload.label
            ? { ...element, checked: !element.checked }
            : element
        ),
      });
    }

    case SEELECT_ALL_FILE_MODAL_HEADERS: {
      return Object.assign({}, state, {
        fileModalHeaders: action.payload,
      });
    }

    case SET_DUPLICATION_ID: {
      return Object.assign({}, state, {
        duplicationId: action.payload,
      });
    }

    case INCREMENT_COUNT: {
      return Object.assign({}, state, {
        maxRows: (state.maxRows += 1),
      });
    }

    case DECREMENT_COUNT: {
      return Object.assign({}, state, {
        maxRows: (state.maxRows -= 1),
      });
    }

    case SET_MODAL_FILE_STATUS: {
      return Object.assign({}, state, {
        modalAttributes: {
          ...state.modalAttributes,
          ...action.payload,
        },
      });
    }

    case CLEAR_MODAL_FILE_STATUS: {
      return Object.assign({}, state, {
        modalAttributes: {
          pageModal: 0,
          rowsPerPageModal: 20,
          fileApiModal: "",
          agencyLabelModal: "",
          headersModal: null,
          getActionTypeModal: null,
          maxRowsModal: 0,
        },
      });
    }

    default:
      return state;
  }
}
