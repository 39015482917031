import React, { Component } from "react";
import { connect } from "react-redux";
import { setFileStatus } from "../../Redux/Actions/FileSettingActions";
import { withRouter } from "react-router-dom";

class ConfirmDuplicate extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps, prevState) {
    const { filePath, duplicationId } = this.props.fileSetting;

    if (prevProps.fileSetting.duplicationId !== duplicationId) {
      if (duplicationId) {
        this.props.history.push(`/admin/${filePath}/modify/${duplicationId}`);
      }
    }

    if (prevProps.errorStatus !== this.props.errorStatus) {
      this.handleClose();
    }
  }

  confirmDuplicate = () => {
    this.props.fileSetting.submitAction(
      this.props.fileSetting.fileSlected,
      true
    );
    this.props.setFileStatus({ status: "edit" });
    window.scrollTo(0, 0);
  };

  handleClose = () => {
    this.props.setFileStatus({ fileSlected: {}, status: "get" });
  };

  render() {
    return (
      <div
        className={`action-popup action-popup-edit ${
          this.props.fileSetting.status === "duplicate" ? "_active" : ""
        }`}
      >
        <div className="action-popup__wrapper">
          <div className="action-popup__content">
            <p className="action-popup__title">Êtes-vous sûr?</p>
            <p className="action-popup__text">
              {"Voulez vous dupliquer cette fiche " +
                this.props.fileSetting.currentFile +
                " ( ID : " +
                this.props.fileSetting.fileSlected?.id +
                " ) "}
            </p>
            <div className="action-popup__buttons">
              <button
                className="action-popup__button action-popup__button_continue button"
                onClick={() => this.confirmDuplicate()}
              >
                Continuer
              </button>
              <button
                className="action-popup__button action-popup__button_close button button-empty"
                onClick={() => this.handleClose()}
              >
                Quitter
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    fileSetting: state.FileSettingReducer,
    errorStatus: state.AlertReducer.error,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setFileStatus,
  })(ConfirmDuplicate)
);
