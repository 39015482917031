/* eslint import/no-anonymous-default-export: "off" */
import { GET_ANSM_FILE_LIST, ADD_FILE_ANSM, DELETE_FILE_ANSM, EDIT_FILE_ANSM } from "../../Const/ActionTypes";
const initialState = {
  listAnsm: [],
  ansmFileToAdd: { id: null },
};
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ANSM_FILE_LIST: {
      return Object.assign({}, state, {
        listAnsm: action.payload,
      });
    }

    case ADD_FILE_ANSM: {
      return Object.assign({}, state, {
        listAnsm: [action.payload].concat(state.listAnsm),
        ansmFileToAdd: action.payload,
      });
    }
    case EDIT_FILE_ANSM: {
      return Object.assign({}, state, {
        listAnsm: [action.payload, ...state.listAnsm.filter((element) => element.id !== action.payload.id)],
        ansmFileToAdd: { id: null },
      });
    }
    case DELETE_FILE_ANSM: {
      return Object.assign({}, state, {
        listAnsm: [...state.listAnsm.filter((element) => element.id !== action.payload)],
      });
    }
    default:
      return state;
  }
}
