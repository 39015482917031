import { Provider } from "react-redux";
import configStore, { history } from "./Redux/configStore";
import { Route, Switch } from "react-router-dom";
import MainApp from "./Components/MainApp";
import { ConnectedRouter } from "connected-react-router";

function App() {
  const store = configStore();

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <div>
          <div className="wrapper">
            <Switch>
              <Route path="/" render={(props) => <MainApp {...props} />} />
            </Switch>
          </div>
        </div>
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
