import {
  ADD_FILE_PRICING_GERMANY,
  DELETE_FILE_PRICING_GERMANY,
  EDIT_FILE_PRICING_GERMANY,
  GET_PRICING_GERMANY_FILE_LIST,
} from "../../Const/ActionTypes";

const initialState = {
  listPricingGermany: [],
  pricingGermanyFileToAdd: {
    id: null,
    communInformation: undefined,
    pricing: undefined,
    evalutionLinksSection: undefined,
  },
};

export default function pricingGermany(state = initialState, action) {
  switch (action.type) {
    case GET_PRICING_GERMANY_FILE_LIST: {
      return Object.assign({}, state, {
        listPricingGermany: action.payload,
      });
    }
    case EDIT_FILE_PRICING_GERMANY: {
      return Object.assign({}, state, {
        listPricingGermany: state.listPricingGermany.map((element) =>
          element.id === action.payload.id ? action.payload : element
        ),

        pricingGermanyFileToAdd: { id: null },
      });
    }
    case ADD_FILE_PRICING_GERMANY: {
      return Object.assign({}, state, {
        listPricingGermany: [action.payload].concat(state.listPricingGermany),
        pricingGermanyFileToAdd: action.payload,
      });
    }

    case DELETE_FILE_PRICING_GERMANY: {
      return Object.assign({}, state, {
        listPricingGermany: [
          ...state.listPricingGermany.filter(
            (element) => element.id !== action.payload
          ),
        ],
      });
    }
    default:
      return state;
  }
}
