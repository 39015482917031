import {
  SET_LOADING,
  SET_LOADING_FIND_BY_ID,
  UNSET_LOADING,
  UNSET_LOADING_FIND_BY_ID,
} from "../../Const/ActionTypes";

const initialState = {
  loading: false,
  loadingFindById: false,
};

function UIReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING: {
      return Object.assign({}, state, {
        loading: true,
      });
    }

    case SET_LOADING_FIND_BY_ID: {
      return Object.assign({}, state, {
        loadingFindById: true,
      });
    }

    case UNSET_LOADING_FIND_BY_ID: {
      return Object.assign({}, state, {
        loadingFindById: false,
      });
    }

    case UNSET_LOADING: {
      return Object.assign({}, state, {
        loading: false,
      });
    }

    default: {
      return state;
    }
  }
}

export default UIReducer;
