/* eslint import/no-anonymous-default-export: "off" */

import {
  GET_ECONOMIC_EVALUATION_FILE_LIST,
  ADD_FILE_ECONOMIC_EVALUATION,
  EDIT_FILE_ECONOMIC_EVALUATION,
  DELETE_FILE_ECONOMIC_EVALUATION,
  SET_TYPE_OF_ECO_ANALY_FILE_ECONOMIC_EVALUATION,
} from "../../Const/ActionTypes";

const initialState = {
  listEconomicEvaluation: [],
  economicEvaluationFileToAdd: {
    id: null,
    communInformation: undefined,
  },

  ecoAnalysisValue: undefined,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ECONOMIC_EVALUATION_FILE_LIST: {
      return Object.assign({}, state, {
        listEconomicEvaluation: action.payload,
      });
    }
    case EDIT_FILE_ECONOMIC_EVALUATION: {
      return Object.assign({}, state, {
        listEconomicEvaluation: [
          action.payload,
          ...state.listEconomicEvaluation.filter(
            (element) => element.id !== action.payload.id
          ),
        ],
        economicEvaluationFileToAdd: { id: null },
      });
    }
    case ADD_FILE_ECONOMIC_EVALUATION: {
      return Object.assign({}, state, {
        listEconomicEvaluation: [action.payload].concat(
          state.listEconomicEvaluation
        ),
        economicEvaluationFileToAdd: action.payload,
      });
    }

    case DELETE_FILE_ECONOMIC_EVALUATION: {
      return Object.assign({}, state, {
        listEconomicEvaluation: [
          ...state.listEconomicEvaluation.filter(
            (element) => element.id !== action.payload
          ),
        ],
      });
    }

    case SET_TYPE_OF_ECO_ANALY_FILE_ECONOMIC_EVALUATION: {
      return Object.assign({}, state, {
        ecoAnalysisValue: action.payload,
      });
    }
    default:
      return state;
  }
}
