/* eslint import/no-anonymous-default-export: "off" */

import {
  GET_AWMSG_FILE_LIST,
  ADD_FILE_AWMSG,
  EDIT_FILE_AWMSG,
  DELETE_FILE_AWMSG,
} from '../../Const/ActionTypes';

const initialState = {
  listAWSMG: [],
  AWSMGFileToAdd: {
    id: null,
    communInformation: undefined,
    refSection: undefined,
    indicationSection: undefined,
    linkSection: undefined,
    decisionSection: undefined,
    budgetSection: undefined,
    nctNumbersSection: undefined,
    realWorldDataSection: undefined,
    qualityOfLifeSection: undefined,
    indirectComparaisonSection: undefined,
    rationaleCommentSection: undefined,
    treatmentLineSection: undefined,
    atcSection: undefined,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_AWMSG_FILE_LIST: {
      return Object.assign({}, state, {
        listAWSMG: action.payload,
      });
    }
    case EDIT_FILE_AWMSG: {
      return Object.assign({}, state, {
        listAWSMG: [
          action.payload,
          ...state.listAWSMG.filter(
            element => element.id !== action.payload.id,
          ),
        ],
        AWSMGFileToAdd: {id: null},
      });
    }
    case ADD_FILE_AWMSG: {
      return Object.assign({}, state, {
        listAWSMG: [action.payload].concat(state.listAWSMG),
        AWSMGFileToAdd: action.payload,
      });
    }
    case DELETE_FILE_AWMSG: {
      return Object.assign({}, state, {
        listAWSMG: [
          ...state.listAWSMG.filter(element => element.id !== action.payload),
        ],
      });
    }
    default:
      return state;
  }
}
