/* eslint import/no-anonymous-default-export: "off" */

import {
  GET_SMC_FILE_LIST,
  ADD_FILE_SMC,
  EDIT_FILE_SMC,
  DELETE_FILE_SMC,
} from "../../Const/ActionTypes";

const initialState = {
  listSMC: [],
  smcFileToAdd: {
    id: null,
    communInformation: undefined,
    keyDocumentsSection: undefined,
    eamsSection: undefined,
    indicationUnderReviewSection: undefined,
    targetPopulationForSubmissionSection: undefined,
    conditionsForApprovalSection: undefined,
    doApprovalConditionsSection: undefined,
    submissionDatesSection: undefined,
    keyConclusionSection: undefined,
    briefingNoteSection: undefined,
    backgroundSection: undefined,
    natureOfConditionSection: undefined,
    summaryOfEvidenceOnComparativeEfficacySection: undefined,
    summaryOfEvidenceOnComparativeSafetySection: undefined,
    summaryOfClinicalEffectivenessIssuesSection: undefined,
    summaryOfPatientAndClinicianEngagementSection: undefined,
    summaryOfUltraOrphanDecisionMakingFrameworkSection: undefined,
    valuForMoneySection: undefined,
    impactBeyondDirectHealthBenefitsAndOnSpecialistServicesSection: undefined,
    costsToNhsAndPersonalSocialServicesSection: undefined,
    conclusionSection: undefined,
    summaryOfComparativeHealthEconomicEvidenceSection: undefined,
    summaryOfPatientAndPublicInvolvementSection: undefined,
    additionalInformationGuidelinesAndProtocolsSection: undefined,
    additionalInformationComparatorsSection: undefined,
    costOfRelevantComparatorsSection: undefined,
    additionalInformationBudgetImpactSection: undefined,
    detailsOfTheHealthEconomicStudySection: undefined,
    comparatorSection: undefined,
    populationSection: undefined,
    nctNumbersSection: undefined,
    realWorldDataSection: undefined, //
    qualityOfLifeSection: undefined, //
    indirectComparaisonSection: undefined, //
    rationaleCommentSection: undefined, //
    secondBudgetImpactSection: undefined, //
    treatmentLineSection: undefined, //
    // evaluationLinksSectionSection: undefined,
  },
  userOptions: {
    id: undefined,
    userId: undefined,
    smcSections: undefined,
    smcHeader: undefined,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SMC_FILE_LIST: {
      return Object.assign({}, state, {
        listSMC: action.payload,
      });
    }
    case EDIT_FILE_SMC: {
      return Object.assign({}, state, {
        listSMC: [
          action.payload,
          ...state.listSMC.filter(
            (element) => element.id !== action.payload.id
          ),
        ],
        smcFileToAdd: { id: null },
      });
    }
    case ADD_FILE_SMC: {
      return Object.assign({}, state, {
        listSMC: [action.payload].concat(state.listSMC),
        smcFileToAdd: action.payload,
      });
    }
    case DELETE_FILE_SMC: {
      return Object.assign({}, state, {
        listSMC: [
          ...state.listSMC.filter((element) => element.id !== action.payload),
        ],
      });
    }
    default:
      return state;
  }
}
