/* eslint import/no-anonymous-default-export: "off" */

import {
  ADD_FILE_CADTH,
  DELETE_FILE_CADTH,
  EDIT_FILE_CADTH,
  GET_CADTH_FILE_LIST,
} from "../../Const/ActionTypes";

const initialState = {
  listCadth: [],
   CadthFileToAdd: {
    id: undefined,
    communInformation:undefined,
    submissionSection:undefined,
    keyMilestonesSection:undefined,
    keyDocumentsSection:undefined,
    listingRequest:undefined,
    recommendationSection:undefined,
    reasonsForTheRecommendation:undefined,
    comparatorNameSection:undefined,
    implementationConsiderations:undefined,
    ofNote:undefined,
    otherDiscussionPoints:undefined,
    researchGaps:undefined,
    background:undefined,
    submissionHistory:undefined,
    summaryOfCDECConsiderationsSection:undefined,
    costAndCostEffectiveness:undefined,
    requestForClarification:undefined,
    additionalInformationSection:undefined,
    detailsOfTheHealthEconomicStudySection:undefined,
    comparatorSection:undefined,
    populationSection:undefined,
    realWorldDataSection:undefined,
    qualityOfLifeSection:undefined,
    indirectComparaisonSection:undefined,
    rationaleCommentSection:undefined,
    patientPopulationSection:undefined,
    treatmentLineSection:undefined,
    nctNumbersSection:undefined,
    evalutionLinks:undefined,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CADTH_FILE_LIST: {
      return Object.assign({}, state, {
        listCadth: action.payload,
      });
    }

    case ADD_FILE_CADTH: {
      return Object.assign({}, state, {
        listCadth: [action.payload].concat(state.listCadth),
         CadthFileToAdd: action.payload,
      });
    }
    case EDIT_FILE_CADTH: {
      return Object.assign({}, state, {
        listCadth: [
          action.payload,
          ...state.listCadth.filter(
            (element) => element.id !== action.payload.id
          ),
        ],
         CadthFileToAdd: { id: null },
      });
    }
    case DELETE_FILE_CADTH: {
      return Object.assign({}, state, {
        listCadth: [
          ...state.listCadth.filter((element) => element.id !== action.payload),
        ],
      });
    }
    default:
      return state;
  }
}
