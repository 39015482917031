/* eslint import/no-anonymous-default-export: "off" */

import {
    GET_ICER_FILE_LIST,
    ADD_FILE_ICER,
    EDIT_FILE_ICER,
    DELETE_FILE_ICER,
  } from "../../Const/ActionTypes";
  
  const initialState = {
    listICER: [],
    icerFileToAdd: {
      id: null,
      communSection: undefined,
      productProfileSection: undefined,
      aetinaSection: undefined,
      icerInformationSection: undefined,
      keyDocumentsSection: undefined,
      scopeOfTheReviewSection:undefined,
      drugsUnderReview:undefined,
      conclusionSection:undefined,
      keyPolicyImplicationsSection:undefined,
      clinicalEvidenceSection:undefined,
      harms:undefined,
      soueceOfUncertainty:undefined,
      icerEvidenceRating:undefined,
      economicAssessment:undefined,
      longTermCostEffectiveness:undefined,
      icerValueBasedPrice:undefined,
      potentialShortTermBudgetImpact:undefined,
      otherConsiderations:undefined,
      secondBudgetImpactSection:undefined,
      nctNumbersSection:undefined,
      realWorldDataSection:undefined,
      qualityOfLifeSection:undefined,
      indirectComparaisonSection:undefined,
      rationaleCommentSection:undefined
    },
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_ICER_FILE_LIST: {
        return Object.assign({}, state, {
            listICER : action.payload,
        });
      }
      case EDIT_FILE_ICER: {
        return Object.assign({}, state, {
            listICER: state.listICER.map((element) =>
            element.id === action.payload.id ? action.payload : element
          ),
       
          icerFileToAdd: { id: null },
        });
      }
      case ADD_FILE_ICER: {
        return Object.assign({}, state, {
            listICER: [action.payload].concat(state.listICER),
            icerFileToAdd: action.payload,
        });
      }
  
      case DELETE_FILE_ICER: {
        return Object.assign({}, state, {
            listICER: [
            ...state.listICER.filter((element) => element.id !== action.payload),
          ],
        });
      }
      default:
        return state;
    }
  }
  