/* eslint import/no-anonymous-default-export: "off" */

import {
  GET_ZINL_FILE_LIST,
  ADD_FILE_ZINL,
  EDIT_FILE_ZINL,
  DELETE_FILE_ZINL,
} from '../../Const/ActionTypes';

const initialState = {
  listZinl: [],
  zinlFileToAdd: {
    id: null,
  },
  userOptions: {
    id: undefined,
    userId: undefined,
    tlvSections: undefined,
    tlvHeader: undefined,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ZINL_FILE_LIST: {
      return Object.assign({}, state, {
        listZinl: action.payload,
      });
    }
    case EDIT_FILE_ZINL: {
      return Object.assign({}, state, {
        listZinl: [
          action.payload,
          ...state.listZinl.filter(element => element.id !== action.payload.id),
        ],
        zinlFileToAdd: {id: null},
      });
    }
    case ADD_FILE_ZINL: {
      return Object.assign({}, state, {
        listZinl: [action.payload].concat(state.listZinl),
        zinlFileToAdd: action.payload,
      });
    }
    case DELETE_FILE_ZINL: {
      return Object.assign({}, state, {
        listZinl: [
          ...state.listZinl.filter(element => element.id !== action.payload),
        ],
      });
    }
    default:
      return state;
  }
}
