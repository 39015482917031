/* eslint import/no-anonymous-default-export: "off" */

import {
  GET_NIHTA_FILE_LIST,
  ADD_FILE_NIHTA,
  EDIT_FILE_NIHTA,
  DELETE_FILE_NIHTA,
} from '../../Const/ActionTypes';

const initialState = {
  listNihta: [],
  NihtaFileToAdd: {
    id: null,
    communInformation: undefined,
    keyConclusionSection: undefined,
    efficacitySection: undefined,
    safetySection: undefined,
    economicEvaluationSection: undefined,
    realWorldDataSection: undefined,
    qualityOfLifeSection: undefined,
    indirectComparaisonSection: undefined,
    nctNumbersSection: undefined,
    rationaleCommentSection: undefined,
    treatmentLineSection: undefined,
    secondBudgetImpactSection: undefined,
    costSection: undefined,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_NIHTA_FILE_LIST: {
      return Object.assign({}, state, {
        listNihta: action.payload,
      });
    }

    case ADD_FILE_NIHTA: {
      return Object.assign({}, state, {
        listNihta: [action.payload].concat(state.listNihta),
        NihtaFileToAdd: action.payload,
      });
    }

    case EDIT_FILE_NIHTA: {
      return Object.assign({}, state, {
        listNihta: [
          action.payload,
          ...state.listNihta.filter(
            element => element.id !== action.payload.id,
          ),
        ],
        NihtaFileToAdd: {id: null},
      });
    }

    case DELETE_FILE_NIHTA: {
      return Object.assign({}, state, {
        listNihta: [
          ...state.listNihta.filter(element => element.id !== action.payload),
        ],
      });
    }
    default:
      return state;
  }
}
