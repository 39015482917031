/* eslint import/no-anonymous-default-export: "off" */
import {
  GET_ACTIVE_INGREDIENT,
  ADD_ACTIVE_INGREDIENT,
  EDIT_ACTIVE_INGREDIENT,
  DELETE_ACTIVE_INGREDIENT,
  GET_ACTIVE_INGREDIENT_PAGINATED,
} from "../../Const/ActionTypes";

const initialState = {
  activeIngredientList: [],
  activeIngredientListPaginnated: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ACTIVE_INGREDIENT: {
      return Object.assign({}, state, {
        activeIngredientList: action.payload,
      });
    }
    case GET_ACTIVE_INGREDIENT_PAGINATED: {
      return Object.assign({}, state, {
        activeIngredientListPaginnated: action.payload,
      });
    }
    case ADD_ACTIVE_INGREDIENT: {
      return Object.assign({}, state, {
        activeIngredientList: action.payload.concat(state.activeIngredientList),
        activeIngredientListPaginnated: action.payload.concat(
          state.activeIngredientListPaginnated
        ),
      });
    }
    case EDIT_ACTIVE_INGREDIENT: {
      return Object.assign({}, state, {
        activeIngredientList: [
          action.payload,
          ...state.activeIngredientList.filter(
            (element) => element.id !== action.payload.id
          ),
        ],
        activeIngredientListPaginnated: [
          action.payload,
          ...state.activeIngredientListPaginnated.filter(
            (element) => element.id !== action.payload.id
          ),
        ],
      });
    }

    case DELETE_ACTIVE_INGREDIENT: {
      return Object.assign({}, state, {
        activeIngredientList: [
          ...state.activeIngredientList.filter(
            (element) => element.id !== action.payload
          ),
        ],
        activeIngredientListPaginnated: [
          ...state.activeIngredientListPaginnated.filter(
            (element) => element.id !== action.payload
          ),
        ],
      });
    }
    default:
      return state;
  }
}
