/* eslint import/no-anonymous-default-export: "off" */
import {
  GET_TRF_FILE_LIST,
  ADD_FILE_TRF,
  DELETE_FILE_TRF,
  EDIT_FILE_TFR,
} from '../../Const/ActionTypes';
const initialState = {
  listTFR: [],
  tfrFileToAdd: {
    id: null,
  },
};
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TRF_FILE_LIST: {
      return Object.assign({}, state, {
        listTFR: action.payload,
      });
    }

    case ADD_FILE_TRF: {
      return Object.assign({}, state, {
        listTFR: [action.payload].concat(state.listTFR),
        tfrFileToAdd: action.payload,
      });
    }

    case EDIT_FILE_TFR: {
      return Object.assign({}, state, {
        listTFR: state.listTFR.map((element) =>
        element.id === action.payload.id ? action.payload : element
      ),
        tfrFileToAdd: {id: null},
      });
    }
    case DELETE_FILE_TRF: {
      return Object.assign({}, state, {
        listTFR: [
          ...state.listTFR.filter(element => element.id !== action.payload),
        ],
      });
    }
    default:
      return state;
  }
}
