/* eslint import/no-anonymous-default-export: "off" */

import {
  GET_LIST_SPECIALITY,
  ADD_SPECIALITY,
  EDIT_SPECIALITY,
  DELETE_SPECIALITY,
  GET_LIST_SPECIALITY_PAGINATED,
} from "../../Const/ActionTypes";

const initialState = {
  listSpeciality: [],
  listSpecialityPaginated: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LIST_SPECIALITY: {
      return Object.assign({}, state, {
        listSpeciality: action.payload,
      });
    }
    case GET_LIST_SPECIALITY_PAGINATED: {
      return Object.assign({}, state, {
        listSpecialityPaginated: action.payload,
      });
    }
    case ADD_SPECIALITY: {
      return Object.assign({}, state, {
        listSpeciality: action.payload.concat(state.listSpeciality),
        listSpecialityPaginated: action.payload.concat(
          state.listSpecialityPaginated
        ),
      });
    }
    case EDIT_SPECIALITY: {
      return Object.assign({}, state, {
        listSpeciality: [
          action.payload,
          ...state.listSpeciality.filter(
            (element) => element.id !== action.payload.id
          ),
        ],
        listSpecialityPaginated: [
          action.payload,
          ...state.listSpecialityPaginated.filter(
            (element) => element.id !== action.payload.id
          ),
        ],
      });
    }

    case DELETE_SPECIALITY: {
      return Object.assign({}, state, {
        listSpeciality: state.listSpeciality.filter(
          (element) => element.id !== action.payload
        ),

        listSpecialityPaginated: state.listSpecialityPaginated.filter(
          (element) => element.id !== action.payload
        ),
      });
    }
    default:
      return state;
  }
}
