import React, { useCallback, useState } from "react";

import { Link, NavLink } from "react-router-dom";
import {
  americaRoutes,
  apacRoutes,
  ecoClinicalRoutes,
  europeRoutes,
  franceRoutes,
  pricingRoutes,
  refApplicatifRoutes,
} from "../../Const/Routes";

import Logo from "../../images/logo.svg";
import UserPic from "../../images/user-photo.png";

const basePath = "/admin";

const intitailState = {
  franceRoutes: false,
  europeRoutes: false,
  americaRoutes: false,
  apacRoutes: false,
  ecoClinicalRoutes: false,
  pricingRoutes: false,
  refApplicatifRoutes: false,
};

export default function NavBar() {
  const [regionIsActive, setRegionIsActive] = useState({ ...intitailState });

  const navLinkCallback = useCallback((regionName = null) => {
    if (regionName) {
      setRegionIsActive({
        ...intitailState,
        [regionName]: true,
      });
    } else {
      setRegionIsActive({ ...intitailState });
    }
  }, []);

  return (
    <header className="header">
      <div className="header__container _container">
        <Link to={basePath} className="header__logo">
          <img src={Logo} alt="Prismaaccess" />
        </Link>

        <div className="dropdown">
          <button
            className={`dropbtn menu__body menu__link ${
              regionIsActive["franceRoutes"] && "dropbtn-active"
            }`}
          >
            France
          </button>

          <div className="menu__item dropdown-content">
            {franceRoutes.map(({ name, path }) => (
              <NavLink
                key={name}
                to={`${basePath}/${path}/list`}
                onClick={() => navLinkCallback("franceRoutes")}
                className={(isActive) =>
                  isActive ? "dropdown-content-selected" : ""
                }
              >
                {name}
              </NavLink>
            ))}
          </div>
        </div>

        <div className="dropdown">
          <button
            className={`dropbtn menu__body menu__link ${
              regionIsActive["europeRoutes"] && "dropbtn-active"
            }`}
          >
            Europe
          </button>
          <div className="menu__item dropdown-content">
            {europeRoutes.map(({ name, path }) => (
              <NavLink
                key={name}
                to={`${basePath}/${path}/list`}
                onClick={() => navLinkCallback("europeRoutes")}
                className={(isActive) =>
                  isActive ? "dropdown-content-selected" : ""
                }
              >
                {name}
              </NavLink>
            ))}
          </div>
        </div>

        <div className="dropdown">
          <button
            className={`dropbtn menu__body menu__link ${
              regionIsActive["americaRoutes"] && "dropbtn-active"
            }`}
          >
            America
          </button>
          <div className="menu__item dropdown-content">
            {americaRoutes.map(({ name, path }) => (
              <NavLink
                key={name}
                to={`${basePath}/${path}/list`}
                onClick={() => navLinkCallback("americaRoutes")}
                className={(isActive) =>
                  isActive ? "dropdown-content-selected" : ""
                }
              >
                {name}
              </NavLink>
            ))}
          </div>
        </div>

        <div className="dropdown">
          <button
            className={`dropbtn menu__body menu__link ${
              regionIsActive["apacRoutes"] && "dropbtn-active"
            }`}
          >
            APAC
          </button>
          <div className="menu__item dropdown-content">
            {apacRoutes.map(({ name, path }) => (
              <NavLink
                key={name}
                to={`${basePath}/${path}/list`}
                onClick={() => navLinkCallback("apacRoutes")}
                className={(isActive) =>
                  isActive ? "dropdown-content-selected" : ""
                }
              >
                {name}
              </NavLink>
            ))}
          </div>
        </div>

        <div className="dropdown">
          <button
            className={`dropbtn menu__body menu__link ${
              regionIsActive["ecoClinicalRoutes"] && "dropbtn-active"
            }`}
          >
            Eco & Clinical templates
          </button>
          <div className="menu__item dropdown-content">
            {ecoClinicalRoutes.map(({ name, path }) => (
              <NavLink
                key={name}
                to={`${basePath}/${path}/list`}
                onClick={() => navLinkCallback("ecoClinicalRoutes")}
                className={(isActive) =>
                  isActive ? "dropdown-content-selected" : ""
                }
              >
                {name}
              </NavLink>
            ))}
          </div>
        </div>

        <div className="dropdown">
          <button
            className={`dropbtn menu__body menu__link ${
              regionIsActive["pricingRoutes"] && "dropbtn-active"
            }`}
          >
            Pricing data
          </button>
          <div className="menu__item dropdown-content">
            {pricingRoutes.map(({ name, path }) => (
              <NavLink
                key={name}
                to={`${basePath}/${path}/list`}
                onClick={() => navLinkCallback("pricingRoutes")}
                className={(isActive) =>
                  isActive ? "dropdown-content-selected" : ""
                }
              >
                {name}
              </NavLink>
            ))}
          </div>
        </div>

        <div className="dropdown">
          <button
            className={`dropbtn menu__body menu__link ${
              regionIsActive["refApplicatifRoutes"] && "dropbtn-active"
            }`}
          >
            Référentiel applicatif
          </button>
          <div className="menu__item dropdown-content">
            {refApplicatifRoutes.map(({ name, path }) => (
              <NavLink
                key={name}
                to={`${basePath}/${path}/list`}
                onClick={() => navLinkCallback("refApplicatifRoutes")}
                className={(isActive) =>
                  isActive ? "dropdown-content-selected" : ""
                }
              >
                {name}
              </NavLink>
            ))}
          </div>
        </div>

        <div className="header__actions actions-header">
          <button className="actions-header__button button">
            <Link
              to={`${basePath}/user`}
              style={{ color: "white", textDecoration: "none" }}
              onClick={() => navLinkCallback()}
            >
              Gestion des comptes
            </Link>
          </button>

          <div className="actions-header__profile profile">
            <p className="profile__name">
              Welcome,
              <br />
              <span>Demo </span>
            </p>
            <div className="profile__image _icon-profile">
              <span>
                <img src={UserPic} alt="profile" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
