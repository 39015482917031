export const ClassificationList = [
  { label: "Biological", value: "1" },
  { label: "Biological (new product)", value: "2" },
  { label: "New product", value: "3" },
  { label: "New product (Reference)", value: "4" },
];
export const CommissionList = [
  { label: "Assessement", value: "1" },
  { label: "Addendum", value: "2" },
  { label: "Consulation", value: "3" },
];
export const StatusList = [
  { label: "", value: 1 },
  { label: "No status", value: 2 },
  { label: "Evaluation of § 35a SGB V started", value: 3 },
  { label: "Statements awaited ", value: 4 },
  { label: "Decision is being prepared", value: 5 },
  { label: "Process completed", value: 6 },
  { label: "Released", value: 7 },
  { label: "Submission for comments initiated", value: 8 },
  { label: "Process suspended", value: 9 },
  { label: "Proceedings stopped", value: 10 },
  { label: "Process start postponed", value: 11 },
  { label: "No GBA decision", value: 13 },
  { label: "Procedure without dossier", value: 14 },
];
export const mkOrphelin = [
  { label: "Orphan drug", value: "1" },
  { label: "Previously Orphan", value: "2" },
];
export const grant = [
  { label: "Positive", value: "1" },
  { label: "Négative", value: "2" },
  { label: "Neutral", value: "3" },
];
export const designationRaison = [
  {
    label: "Request of sponsor",
    value: "1",
  },
  {
    label: "End of 10-years market exclusivity",
    value: "2",
  },
];

export const marketingType = [
  { id: 1, label: "Biosimilar", value: "Biosimilaire" },
  { id: 2, label: "Generic", value: "Generic" },
];

export const objetList = [
  { id: 0, label: "", value: 0 },
  { id: 2, label: "Décision du Collège de la HAS", value: 1 },
];
export const reimbursementCriteria = [
  {
    id: 1,
    label:
      "a) Severity, duration and sequelae of the different pathologies for which they are indicated.",
    value: "Biosimilaire",
  },
  {
    id: 2,
    label: "b) Specific needs of certain populations.",
    value: "Generic",
  },
  {
    id: 3,
    label:
      "c) Therapeutic and social value of the drug and its incremental clinical benefit, taking into account its cost-effectiveness ratio.",
    value: "Generic",
  },
  {
    id: 4,
    label:
      "d) Rationalization of public spending for pharmaceutical services and budgetary impact on the National Health System.",
    value: "Generic",
  },
  {
    id: 5,
    label:
      "e) Existence of drugs or other therapeutic alternatives for the same conditions at a lower price or lower cost of treatment.",
    value: "Generic",
  },
  { id: 6, label: "f) Degree of innovation of the drug.", value: "Generic" },
];

export const ManagementAgreement = [
  { label: "Ceiling expenditure", value: "Ceiling expenditure" },
  { label: "Max. cost per patient", value: "Max. cost per patient" },
  { label: "No MEA", value: "No MEA" },
  { label: "Cost sharing", value: "Cost sharing" },
  { label: "Risk sharing", value: "Risk sharing" },
  { label: "Payment by result", value: "Payment by result" },
  { label: "Success fee", value: "Success fee" },
  { label: "Capping/payback", value: "Capping/payback" },
  { label: "Fixed cost per patient", value: "Fixed cost per patient" },
  { label: "Price/Volume", value: "Price/Volume" },
];

export const ManagementAgreementDiscount = [
  { id: 1, label: "4 %", value: "4 %" },
  { id: 2, label: "7.5 %", value: "7.5 %" },
  { id: 3, label: "15 %", value: "15 %" },
];

export const emaStatusAndDecisionList = [
  { id: 0, label: "", value: "0" },
  { id: 1, label: "Authorized", value: "1" },
  { id: 2, label: "Refused", value: "2" },
  { id: 3, label: "Suspended", value: "3" },
  { id: 4, label: "Withdrawn post-authorisation", value: "4" },
  { id: 5, label: "Withdrawn initial authorization", value: "5" },
  { id: 6, label: "Ongoing", value: "6" },
];

export const recommendationList = [
  { id: 1, label: "Outcome" },
  { id: 2, label: "Recommended" },
  { id: 3, label: "Deferred" },
  { id: 4, label: "Rejected" },
  { id: 5, label: "Withdrawn" },
  { id: 6, label: "Awaiting TGA outcomes" },
];
export const TimeZoneList = [
  { label: "Per treatment", value: "Per treatment" },
  { label: "Per treatment cycle", value: "Per treatment cycle" },
  { label: "Per year", value: "Per year" },
  { label: "Year 1", value: "Year 1" },
  { label: "Year 2", value: "Year 2" },
  { label: "Year 3", value: "Year 3" },
  { label: "Year 4", value: "Year 4" },
  { label: "Year 5", value: "Year 5" },
];

export const conclusionOnEconomicEvidenceList = [
  { label: "PE assessment sufficient", value: "1" },
  { label: "PE assessment insufficient", value: "2" },
  {
    label: "Initial PE evaluation and design outcomes research sufficient",
    value: "3",
  },
  {
    label: "Initial PE evaluation and design outcomes research insufficient",
    value: "4",
  },
  { label: "Outcomes research sufficient/ PE sufficient", value: "5" },
  { label: "Outcomes research sufficient/ PE insufficient", value: "6" },
  { label: "Outcomes research insufficient/ PE sufficient", value: "7" },
  { label: "Outcomes research insufficient/ PE insufficient", value: "8" },
  { label: "Treatment cost-effective", value: "9" },
  { label: "Treatment not cost-effective", value: "10" },
  { label: "Candidate for Financial Arrangement", value: "11" },
];

export const RecommendationList = [
  { label: "GVS 1A (same therapeutic value)", value: "1" },
  { label: "GVS 1B (superior therapeutic value)", value: "2" },
  { label: "Accepted (intramural drug)", value: "3" },
  { label: "Accepted (intramural drug)", value: "4" },
  { label: "Rejected (lower therapeutic value, no PE)", value: "5" },
  { label: "Rejected (PE insufficient)", value: "6" },
  { label: "Conditional reimbursement approved", value: "7" },
  { label: "Conditional reimbursement not approved", value: "8" },
  { label: "Candidate for financial arrangement", value: "9" },
  { label: "Suspended", value: "10" },
];

export const hTAdecision = [
  { label: "Ongoing", color: "#4397ac", value: "Ongoing" },
  { label: "N/A", color: "#4397ac", value: "N/A" },
  { label: "Positive", color: "green", value: "Yes_Green" },
  { label: "Partial", color: "orange", value: "Yes_Orange" },
  { label: "Negative", color: "red", value: "Yes_Red" },
  { label: "Non-submission", color: "#4397ac", value: "Non-submission" },
  { label: "Suspended", color: "#4397ac", value: "Suspended" },
  { label: "Deferred", color: "#4397ac", value: "Deferred" },
];

export const hTAdecisionList = [
  { label: "Yes", color: "#4397ac", value: "Yes" },
  { label: "No", color: "#4397ac", value: "No" },
  { label: "Ongoing", color: "#4397ac", value: "Ongoing" },
  { label: "N/A", color: "#4397ac", value: "N/A" },
  { label: "Positive", color: "green", value: "Yes_Green" },
  { label: "Partial", color: "orange", value: "Yes_Orange" },
  { label: "Negative", color: "red", value: "Yes_Red" },
  { label: "Non-submission", color: "#4397ac", value: "Non-submission" },
  { label: "Suspended", color: "#4397ac", value: "Suspended" },
  { label: "Deferred", color: "#4397ac", value: "Deferred" },
];

export const hTAdecisionAotmit = [
  { label: "Yes", color: "#4397ac", value: "Yes" },
  { label: "No", color: "#4397ac", value: "No" },
  { label: "Ongoing", color: "#4397ac", value: "Ongoing" },
  { label: "N/A", color: "#4397ac", value: "N/A" },
  { label: "Non-submission", color: "#4397ac", value: "Non-submission" },
  { label: "Suspended", color: "#4397ac", value: "Suspended" },
  { label: "Deferred", color: "#4397ac", value: "Deferred" },
  { label: "Positive", color: "#4397ac", value: "Positive" },
  { label: "Partial", color: "#4397ac", value: "Partial" },
  { label: "Negative", color: "#4397ac", value: "Negative" },
];

export const hTAdecisionApac = [
  { label: "Ongoing", color: "#4397ac", value: "Ongoing" },
  { label: "N/A", color: "#4397ac", value: "N/A" },
  { label: "Non-submission", color: "#4397ac", value: "Non-submission" },
  { label: "Suspended", color: "#4397ac", value: "Suspended" },
  { label: "Deferred", color: "#4397ac", value: "Deferred" },
  { label: "Positive", color: "#4397ac", value: "Positive" },
  { label: "Partial", color: "#4397ac", value: "Partial" },
  { label: "Negative", color: "#4397ac", value: "Negative" },
  { label: "ATU", color: "#4397ac", value: "ATU" },
];

export const SubmissionTypeList = [
  { value: "Limited Submission", label: "Limited Submission", color: "black" },
  { value: "Full Submission", label: "Full Submission", color: "black" },
  { value: "Non-submission", label: "Non-submission", color: "black" },
  { value: "Independent Review", label: "Independent Review", color: "black" },
  { value: "Resubmission", label: "Resubmission", color: "black" },
  { value: "Not applicable", label: "Not applicable", color: "black" },
  { value: "NICE HST", label: "NICE HST", color: "black" },
];

export const statusList = [
  {
    value: "Marketing authorisation withdrawn",
    label: "Marketing authorisation withdrawn",
    color: "black",
  },
  {
    value: "Partially superseded",
    label: "Partially superseded",
    color: "black",
  },
  { value: "Recommended", label: "Recommended", color: "black" },
  {
    value: "Recommended with restrictions",
    label: "Recommended with restrictions",
    color: "black",
  },
  { value: "Superseded", label: "Superseded", color: "black" },
];

export const reimbursementList = [
  { id: 1, value: "Yes", label: "Positive", color: "green" },
  { id: 2, value: "No", label: "Negative", color: "red" },
  { id: 3, value: "Partial", label: "Partial", color: "orange" },
  { id: 4, value: "Ongoing", label: "Ongoing", color: "#4397ac" },
  { id: 5, value: "N/A", label: "N/A", color: "#4397ac" },
];

export const reimbursementListNcpe = [
  { id: 1, value: "Yes", label: "Yes", color: "green" },
  { id: 2, value: "No", label: "No", color: "red" },
  { id: 3, value: "Partial", label: "Partial", color: "orange" },
  { id: 4, value: "Ongoing", label: "Ongoing", color: "#4397ac" },
  { id: 5, value: "N/A", label: "N/A", color: "#4397ac" },
  { id: 6, value: "Suspended", label: "Suspended", color: "#4307ac" },
  { id: 7, value: "Positive", label: "Positive", color: "#4120ec" },
  { id: 8, value: "Negative", label: "Negative", color: "#1397ac" },
];

export const reimbursementListSpain = [
  { id: 1, value: "Yes", label: "Positive", color: "green" },
  { id: 2, value: "No", label: "Negative", color: "red" },
  { id: 3, value: "Partial", label: "Partial", color: "orange" },
  { id: 4, value: "Ongoing", label: "Ongoing", color: "#4397ac" },
  { id: 5, value: "N/A", label: "N/A", color: "#4397ac" },
  { id: 6, value: "Suspended", label: "Suspended", color: "#4389ac" },
];

export const reimbursementListAotmit = [
  { id: 1, value: "Yes", label: "Yes", color: "green" },
  { id: 2, value: "No", label: "No", color: "red" },
  { id: 3, value: "Partial", label: "Partial", color: "orange" },
  { id: 4, value: "Ongoing", label: "Ongoing", color: "#4397ac" },
  { id: 5, value: "N/A", label: "N/A", color: "#4397ac" },
  { id: 6, value: "Suspended", label: "Suspended", color: "#4389ac" },
  { id: 7, value: "Positive", label: "Positive", color: "green" },
  { id: 8, value: "Negative", label: "Negative", color: "red" },
];
export const classificationList = [
  { label: "Additional Range", value: "Complement de gamme" },
  { label: "Biosimilar", value: "Biosimilaire" },
  { label: "Hybrid", value: "Hybride" },
  { label: "New Combination", value: "Nouvelle association" },
  { label: "New indication", value: "Nouvelle indication" },
  { label: "New product", value: "Nouveau produit" },
  {
    label: "Change to an existing indication",
    value: "Modification de l'indication",
  },
  { label: "New molecular entity", value: "Nouvelle entité" },
  { label: "Reappraisal", value: "Reappraisal" },
  { label: "Other", value: "Other" },
];

export const classificationListCheckBox = [
  {
    name: "checkFirstInClass",
    nameEn: "First in class",
    nameFr: "Premier en classe",
  },
  {
    name: "checkNewMolecularEntity",
    nameEn: "New molecular entity",
    nameFr: "Nouvelle entité moléculaire",
  },
  { name: "checkNewProduct", nameEn: "New product", nameFr: "Nouveau produit" },
  {
    name: "checkNewIndication",
    nameEn: "New indication",
    nameFr: "Nouvelle indication",
  },

  {
    name: "checkNewCombination",
    nameEn: "New combination",
    nameFr: "Nouvelle combinaison",
  },
  {
    name: "checkAdditionalRange",
    nameEn: "Additional range",
    nameFr: "Gamme supplémentaire",
  },
  {
    name: "checkChangeExistingIndication",
    nameEn: "Change to an existing indication",
    nameFr: "Modification d'une indication existante",
  },
  { name: "checkOther", nameEn: "Other", nameFr: "Autre" },
];

export const classificationListTraduction = [
  { nameEn: "", nameFr: "" },
  { nameEn: "Additional Range", nameFr: "Complement de gamme" },
  { nameEn: "Biosimilar", nameFr: "Biosimilaire" },
  { nameEn: "Hybrid", nameFr: "Hybride" },
  { nameEn: "New Combination", nameFr: "Nouvelle association" },
  { nameEn: "New indication", nameFr: "Nouvelle indication" },
  { nameEn: "New product", nameFr: "Nouveau produit" },
  {
    nameEn: "Change to an existing indication",
    nameFr: "Modification de l'indication",
  },
  { nameEn: "New molecular entity", nameFr: "Nouvelle entité" },
  { nameEn: "Reappraisal", nameFr: "Reappraisal" },
  { nameEn: "Generic", nameFr: "Generique" },
  { nameEn: "Other", nameFr: "Other" },
];

export const classificationListTraductionApac = [
  { nameEn: "", nameFr: "" },
  { nameEn: "Additional Range", nameFr: "Complement de gamme" },
  { nameEn: "Biosimilar", nameFr: "Biosimilaire" },
  { nameEn: "Generic", nameFr: "Generique" },
  { nameEn: "Hybrid", nameFr: "Hybride" },
  { nameEn: "New Combination", nameFr: "Nouvelle association" },
  { nameEn: "New indication", nameFr: "Nouvelle indication" },
  { nameEn: "New product", nameFr: "Nouveau produit" },
  {
    nameEn: "Change to an existing indication",
    nameFr: "Modification de l'indication",
  },
  { nameEn: "New molecular entity", nameFr: "Nouvelle entité" },
  { nameEn: "Reappraisal", nameFr: "Reappraisal" },
  { nameEn: "Continuity of support ", nameFr: "Continuité de prise en charge" },
  { nameEn: "Other", nameFr: "Other" },
];

export const recordedScoreQualityOfLifeList = [
  { id: 1, label: " ", value: " " },
  { id: 2, label: "N/A", value: "N/A" },
  { id: 3, label: "Not scored", value: "Not scored" },
  {
    id: 4,
    label:
      "2 : Published evidence of significant improvement in overall Quality of Life (QOL), using a validated tool.",
    value: "2",
  },
  {
    id: 5,
    label:
      "1 : Measurable evidence of significant improvement in relevant aspect(s) of QOL using a validated tool or evidence of lack of deterioration in overall QOL using a validated tool or clear evidence of major improvement in QOL without validated tool (e.g. clinically significant reduction in blood transfusion)",
    value: "1",
  },
  {
    id: 6,
    label: "0 : No QOL data collected in the trial or QOL data not analysed",
    value: "00",
  },
  {
    id: 7,
    label:
      "Minus 1: Measurable evidence of significant deterioration in relevant aspect(s) of QOL using a validated tool or clear evidence of major deterioration in QOL without a validated tool (e.g. clinically significant increase in incidence of febrile neutropenia)",
    value: "Minus 1",
  },
  {
    id: 8,
    label:
      "Minus 2:  Published evidence of significant deterioration in overall QOL using a validated tool.",
    value: "Minus 2",
  },
];

export const recordedScoreToxicityComparedList = [
  { label: " ", value: " " },
  { label: "N/A", value: "N/A" },
  { label: "Not scored", value: "Not scored" },
  { label: "2 : Significant improvement", value: "2" },
  { label: "1 : Improved", value: "1" },
  { label: "0 : Equal", value: "00" },
  { label: "Minus 1: Worsened", value: "Minus 1" },
  { label: "Minus 2:  Significantly worsened", value: "Minus 2" },
];
export const recordedScoreDegreeOfClinicalList = [
  { label: " ", value: " " },
  { label: "N/A", value: "N/A" },
  { label: "Not scored", value: "Not scored" },
  {
    label:
      "3 : This drug is the first demonstration of efficacy of a systemic therapy for the disease concerned or a step change for the clinical setting concerned",
    value: "3",
  },
  {
    label:
      "0 : This drug is not the first demonstration of efficacy of a systemic therapy for the disease concerned nor a step change for the clinical setting concerned",
    value: "00",
  },
];

export const recordedScoreCostList = [
  { id: 1, label: " ", value: " " },
  { id: 2, label: "N/A", value: "N/A" },
  { id: 3, label: "2 : £30-40,000", value: "2" },
  { id: 4, label: "1 : £40-50,000", value: "1" },
  { id: 5, label: "0 : £50-60,000", value: "00" },
  { id: 6, label: "Minus 1 : £60-80,000", value: "Minus 1" },
  { id: 7, label: "Minus 2 : >£80,000", value: "Minus 2" },
];
export const recordedScoreStrengthEvidence = [
  { id: 1, label: " ", value: " " },
  { id: 2, label: "N/A", value: "N/A" },
  { id: 3, label: "Not scored", value: "Not scored" },
  {
    id: 4,
    label:
      "A: Two or more good quality Phase III Randomised Controlled Trials, both published",
    value: "A",
  },
  {
    id: 5,
    label:
      "B : One good quality Phase III Randomised Controlled Trial, published",
    value: "B",
  },
  { id: 6, label: "C: Comparative Phase II trial, published", value: "C" },
  { id: 7, label: "D : Non-Comparative Phase II, published", value: "D" },
  {
    id: 8,
    label:
      "U1: Unpublished data in abstract form only (Appropriate methodology for the treatment setting, presented at an international meeting)",
    value: "U1",
  },
  {
    id: 9,
    label:
      "U2 : Unpublished data in abstract form only (Methodology inappropriate for treatment setting and/or not presented at international meeting)",
    value: "U2",
  },
];

export const SeverityOfTheConditionList = [
  { id: 1, label: " ", value: " " },
  { id: 2, value: "low", label: "low" },
  { id: 3, value: "moderate", label: "moderate" },
  { id: 4, value: "high", label: "high" },
  { id: 4, value: "very high", label: "very high" },
];
export const RarityOfTheConditionList = [
  { id: 1, label: " ", value: " " },
  { id: 2, value: "Very rare", label: "Very rare" },
  { id: 3, value: "Rare", label: "Rare" },
  { id: 4, value: "Less common", label: "Less common" },
  { id: 4, value: "common", label: "common" },
];

export const listingType = [
  { label: "Matter arising", value: "Matter arising" },
  { label: "Matters Outstanding", value: "Matters Outstanding" },
  { label: "New listing", value: "New listing" },
  { label: "New formulation", value: "New formulation" },
  { label: "New indication", value: "New indication" },
  { label: "New combination", value: "New combination" },
  { label: "New medicine", value: "New medicine" },
  { label: "Change to listing", value: "Change to listing" },
  {
    label: "Change to recommended listing",
    value: "Change to recommended listing",
  },
];
export const submissionList = [
  { id: 1, label: "Submission", value: "Submission" },
  { id: 2, label: "Re-submission", value: "Re-submission" },
  { id: 3, label: "Minor submission", value: "Minor submission" },
  { id: 4, label: "Minor resubmission", value: "Minor resubmission" },
  { id: 5, label: "Addendum", value: "Addendum" },
];
export const pbacRecommendationList = [
  { id: 1, label: "Outcome", value: "Outcome" },
  { id: 2, label: "Recommended", value: "Recommended" },
  { id: 3, label: "Deferred", value: "Deferred" },
  { id: 4, label: "Rejected", value: "Rejected" },
  { id: 5, label: "Withdrawn", value: "Withdrawn" },
  { id: 6, label: "Awaiting TGA outcomes", value: "Awaiting TGA outcomes" },
];
export const recommendedList = [
  { id: 1, label: "Unrestricted", value: "Unrestricted" },
  { id: 2, label: "Restricted", value: "Restricted" },
  {
    id: 3,
    label: "Authority Required (STREAMLINED)",
    value: "Authority Required (STREAMLINED)",
  },
  { id: 4, label: "Authority required", value: "Authority required" },
  {
    id: 5,
    label: "National Immunisation Program",
    value: "National Immunisation Program",
  },
];
export const economicHealthList = [
  { id: 1, label: "Oui", value: "1" },
  { id: 2, label: "Non", value: "2" },
];
export const StrengthOfEvidenceListCDF = [
  {
    id: 1,
    value: "A",
    label:
      "A : Two or more good quality Phase III Randomised Controlled Trials, both published",
  },
  {
    id: 2,
    value: "B",
    label:
      "B : One good quality Phase III Randomised Controlled Trial, published",
  },
  { id: 3, value: "C", label: "C : Comparative Phase II trial, published" },

  { id: 4, value: "D", label: "D : Non-Comparative Phase II, published" },
  {
    id: 5,
    value: "U1",
    label:
      "U1 : Unpublished data in abstract form only (Appropriate methodology for the treatment setting, presented at an international meeting)",
  },
  {
    id: 6,
    value: "U2",
    label:
      "U2 : Unpublished data in abstract form only (Methodology inappropriate for treatment setting and/or not presented at international meeting)",
  },
];
export const RetentionWithinListCDF = [
  { id: 1, value: 1, label: "Yes" },
  { id: 2, value: 2, label: "No" },
  { id: 3, value: 3, label: "Withdrawn" },

  { id: 4, value: 4, label: "Referred" },
  { id: 5, value: 5, lab: "Pending" },
];

export const YesNoList = [
  { id: 1, value: 1, label: "No" },
  { id: 2, value: 2, label: "Yes" },
];
export const CommissionTypeList = [
  { label: "", value: 1 },
  { label: "Assessement", value: 2 },
  { label: "Addendum", value: 3 },
  { label: "Consulation", value: 4 },
];
export const ouiNonList = [
  { id: 1, value: "oui", label: "oui" },
  { id: 2, value: "Non", label: "Non" },
];
export const conclusionList = [
  { id: 1, value: 1, label: "Eligible à l'évaluation médico-économique" },
  { id: 2, value: 2, label: "Non éligible à l'évaluation médico-économique" },
];

export const etudeEconomic = [
  { id: 1, value: "INS", label: "INS" },
  { id: 2, value: "EIT", label: "EIT" },
  { id: 3, value: "REEVAL", label: "REEVAL" },
  { id: 4, value: "REINS", label: "REINS" },
];

export const asmrList = [
  { id: 1, value: "I", label: "I" },
  { id: 2, value: "II", label: "II" },
  { id: 3, value: "III", label: "III" },
  { id: 4, value: "IV", label: "IV" },
  { id: 5, value: "V", label: "V" },
];
export const identitiqueList = [
  { id: 1, value: "Identique", label: "Identique" },
  { id: 2, value: "Différent", label: "Différent" },
];
export const identiqueAMMList = [
  { id: 1, value: "Identique", label: "Identique" },
  { id: 2, value: "Restreint", label: "Restreint" },
  { id: 3, value: "Différent", label: "Différent" },
];

export const impactList = [
  { id: 1, value: "oui", label: "oui" },
  { id: 2, value: "Non", label: "Non" },
];

export const FRList = [
  { id: 1, value: "Analyse coût-efficacité", label: "Analyse coût-efficacité" },
  { id: 2, value: "Analyse coût-utilité", label: "Analyse coût-utilité" },
  { id: 3, value: "Analyse coût-bénéfice", label: "Analyse coût-bénéfice" },
  {
    id: 4,
    value: "Analyse d'impact budgétaire",
    label: "Analyse d'impact budgétaire",
  },
];

export const ENList = [
  {
    id: 1,
    value: "Cost-effectiveness analysis",
    label: "Cost-effectiveness analysis",
  },
  { id: 2, value: "Cost-utility analysis", label: "Cost-utility analysis" },
  { id: 3, value: "Cost-benefit analysis", label: "Cost-benefit analysis" },
  { id: 4, value: "Budget impact analysis", label: "Budget impact analysis" },
];

export const appreciationList = [
  { id: 1, value: "Conforme sans réserve", label: "Conforme sans réserve" },
  {
    id: 2,
    value: "Acceptable avec réserves mineures",
    label: "Acceptable avec réserves mineures",
  },
  {
    id: 3,
    value: "Acceptable avec réserves importantes",
    label: "Acceptable avec réserves importantes",
  },
  {
    id: 4,
    value: "Non conforme (réserve majeure)",
    label: "Non conforme (réserve majeure)",
  },
  { id: 5, value: "Non documenté", label: "Non documenté" },
  { id: 3, value: "Acceptable", label: "Acceptable" },
  {
    id: 4,
    value: "Réserve majeure partielle",
    label: "Réserve majeure partielle",
  },
];
export const SubTreatmentLineList1 = [
  { label: "preterm new born infants", choiceName: "pretermNewBornInfants" },
  {
    label: "term new born infants (0 –27 days)",
    choiceName: "termNewBornInfants",
  },
  {
    label: "infants and toddlers (28 days to 23 months)",
    choiceName: "infantsAndToddlers",
  },
  { label: "children (2 to 11 years)", choiceName: "children" },
  { label: "adolescents (12 to 18 years)", choiceName: "adolescents" },
  { label: "adult", choiceName: "adult" },
];
export const SubTreatmentLineChoice2 = [
  { label: "ECOG 0", choiceName: "ecog0" },
  { label: "ECOG 1", choiceName: "ecog1" },
  { label: "ECOG 2", choiceName: "ecog2" },
  { label: "ECOG 3", choiceName: "ecog3" },
  { label: "ECOG 4", choiceName: "ecog4" },
];
export const SubTreatmentLineChoice4 = [
  { label: "Premenopausal", choiceName: "premenopausal" },
  { label: "Postmenopausal", choiceName: "postmenopausal" },
  { label: "Perimenopausal", choiceName: "perimenopausal" },
];
export const SubTreatmentLineChoice6 = [
  {
    label: "17p deletion",
    choiceName: "deletion17P",
  },
  {
    label: "MSI-H/dMMR",
    choiceName: "msiHDmmr",
  },
];
export const SubTreatmentLineChoice3 = [
  { label: "HER2", choiceName: "her2", choiceList: ["Positive", "Négative"] },
  { label: "HR", choiceName: "hr", choiceList: ["Positive", "Négative"] },
  {
    label: "PIK3CA",
    choiceName: "pik3ca",
    choiceList: ["Mutation", "Wild type"],
  },
  {
    label: "TP53",
    choiceName: "tp53",
    choiceList: ["Mutation", "Wild type"],
  },
];

export const transplantEligibilityList = [
  "Ineligible for HSCT",
  "Eligible for HSCT",
  "Undergone HSCT",
  "Ineligible for autologous SCT",
  "Eligible for autologous SCT",
  "Undergone autologous SCT",
  "Ineligible for allogenic SCT",
  "Eligible for allogenic SCT",
  "Undergone allogenic SCT",
];

export const decisionList = [
  { id: 1, label: "Approved", value: "1" },
  { id: 2, label: "Refused", value: "2" },
  { id: 2, label: "Ongoing", value: "3" },
  { id: 2, label: "Withdrawn", value: "4" },
];

export const communHeaderItems = [
  { label: "ID", up: false, down: false, sorted: false, checked: true },
  {
    label: "Indexation_fr",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_en",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_de",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "Drug name", up: false, down: false, sorted: false, checked: true },
  { label: "Inn", up: false, down: false, sorted: false, checked: true },
  { label: "ATC", up: false, down: false, sorted: false, checked: true },
  { label: "DCI", up: false, down: false, sorted: false, checked: true },
  { label: "Catégorie", up: false, down: false, sorted: false, checked: true },

  {
    label: "MK orphelin",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "Applicant", up: false, down: false, sorted: false, checked: false },
  {
    label: "HTA decision",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Reimbursement",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },

  { label: "Créé", up: false, down: false, sorted: false, checked: true },
  { label: "Modifiés", up: false, down: false, sorted: false, checked: true },
  { label: "On line", up: false, down: false, sorted: false, checked: false },
];

export const HiraHeader = [
  { label: "ID", up: false, down: false, sorted: false, checked: true },
  {
    label: "Indexation_fr",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_en",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_de",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "Drug name", up: false, down: false, sorted: false, checked: false },
  { label: "Inn", up: false, down: false, sorted: false, checked: false },
  {
    label: "Laboratoire",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "ATC", up: false, down: false, sorted: false, checked: true },
  { label: "DCI", up: false, down: false, sorted: false, checked: true },
  { label: "Spécialité", up: false, down: false, sorted: false, checked: true },
  { label: "Créé", up: false, down: false, sorted: false, checked: true },
  { label: "Modifiés", up: false, down: false, sorted: false, checked: true },
  { label: "On line", up: false, down: false, sorted: false, checked: true },
  { label: "Catégorie", up: false, down: false, sorted: false, checked: false },

  {
    label: "MK orphelin",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "Applicant", up: false, down: false, sorted: false, checked: false },
  {
    label: "HTA decision",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Reimbursement",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
];
export const inamiHeader = [
  { label: "ID", up: false, down: false, sorted: false, checked: true },
  {
    label: "Indexation_fr",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_en",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_de",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },

  { label: "Drug name", up: false, down: false, sorted: false, checked: true },

  {
    label: "Active ingredient / INN",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "Créé", up: false, down: false, sorted: false, checked: true },
  { label: "Modifiés", up: false, down: false, sorted: false, checked: true },
  { label: "On line", up: false, down: false, sorted: false, checked: true },
];

export const CDFHeader = [
  { label: "ID", up: false, down: false, sorted: false, checked: true },
  {
    label: "Indexation",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_en",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_de",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },

  {
    label: "Firm",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "Drug Name", up: false, down: false, sorted: false, checked: true },
  { label: "ATC", up: false, down: false, sorted: false, checked: true },
  { label: "DCI", up: false, down: false, sorted: false, checked: true },
  {
    label: "Tumour Type",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },

  { label: "Créé", up: false, down: false, sorted: false, checked: true },
  { label: "Modifiés", up: false, down: false, sorted: false, checked: true },
  { label: "On line", up: false, down: false, sorted: false, checked: true },
];

export const ansmHeaderItems = [
  {
    label: "ID",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "ID ANSM",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Libellé du groupe générique ANSM",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Libellé DCI du groupe générique ANSM",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Date de création du groupe générique au répertoire",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Date de Fin du Groupe Générique",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },

  { label: "Créé", up: false, down: false, sorted: false, checked: true },
  {
    label: "Modifiés",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "On line", up: false, down: false, sorted: false, checked: true },
];
export const CadthHeaderItems = [
  {
    label: "ID",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_en",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_de",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Status",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Type of restriction",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Recommendation",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Generic Name",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Brand Name",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "ATC",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },

  {
    label: "Manufacturer",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },

  { label: "Créé", up: false, down: false, sorted: false, checked: true },
  {
    label: "Modifiés",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Online",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
];
export const PeruHeaderItems = [
  {
    label: "ID",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_en",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_de",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Laboratoire",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "ATC",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Status",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "DCI",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Spécialité",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Agency",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Region",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },

  { label: "Créé", up: false, down: false, sorted: false, checked: true },
  {
    label: "Modifiés",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Online",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
];
export const defaultAgencyList = [
  ///------EUROPE
  { label: "EMA (FR)", value: "Ema" },
  { label: "NICE (UK)", value: "Nice" },
  { label: "SMC (UK)", value: "Smc" },
  { label: "CDF (UK)", value: "Cdf" },
  { label: "TLV (SE)", value: "Tlv" },
  { label: "NT Council", value: "Ntcouncil" },
  { label: "Zinl (NL)", value: "Zinl" },
  { label: "Spain", value: "Spain" },
  { label: "ITALY", value: "Italy" },
  { label: "NCPE (IE)", value: "Ncpe" },
  { label: "AWMSG", value: "Awmsg" },
  { label: "INAMI (BE)", value: "Inami" },
  { label: "AOTMIT – POLAND", value: "Aotmit" },
  //---- FRANCE
  { label: "CEESP (FR)", value: "Ceesp" },
  { label: "RTU (FR)", value: "Rtu" },
  // {label: 'Boîte présentation', value: 'Boite'},
  // {label: 'ANSM', value: 'ANSM'},
  // {label: 'Biosimilaires', value: 'Biosimilaires'},
  // {label: 'TFR', value: 'TFR'},
  // {label: 'Indications JO', value: 'indication_jo'},
  //---- APAC
  { label: "PBAC (AU)", value: "Pbac" },
  { label: "ACE (SG)", value: "Singapore" },
  { label: "HIRA", value: "Hira" },
  { label: "NIHTA", value: "Nihta" },
  { label: "PMDA", value: "Pmda" },
  //---- AMERICA
  { label: "FDA (USA)", value: "Fda" },
];

export const userAgencyList = [
  //---- FRANCE
  {
    France: [
      { label: "CEESP (FR)", value: "Ceesp" },
      { label: "RTU (FR)", value: "Rtu" },
      { label: "Boîte présentation", value: "Smc" },
      { label: "ANSM", value: "Ema" },
      { label: "Biosimilaires", value: "Nice" },
      { label: "TFR", value: "Rtu" },
      { label: "Indications JO", value: "Tlv" },
    ],
  },
  //------EUROPE
  {
    Europe: [
      { label: "EMA (FR)", value: "Ema" },
      { label: "NICE (UK)", value: "Nice" },
      { label: "SMC (UK)", value: "Smc" },
      { label: "CDF (UK)", value: "Cdf" },
      { label: "TLV (SE)", value: "Tlv" },
      { label: "NT Council", value: "Ntcouncil" },
      { label: "Zinl (NL)", value: "Zinl" },
      { label: "Spain", value: "Spain" },
      { label: "ITALY", value: "Italy" },
      { label: "NCPE (IE)", value: "Ncpe" },
      { label: "AWMSG", value: "Awmsg" },
      { label: "INAMI (BE)", value: "Inami" },
      { label: "AOTMIT – POLAND", value: "Aotmit" },
    ],
  },
  //---- APAC
  {
    APAC: [
      { label: "PBAC (AU)", value: "Pbac" },
      { label: "ACE (SG)", value: "Singapore" },
      { label: "HIRA", value: "Hira" },
      { label: "NIHTA", value: "Nihta" },
      { label: "PMDA", value: "Pmda" },
    ],
  },
  //---- AMERICA
  { America: [{ label: "FDA (USA)", value: "Fda" }] },
];

export const permissionList = [
  {
    region: "France",
    agencyLabel: "Transparency (FR)",
    create: false,
    update: false,
    duplicate: false,
    delete: false,
    agency: "Transparence",
  },
  {
    region: "France",
    agencyLabel: "CEESP (FR)",
    create: false,
    update: false,
    duplicate: false,
    delete: false,
    agency: "Ceesp",
  },
  {
    region: "France",
    agencyLabel: "RTU (FR)",
    create: false,
    update: false,
    duplicate: false,
    delete: false,
    agency: "Rtu",
  },
  {
    region: "France",
    agencyLabel: "AP/AC (FR)",
    create: false,
    update: false,
    duplicate: false,
    delete: false,
    agency: "AtuFrance",
  },
  {
    region: "France",
    agencyLabel: "Boîte présentation",
    create: false,
    update: false,
    duplicate: false,
    delete: false,
    agency: "BoitePresentation",
  },
  {
    region: "France",
    agencyLabel: "ANSM",
    create: false,
    update: false,
    duplicate: false,
    delete: false,
    agency: "Ansm",
  },
  {
    region: "France",
    agencyLabel: "Biosimilaires",
    create: false,
    update: false,
    duplicate: false,
    delete: false,
    agency: "Biosimilaire",
  },
  {
    region: "France",
    agencyLabel: "TFR",
    create: false,
    update: false,
    duplicate: false,
    delete: false,
    agency: "Tfr",
  },
  {
    region: "France",
    agencyLabel: "Indications JO",
    create: false,
    update: false,
    duplicate: false,
    delete: false,
    agency: "IndicationsJO",
  },
  {
    region: "Europe",
    agencyLabel: "EMA (FR)",
    create: false,
    update: false,
    duplicate: false,
    delete: false,
    agency: "Ema",
  },
  {
    region: "Europe",
    agencyLabel: "NICE (UK)",
    create: false,
    update: false,
    duplicate: false,
    delete: false,
    agency: "Nice",
  },
  {
    region: "Europe",
    agencyLabel: "SMC (UK)",
    create: false,
    update: false,
    duplicate: false,
    delete: false,
    agency: "Smc",
  },
  {
    region: "Europe",
    agencyLabel: "CDF (UK)",
    create: false,
    update: false,
    duplicate: false,
    delete: false,
    agency: "Cdf",
  },
  {
    region: "Europe",
    agencyLabel: "TLV (SE)",
    create: false,
    update: false,
    duplicate: false,
    delete: false,
    agency: "Tlv",
  },
  {
    region: "Europe",
    agencyLabel: "NT Council",
    create: false,
    update: false,
    duplicate: false,
    delete: false,
    agency: "Ntcouncil",
  },
  {
    region: "Europe",
    agencyLabel: "Zinl (NL)",
    create: false,
    update: false,
    duplicate: false,
    delete: false,
    agency: "Zinl",
  },
  {
    region: "Europe",
    agencyLabel: "Spain",
    create: false,
    update: false,
    duplicate: false,
    delete: false,
    agency: "Spain",
  },
  {
    region: "Europe",
    agencyLabel: "ITALY",
    create: false,
    update: false,
    duplicate: false,
    delete: false,
    agency: "Italy",
  },
  {
    region: "Europe",
    agencyLabel: "NCPE (IE)",
    create: false,
    update: false,
    duplicate: false,
    delete: false,
    agency: "Ncpe",
  },
  {
    region: "Europe",
    agencyLabel: "AWMSG",
    create: false,
    update: false,
    duplicate: false,
    delete: false,
    agency: "Awmsg",
  },
  {
    region: "Europe",
    agencyLabel: "INAMI (BE)",
    create: false,
    update: false,
    duplicate: false,
    delete: false,
    agency: "Inami",
  },
  {
    region: "Europe",
    agencyLabel: "AOTMIT – POLAND",
    create: false,
    update: false,
    duplicate: false,
    delete: false,
    agency: "Aotmit",
  },
  {
    region: "Europe",
    agencyLabel: "IQWIG (DE)",
    create: false,
    update: false,
    duplicate: false,
    delete: false,
    agency: "Iqwig",
  },
  {
    region: "Europe",
    agencyLabel: "GBA (DE)",
    create: false,
    update: false,
    duplicate: false,
    delete: false,
    agency: "Gba",
  },
  {
    region: "APAC",
    agencyLabel: "PBAC (AU)",
    create: false,
    update: false,
    duplicate: false,
    delete: false,
    agency: "Pbac",
  },
  {
    region: "APAC",
    agencyLabel: "ACE (SG)",
    create: false,
    update: false,
    duplicate: false,
    delete: false,
    agency: "Singapore",
  },
  {
    region: "APAC",
    agencyLabel: "HIRA",
    create: false,
    update: false,
    duplicate: false,
    delete: false,
    agency: "Hira",
  },
  {
    region: "APAC",
    agencyLabel: "NIHTA",
    create: false,
    update: false,
    duplicate: false,
    delete: false,
    agency: "Nihta",
  },
  {
    region: "APAC",
    agencyLabel: "PMDA",
    create: false,
    update: false,
    duplicate: false,
    delete: false,
    agency: "Pmda",
  },
  {
    region: "APAC",
    agencyLabel: "NMPA (CN)",
    create: false,
    update: false,
    duplicate: false,
    delete: false,
    agency: "Nmpa",
  },
  {
    region: "APAC",
    agencyLabel: "NHSA",
    create: false,
    update: false,
    duplicate: false,
    delete: false,
    agency: "Nhsa",
  },
  {
    region: "America",
    agencyLabel: "FDA (USA)",
    create: false,
    update: false,
    duplicate: false,
    delete: false,
    agency: "Fda",
  },
  {
    region: "America",
    agencyLabel: "US (ICER)",
    create: false,
    update: false,
    duplicate: false,
    delete: false,
    agency: "US (ICER)",
  },
  {
    region: "America",
    agencyLabel: "CADTH (CA)",
    create: false,
    update: false,
    duplicate: false,
    delete: false,
    agency: "Cadth",
  },
  {
    region: "America",
    agencyLabel: "pCODR (CA)",
    create: false,
    update: false,
    duplicate: false,
    delete: false,
    agency: "Perc",
  },
  {
    region: "America",
    agencyLabel: "PERU",
    create: false,
    update: false,
    duplicate: false,
    delete: false,
    agency: "Peru",
  },
];

export const hash = {
  Nom: "lastName",
  Prénom: "firstName",
  Email: "email",
  Occupation: "occupation",
  Société: "society",
  Pays: "country",
  Rôle: "role",
  "Adresse IP": "ipAddress",
  Langue: "language",
  "Jours restants": "days",
  Statut: "status",
  Analyste: "analyst",
  Admin: "admin",
};

export const countriesList = [
  "European Union",
  "Aruba",
  "Afghanistan",
  "Angola",
  "Anguilla",
  "Åland Islands",
  "Albania",
  "Andorra",
  "United Arab Emirates",
  "Argentina",
  "Armenia",
  "American Samoa",
  "Antarctica",
  "French Southern Territories",
  "Antigua and Barbuda",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Burundi",
  "Belgium",
  "Benin",
  "Bonaire, Sint Eustatius and Saba",
  "Burkina Faso",
  "Bangladesh",
  "Bulgaria",
  "Bahrain",
  "Bahamas",
  "Bosnia and Herzegovina",
  "Saint Barthélemy",
  "Belarus",
  "Belize",
  "Bermuda",
  "Bolivia, Plurinational State of",
  "Brazil",
  "Barbados",
  "Brunei Darussalam",
  "Bhutan",
  "Bouvet Island",
  "Botswana",
  "Central African Republic",
  "Canada",
  "Cocos (Keeling) Islands",
  "Switzerland",
  "Chile",
  "China",
  "Côte d'Ivoire",
  "Cameroon",
  "Congo, The Democratic Republic of the",
  "Congo",
  "Cook Islands",
  "Colombia",
  "Comoros",
  "Cabo Verde",
  "Costa Rica",
  "Cuba",
  "Curaçao",
  "Christmas Island",
  "Cayman Islands",
  "Cyprus",
  "Czechia",
  "Germany",
  "Djibouti",
  "Dominica",
  "Denmark",
  "Dominican Republic",
  "Algeria",
  "Ecuador",
  "Egypt",
  "Eritrea",
  "Western Sahara",
  "Spain",
  "Estonia",
  "Ethiopia",
  "Finland",
  "Fiji",
  "Falkland Islands (Malvinas)",
  "France",
  "Faroe Islands",
  "Micronesia, Federated States of",
  "Gabon",
  "United Kingdom",
  "Georgia",
  "Guernsey",
  "Ghana",
  "Gibraltar",
  "Guinea",
  "Guadeloupe",
  "Gambia",
  "Guinea-Bissau",
  "Equatorial Guinea",
  "Greece",
  "Grenada",
  "Greenland",
  "Guatemala",
  "French Guiana",
  "Guam",
  "Guyana",
  "Hong Kong",
  "Heard Island and McDonald Islands",
  "Honduras",
  "Croatia",
  "Haiti",
  "Hungary",
  "Indonesia",
  "Isle of Man",
  "India",
  "British Indian Ocean Territory",
  "Ireland",
  "Iran, Islamic Republic of",
  "Iraq",
  "Iceland",
  "Israel",
  "Italy",
  "Jamaica",
  "Jersey",
  "Jordan",
  "Japan",
  "Kazakhstan",
  "Kenya",
  "Kyrgyzstan",
  "Cambodia",
  "Kiribati",
  "Saint Kitts and Nevis",
  "Korea, Republic of",
  "Kuwait",
  "Lao People's Democratic Republic",
  "Lebanon",
  "Liberia",
  "Libya",
  "Saint Lucia",
  "Liechtenstein",
  "Sri Lanka",
  "Lesotho",
  "Lithuania",
  "Luxembourg",
  "Latvia",
  "Macao",
  "Saint Martin (French part)",
  "Morocco",
  "Monaco",
  "Moldova, Republic of",
  "Madagascar",
  "Maldives",
  "Mexico",
  "Marshall Islands",
  "North Macedonia",
  "Mali",
  "Malta",
  "Myanmar",
  "Montenegro",
  "Mongolia",
  "Northern Mariana Islands",
  "Mozambique",
  "Mauritania",
  "Montserrat",
  "Martinique",
  "Mauritius",
  "Malawi",
  "Malaysia",
  "Mayotte",
  "Namibia",
  "New Caledonia",
  "Niger",
  "Norfolk Island",
  "Nigeria",
  "Nicaragua",
  "Niue",
  "Netherlands",
  "Norway",
  "Nepal",
  "Nauru",
  "New Zealand",
  "Oman",
  "Pakistan",
  "Panama",
  "Pitcairn",
  "Peru",
  "Philippines",
  "Palau",
  "Papua New Guinea",
  "Poland",
  "Puerto Rico",
  "Korea, Democratic People's Republic of",
  "Portugal",
  "Paraguay",
  "Palestine, State of",
  "French Polynesia",
  "Qatar",
  "Réunion",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saudi Arabia",
  "Sudan",
  "Senegal",
  "Singapore",
  "South Georgia and the South Sandwich Islands",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Svalbard and Jan Mayen",
  "Solomon Islands",
  "Sierra Leone",
  "El Salvador",
  "San Marino",
  "Somalia",
  "Saint Pierre and Miquelon",
  "Serbia",
  "South Sudan",
  "Sao Tome and Principe",
  "Suriname",
  "Slovakia",
  "Slovenia",
  "Sweden",
  "Eswatini",
  "Sint Maarten (Dutch part)",
  "Seychelles",
  "Syrian Arab Republic",
  "Turks and Caicos Islands",
  "Chad",
  "Togo",
  "Thailand",
  "Tajikistan",
  "Tokelau",
  "Turkmenistan",
  "Timor-Leste",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Tuvalu",
  "Taiwan, Province of China",
  "Tanzania, United Republic of",
  "Uganda",
  "Ukraine",
  "United States Minor Outlying Islands",
  "Uruguay",
  "United States",
  "Uzbekistan",
  "Holy See (Vatican City State)",
  "Saint Vincent and the Grenadines",
  "Venezuela, Bolivarian Republic of",
  "Virgin Islands, British",
  "Virgin Islands, U.S.",
  "Viet Nam",
  "Vanuatu",
  "Wallis and Futuna",
  "Samoa",
  "Yemen",
  "South Africa",
  "Zambia",
  "Zimbabwe",
];

// Transparency lists

export const tauxRemboursementList = [
  { label: 15, color: "#4397ac", value: "15" },
  { label: 30, color: "#4397ac", value: "30" },
  { label: 35, color: "#4397ac", value: "35" },
  { label: 60, color: "#4397ac", value: "60" },
  { label: 65, color: "#4397ac", value: "65" },
  { label: 100, color: "#4397ac", value: "100" },
];

export const remboursementMotif = [
  { label: "SS", color: "#4397ac", value: "SS" },
  { label: "SSCOL", color: "#4397ac", value: "SSCOL" },
  { label: "COL", color: "#4397ac", value: "COL" },
];

export const typeDemande = [
  { label: "INS", color: "#4397ac", value: "INS" },
  { label: "REINS", color: "#4397ac", value: "REINS" },
  { label: "EIT", color: "#4397ac", value: "EIT" },
  { label: "REEVAL", color: "#4397ac", value: "REEVAL" },
  { label: "REEVAL/REINS", color: "#4397ac", value: "REEVAL/REINS" },
  { label: "RADIATION", color: "#4397ac", value: "RADIATION" },
  {
    label: "Modification indication / RCP",
    color: "#4397ac",
    value: "Modification indication / RCP",
  },
  { label: "AUTRE", color: "#4397ac", value: "AUTRE" },
  { label: "NEWDATA", color: "#4397ac", value: "NEWDATA" },
  { label: "RTU", color: "#4397ac", value: "RTU" },
  {
    label: "Prise en charge temporaire",
    color: "#4397ac",
    value: "Prise en charge temporaire",
  },
  {
    label: "Remboursement non demandé",
    color: "#4397ac",
    value: "Remboursement non demandé",
  },
  { label: "Accès précoce", color: "#4397ac", value: "Accès précoce" },
];

export const raisonReevaluation = [
  {
    label: "Demande des autorités",
    color: "#4397ac",
    value: "Demande des autorités",
  },
  {
    label: "Demande de la firme",
    color: "#4397ac",
    value: "Demande de la firme",
  },
];

export const typeProcedure = [
  {
    label: "PIC",
    color: "#4397ac",
    value: "PIC",
  },
  {
    label: "PIS",
    color: "#4397ac",
    value: "PIS",
  },
  {
    label: "QD",
    color: "#4397ac",
    value: "QD",
  },
  {
    label: "Non renseigné",
    color: "#4397ac",
    value: "Non renseigné",
  },
];
export const classificationAPAC = [
  {
    label: "Nouvelle association",
    color: "#4397ac",
    value: { nameFr: "Nouvelle association", nameEn: "nameEn" },
  },
  {
    label: "Biosimilaire",
    color: "#4397ac",
    value: { nameFr: "Biosimilaire", nameEn: "nameEn" },
  },
  {
    label: "Générique",
    color: "#4397ac",
    value: { nameFr: "Générique", nameEn: "nameEn" },
  },
  {
    label: "Hybride",
    color: "#4397ac",
    value: { nameFr: "Hybride", nameEn: "nameEn" },
  },
  {
    label: "Complément de gamme",
    color: "#4397ac",
    value: { nameFr: "Complément de gamme", nameEn: "nameEn" },
  },
  {
    label: "Nouveau produit",
    color: "#4397ac",
    value: { nameFr: "Nouveau produit", nameEn: "nameEn" },
  },
  {
    label: "Nouvelle indication",
    color: "#4397ac",
    value: { nameFr: "Nouvelle indication", nameEn: "nameEn" },
  },
  {
    label: "Modification indication / RCP",
    color: "#4397ac",
    value: { nameFr: "Modification indication / RCP", nameEn: "nameEn" },
  },
  {
    label: "Nouvelle entité",
    color: "#4397ac",
    value: { nameFr: "Nouvelle entité", nameEn: "nameEn" },
  },
  {
    label: "Continuité de prise en charge",
    color: "#4397ac",
    value: { nameFr: "Continuité de prise en charge", nameEn: "nameEn" },
  },
  {
    label: "Reappraisal",
    color: "#4397ac",
    value: { nameFr: "Réévaluation", nameEn: "nameEn" },
  },
  {
    label: "Autre",
    color: "#4397ac",
    value: { nameFr: "Autre", nameEn: "nameEn" },
  },
];

export const marketingAutorisationEma = [
  {
    label: "Advanced therapy medicinal products (ATMPs)",
    name: "checkAtmps",
  },
  {
    label: "Biosimilar",
    name: "checkBiosimilar",
  },
  {
    label: "Conditional MA",
    name: "checkConditionalMa",
  },
  {
    label: "MA under exceptional circumstances",
    name: "checkMaUnderExcept",
  },
  {
    label: "Generic",
    name: "checkGeneric",
  },
  {
    label: "Switched to non-conditional",
    name: "checkSwitchedNonConditional",
  },
  {
    label: "Hybrid",
    name: "checkHybrid",
  },
  {
    label: "Similar",
    name: "checkSimilar",
  },
];
export const emaProcess = [
  {
    label: "Priority review drug",
    name: "priorityReviewDrug",
  },
  {
    label: "Standard drug review",
    name: "standardDrugReview",
  },
  {
    label: " Accelerated approval",
    name: "acceleratedApproval",
  },
  {
    label: "Breakthrough therapy",
    name: "breakthroughTherapy",
  },
  {
    label: " Fast track",
    name: "fastTrack",
  },
  {
    label: "Rare Pediatric Disease",
    name: "rarePediatricDisease",
  },
  {
    label: "Rolling review",
    name: "rollingReview",
  },
  {
    label: " Regenerative Medicine Advanced Therapy Designation",
    name: "regenerativeMedicineAdvancedTherapyDesignation",
  },
];

export const emaProcessEMA = [
  {
    label: "Accelerated Assessment",
    name: "checkAcceleratedAssessment",
  },
  {
    label: "Prime",
    name: "checkPrime",
  },
];

export const classificationListCheckBoxEma = [
  {
    name: "firstInClass",
    label: "First in class",
  },
  {
    name: "newMolecularEntity",
    label: "New molecular entity",
  },
  {
    name: "newProduct",
    label: "New product",
  },
  {
    name: "newIndication",
    label: "New indication",
  },

  {
    name: "newCombination",
    label: "New combination",
  },
  {
    name: "additionalRange",
    label: "Additional range",
  },
  {
    name: "changeToAnExistingIndication",
    label: "Change to an existing indication",
  },
  {
    name: "other",
    label: "Other",
  },
];
export const typeAMM = [
  { label: "Biosimilaire", color: "#4397ac", value: "Biosimilaire" },
  {
    label: "AMM conditionnelle",
    color: "#4397ac",
    value: "AMM conditionnelle",
  },
  {
    label: "AMM sous circonstances exceptionnelles",
    color: "#4397ac",
    value: "AMM sous circonstances exceptionnelles",
  },
  {
    label: "Générique",
    color: "#4397ac",
    value: "Générique",
  },
  {
    label: "Hybride",
    color: "#4397ac",
    value: "Hybride",
  },
  {
    label: "Similaire",
    color: "#4397ac",
    value: "Similaire",
  },
];

export const ISPLibelle = [
  { label: "", color: "#4397ac", value: "" },
  { label: "Absence", color: "#4397ac", value: "absence" },
  { label: "Insuffisant", color: "#4397ac", value: "insuffisant" },
  { label: "Faible", color: "#4397ac", value: "faible" },
  { label: "Modéré", color: "#4397ac", value: "modere" },
  { label: "Important", color: "#4397ac", value: "important" },
  { label: "Impact négatif", color: "#4397ac", value: "Impact negatif" },
  {
    label: "Susceptible d’avoir un impact",
    color: "#4397ac",
    value: "Susceptible d’avoir un impact",
  },
  { label: "Oui", color: "#4397ac", value: "Oui" },
];

export const ASMRObtenue = [
  { label: "", color: "#4397ac", value: "0" },
  { label: "I", color: "#4397ac", value: "I" },
  { label: "II", color: "#4397ac", value: "II" },
  { label: "III", color: "#4397ac", value: "III" },
  { label: "IV", color: "#4397ac", value: "IV" },
  { label: "V,utile", color: "#4397ac", value: "V,utile" },
  { label: "V", color: "#4397ac", value: "V" },
  { label: "Sans objet", color: "#4397ac", value: "Sans objet" },
  { label: "non évaluable", color: "#4397ac", value: "non évaluable" },
  { label: "Pas de changement", color: "#4397ac", value: "Pas de changement" },
];

export const ASMRDemandee = [
  { label: "", color: "#4397ac", value: "0" },
  { label: "I", color: "#4397ac", value: "I" },
  { label: "II", color: "#4397ac", value: "II" },
  { label: "III", color: "#4397ac", value: "III" },
  { label: "IV", color: "#4397ac", value: "IV" },
  { label: "V", color: "#4397ac", value: "V" },
  { label: "Multiple", color: "#4397ac", value: "Multiple" },
];

export const ASMRComparateurs = [
  { label: "", color: "#4397ac", value: "0" },
  {
    label: "En stratégie thérapeutique",
    color: "#4397ac",
    value: "TRS_asmr_1",
  },
  { label: "Versus comparateur", color: "#4397ac", value: "TRS_asmr_2" },
  { label: "Partage d’ASMR", color: "#4397ac", value: "TRS_asmr_3" },
];

export const HeaderItemsPricnigGermany = [
  { label: "ID", up: false, down: false, sorted: false, checked: true },
  { label: "Drug name", up: false, down: false, sorted: false, checked: true },
  {
    label: "Active ingredient",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "ATC", up: false, down: false, sorted: false, checked: true },
  { label: "Packaging", up: false, down: false, sorted: false, checked: true },
  {
    label: "Dosage strength",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "Créé", up: false, down: false, sorted: false, checked: true },
  { label: "Modifiés", up: false, down: false, sorted: false, checked: true },
  { label: "On line", up: false, down: false, sorted: false, checked: false },
];

export const HeaderItemsPricnigSpain = [
  { label: "ID", up: false, down: false, sorted: false, checked: true },
  {
    label: "Registration number",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "National code",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "Drug Name", up: false, down: false, sorted: false, checked: true },
  {
    label: "Drug name Prioritis",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "ATC code", up: false, down: false, sorted: false, checked: true },
  { label: "Créé", up: false, down: false, sorted: false, checked: true },
  { label: "Modifiés", up: false, down: false, sorted: false, checked: true },
  { label: "On line", up: false, down: false, sorted: false, checked: false },
];

export const HeaderItemsPricnigJapan = [
  { label: "ID", up: false, down: false, sorted: false, checked: true },
  { label: "Drug code", up: false, down: false, sorted: false, checked: true },
  { label: "Drug name", up: false, down: false, sorted: false, checked: true },
  {
    label: "Active ingredient",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "Applicant", up: false, down: false, sorted: false, checked: true },
  { label: "Créé", up: false, down: false, sorted: false, checked: true },
  { label: "Modifiés", up: false, down: false, sorted: false, checked: true },
  { label: "On line", up: false, down: false, sorted: false, checked: false },
];
export const HeaderItemsHta = [
  { label: "Nom", up: false, down: false, sorted: false, checked: true },
  { label: "Pays", up: false, down: false, sorted: false, checked: true },
  { label: "Créé", up: false, down: false, sorted: false, checked: true },
  { label: "Modifiés", up: false, down: false, sorted: false, checked: true },
  { label: "On line", up: false, down: false, sorted: false, checked: false },
];

export const HeaderItemsIQWIG = [
  { label: "ID", up: false, down: false, sorted: false, checked: true },
  { label: "Indexation", up: false, down: false, sorted: false, checked: true },

  {
    label: "Indexation_en",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_de",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "Firm", up: false, down: false, sorted: false, checked: true },
  { label: "Name", up: false, down: false, sorted: false, checked: true },
  { label: "ATC", up: false, down: false, sorted: false, checked: true },
  { label: "PDF", up: false, down: false, sorted: false, checked: true },
  {
    label: "Project completed",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "Commission", up: false, down: false, sorted: false, checked: true },

  { label: "Créé", up: false, down: false, sorted: false, checked: true },
  { label: "Modifiés", up: false, down: false, sorted: false, checked: true },
  { label: "On line", up: false, down: false, sorted: false, checked: false },
];

export const HeaderItemsPricnigKorea = [
  { label: "ID", up: false, down: false, sorted: false, checked: true },
  { label: "Drug code", up: false, down: false, sorted: false, checked: true },
  { label: "Drug name", up: false, down: false, sorted: false, checked: true },
  {
    label: "Active ingredient",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "Applicant", up: false, down: false, sorted: false, checked: true },
  { label: "Créé", up: false, down: false, sorted: false, checked: true },
  { label: "Modifiés", up: false, down: false, sorted: false, checked: true },
  { label: "On line", up: false, down: false, sorted: false, checked: false },
];
export const HeaderItemsPricnigBresil = [
  { label: "ID", up: false, down: false, sorted: false, checked: true },
  { label: "Name", up: false, down: false, sorted: false, checked: true },
  { label: "DCI", up: false, down: false, sorted: false, checked: true },
  {
    label: "Presentation",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Drug number",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "Créé", up: false, down: false, sorted: false, checked: true },
  { label: "Modifiés", up: false, down: false, sorted: false, checked: true },
  { label: "On line", up: false, down: false, sorted: false, checked: false },
];

export const HeaderItemsPricnigBresilNEW = [
  { label: "ID", up: false, down: false, sorted: false, checked: true },
  { label: "Name", up: false, down: false, sorted: false, checked: true },
  { label: "DCI", up: false, down: false, sorted: false, checked: true },
  {
    label: "Presentation",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Drug number",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "Créé", up: false, down: false, sorted: false, checked: true },
  { label: "Modifiés", up: false, down: false, sorted: false, checked: true },
  { label: "On line", up: false, down: false, sorted: false, checked: false },
  { label: "TEST", up: false, down: false, sorted: false, checked: true },
];

export const HeaderItemsPricnigSweden = [
  { label: "ID", up: false, down: false, sorted: false, checked: true },
  { label: "Drug name", up: false, down: false, sorted: false, checked: true },
  { label: "dci", up: false, down: false, sorted: false, checked: true },
  { label: "form", up: false, down: false, sorted: false, checked: true },
  {
    label: "dosage_strength",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "Packaging", up: false, down: false, sorted: false, checked: true },
  { label: "Unit", up: false, down: false, sorted: false, checked: true },
  { label: "atc", up: false, down: false, sorted: false, checked: true },
  { label: "Créé", up: false, down: false, sorted: false, checked: true },
  { label: "Modifiés", up: false, down: false, sorted: false, checked: true },
  { label: "On line", up: false, down: false, sorted: false, checked: false },
];

export const HeaderItemsPricnigItaly = [
  { label: "ID", up: false, down: false, sorted: false, checked: true },
  { label: "Drug name", up: false, down: false, sorted: false, checked: true },
  {
    label: "Confezione / Packaging Italy",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "INN", up: false, down: false, sorted: false, checked: true },
  { label: "AIC", up: false, down: false, sorted: false, checked: true },
  { label: "Créé", up: false, down: false, sorted: false, checked: true },
  { label: "Modifiés", up: false, down: false, sorted: false, checked: true },
  { label: "On line", up: false, down: false, sorted: false, checked: false },
];

export const HeaderItemsPricnigAustralie = [
  { label: "ID", up: false, down: false, sorted: false, checked: true },
  { label: "Code", up: false, down: false, sorted: false, checked: true },
  {
    label: "INN / Active ingredient",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "Schedule", up: false, down: false, sorted: false, checked: true },
  {
    label: "Body System",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "Drug name", up: false, down: false, sorted: false, checked: true },
  { label: "Créé", up: false, down: false, sorted: false, checked: true },
  { label: "Modifiés", up: false, down: false, sorted: false, checked: true },
  { label: "On line", up: false, down: false, sorted: false, checked: false },
];

export const HeaderItemsPricnigINAMI = [
  { label: "ID", up: false, down: false, sorted: false, checked: true },
  { label: "Drug Name", up: false, down: false, sorted: false, checked: true },
  { label: "DCI", up: false, down: false, sorted: false, checked: true },
  { label: "Code INAMI", up: false, down: false, sorted: false, checked: true },
  { label: "Créé", up: false, down: false, sorted: false, checked: true },
  { label: "Modifiés", up: false, down: false, sorted: false, checked: true },
  { label: "On line", up: false, down: false, sorted: false, checked: false },
];

export const HeaderItemsGBA = [
  { label: "ID", up: false, down: false, sorted: false, checked: true },
  { label: "Indexation", up: false, down: false, sorted: false, checked: true },
  {
    label: "Indexation_en",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_de",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Firm",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "Name", up: false, down: false, sorted: false, checked: true },
  { label: "ATC", up: false, down: false, sorted: false, checked: true },
  { label: "PDF", up: false, down: false, sorted: false, checked: true },
  { label: "Status", up: false, down: false, sorted: false, checked: true },
  { label: "Créé", up: false, down: false, sorted: false, checked: true },
  { label: "Modifiés", up: false, down: false, sorted: false, checked: true },
  { label: "On line", up: false, down: false, sorted: false, checked: true },
];
export const HeaderItemsPCODR = [
  { label: "ID", up: false, down: false, sorted: false, checked: true },
  {
    label: "Indexation",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_en",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_de",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Firm",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "Name", up: false, down: false, sorted: false, checked: true },
  { label: "ATC", up: false, down: false, sorted: false, checked: true },
  {
    label: "Project number",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "DCI", up: false, down: false, sorted: false, checked: true },
  { label: "Créé", up: false, down: false, sorted: false, checked: true },
  { label: "Modifiés", up: false, down: false, sorted: false, checked: true },
  { label: "On line", up: false, down: false, sorted: false, checked: false },
];

export const HeaderItemsNHSA = [
  { label: "ID", up: false, down: false, sorted: false, checked: true },
  {
    label: "Indexation",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_en",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_de",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Firm",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "DCI", up: false, down: false, sorted: false, checked: true },
  { label: "Spécialité", up: false, down: false, sorted: false, checked: true },
  { label: "Créé", up: false, down: false, sorted: false, checked: true },
  { label: "Modifiés", up: false, down: false, sorted: false, checked: true },
  { label: "On line", up: false, down: false, sorted: false, checked: true },
];

export const ClinicalTrialsHeaderItems = [
  { label: "ID", up: false, down: false, sorted: false, checked: true },
  {
    label: "Indexation_fr",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_en",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_de",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "Catégorie", up: false, down: false, sorted: false, checked: true },
  {
    label: "Ingrédiant actif",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Trial name",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Type",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Comparaison",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "Créé", up: false, down: false, sorted: false, checked: true },
  { label: "Modifiés", up: false, down: false, sorted: false, checked: true },
];

export const ICERHeaderItems = [
  { label: "ID", up: false, down: false, sorted: false, checked: true },
  {
    label: "Laboratoire",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Name",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "ATC",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "DCI New", up: false, down: false, sorted: false, checked: true },
  {
    label: "Agency",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "Créé", up: false, down: false, sorted: false, checked: true },
  { label: "Modifiés", up: false, down: false, sorted: false, checked: true },
  { label: "On line", up: false, down: false, sorted: false, checked: true },
];

export const BIOSIMILAIRESHeaderItems = [
  {
    label: "ID",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "ID ANSM Biosimilaire",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Médicament de référence",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Substance active",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Date de création du groupe",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Date de fin du groupe",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Lien création ANSM",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "Crée", up: false, down: false, sorted: false, checked: true },
  {
    label: "Modifiée",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "On line",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
];

export const TFRHeaderItems = [
  {
    label: "ID",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "ID TFR",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "ID ANSM",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Forme galénique",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Unité",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "groupe générique ANSM",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "groupe générique DCI ANSM",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "Créé", up: false, down: false, sorted: false, checked: true },
  {
    label: "Modifiés",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "On line",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
];

export const headerItemsProbabilityAB = [
  {
    label: "ID",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },

  {
    label: "Title",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Text",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },

  {
    label: "Added Benefit",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
];
export const smrIdHeaderItems = [
  {
    label: "ID",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },

  {
    label: "Type",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Titre",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },

  {
    label: "SMR_TRS_code_prioritis",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
];

export const patientIdHeaderItems = [
  {
    label: "ID",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },

  {
    label: "Title",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Agency model",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },

  {
    label: "Drug ID",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
];

export const transparencyHeaderItems = [
  { label: "ID", up: false, down: false, sorted: false, checked: true },
  {
    label: "Indexation_fr",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_en",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_de",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Laboratoire",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "Drug name", up: false, down: false, sorted: false, checked: true },
  { label: "ATC", up: false, down: false, sorted: false, checked: true },
  { label: "DCI", up: false, down: false, sorted: false, checked: true },
  { label: "Catégorie", up: false, down: false, sorted: false, checked: false },
  {
    label: "MK orphelin",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "Applicant", up: false, down: false, sorted: false, checked: false },
  {
    label: "HTA decision",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Reimbursement",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "Créé", up: false, down: false, sorted: false, checked: true },
  { label: "Modifiés", up: false, down: false, sorted: false, checked: true },
  { label: "On line", up: false, down: false, sorted: false, checked: true },
];

export const indicationJoHeaderItems = [
  {
    label: "Email Alert",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "ID", up: false, down: false, sorted: false, checked: true },
  {
    label: "Catégorie",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Spécialité(Trs)",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "DCI(Trs)",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "ATC(Trs)",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Aire thérapeutique(Trs)",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indication",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "SMR ID",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Créé",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Modifiés",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "On line",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
];

export const boitePresentationHeaderItems = [
  {
    label: "ID",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Email Alert",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Lien E.",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "UCD",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "CIS",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "CIP13",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "ATC Pro",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Présentation / Conditionnement",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "Créé", up: false, down: false, sorted: false, checked: true },
  {
    label: "Modifiés",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "On line",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
];

export const hTAdecisionTransparency = [
  { label: "Yes", color: "green", value: "Yes" },
  { label: "Ongoing", color: "orange", value: "Ongoing" },
  { label: "N/A", color: "red", value: "N/A" },
  { label: "ATU", color: "#4397ac", value: "ATU" },
  { label: "Non-submission", color: "#4397ac", value: "Non-submission" },
  { label: "Suspended", color: "blue", value: "Suspended" },
  { label: "Deferred", color: "#4397ac", value: "Deferred" },
  { label: "Positive", color: "#4397ac", value: "Positive" },
  { label: "Partial", color: "#4397ac", value: "Partial" },
  { label: "Negative", color: "red", value: "Negative" },
];
export const hTAdecisionACE = [
  { label: "Yes", value: "Yes", color: "#4397ac" },
  { label: "No", value: "No", color: "#4397ac" },
  { label: "Ongoing", value: "Ongoing", color: "#4397ac" },

  { label: "N/A", value: "N/A", color: "#4397ac" },
  { label: "Positive", color: "green", value: "Yes_Green" },
  { label: "Partial", color: "orange", value: "Yes_Orange" },
  { label: "Negative", color: "red", value: "Yes_Red" },
  { label: "Non-submission", color: "#4397ac", value: "Non-submission" },
  { label: "Suspended", color: "#4397ac", value: "Suspended" },
  { label: "Deferred", color: "#4397ac", value: "Deferred" },
];

export const reimbursementListACE = [
  { id: 1, value: "Yes", label: "Yes", color: "green" },
  { id: 2, value: "No", label: "No", color: "red" },
  { id: 3, value: "Partial", label: "Partial", color: "orange" },
  { id: 4, value: "Ongoing", label: "Ongoing", color: "#4397ac" },
  { id: 5, value: "N/A", label: "N/A", color: "#4397ac" },
  { id: 6, value: "Suspended", label: "Suspended", color: "black" },
  { id: 7, value: "Positive", label: "Positive", color: "green" },
  { id: 8, value: "Negative", label: "Negative", color: "red" },
];

export const zinlHeaderItems = [
  { label: "ID", up: false, down: false, sorted: false, checked: true },
  {
    label: "Indexation_fr",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_en",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_de",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "Drug name", up: false, down: false, sorted: false, checked: true },
  { label: "Inn", up: false, down: false, sorted: false, checked: true },
  { label: "ATC", up: false, down: false, sorted: false, checked: true },
  { label: "Créé", up: false, down: false, sorted: false, checked: true },
  { label: "Modifiés", up: false, down: false, sorted: false, checked: true },
  { label: "On line", up: false, down: false, sorted: false, checked: true },
];

export const rtuHeaderItems = [
  { label: "ID", up: false, down: false, sorted: false, checked: true },
  {
    label: "Indexation_fr",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_en",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_de",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "Firm", up: false, down: false, sorted: false, checked: true },
  { label: "Drug name", up: false, down: false, sorted: false, checked: true },
  { label: "ATC", up: false, down: false, sorted: false, checked: true },
  { label: "DCI", up: false, down: false, sorted: false, checked: true },
  { label: "Créé", up: false, down: false, sorted: false, checked: true },
  { label: "Modifiés", up: false, down: false, sorted: false, checked: true },
  { label: "On line", up: false, down: false, sorted: false, checked: true },
];

export const ntCouncilHeaderItems = [
  { label: "ID", up: false, down: false, sorted: false, checked: true },
  {
    label: "Indexation_fr",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_en",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_de",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "Firm", up: false, down: false, sorted: false, checked: true },
  { label: "DCI", up: false, down: false, sorted: false, checked: true },
  { label: "Drug name", up: false, down: false, sorted: false, checked: true },
  { label: "Créé", up: false, down: false, sorted: false, checked: true },
  { label: "Modifiés", up: false, down: false, sorted: false, checked: true },
  { label: "On line", up: false, down: false, sorted: false, checked: true },
];

export const spainHeaderItems = [
  { label: "ID", up: false, down: false, sorted: false, checked: true },
  {
    label: "Indexation_fr",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_en",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_de",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Laboratoire",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "ATC", up: false, down: false, sorted: false, checked: true },
  { label: "Status", up: false, down: false, sorted: false, checked: true },
  { label: "DCI", up: false, down: false, sorted: false, checked: true },
  { label: "Drug name", up: false, down: false, sorted: false, checked: true },
  { label: "Agency", up: false, down: false, sorted: false, checked: true },
  { label: "Region", up: false, down: false, sorted: false, checked: true },
  { label: "Créé", up: false, down: false, sorted: false, checked: true },
  { label: "Modifiés", up: false, down: false, sorted: false, checked: true },
  { label: "On line", up: false, down: false, sorted: false, checked: true },
];

export const ncpeHeaderItems = [
  { label: "ID", up: false, down: false, sorted: false, checked: true },
  {
    label: "Indexation_fr",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_en",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_de",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Laboratoire",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "ATC", up: false, down: false, sorted: false, checked: true },
  { label: "Status", up: false, down: false, sorted: false, checked: true },
  { label: "DCI", up: false, down: false, sorted: false, checked: true },
  { label: "Drug name", up: false, down: false, sorted: false, checked: true },
  { label: "Créé", up: false, down: false, sorted: false, checked: true },
  { label: "Modifiés", up: false, down: false, sorted: false, checked: true },
  { label: "On line", up: false, down: false, sorted: false, checked: true },
];

export const nmpaHeaderItems = [
  { label: "ID", up: false, down: false, sorted: false, checked: true },
  {
    label: "Indexation_fr",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_en",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_de",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "Firm", up: false, down: false, sorted: false, checked: true },
  { label: "DCI", up: false, down: false, sorted: false, checked: true },
  { label: "Drug name", up: false, down: false, sorted: false, checked: true },
  { label: "Créé", up: false, down: false, sorted: false, checked: true },
  { label: "Modifiés", up: false, down: false, sorted: false, checked: true },
  { label: "On line", up: false, down: false, sorted: false, checked: true },
];

export const NIHTAHeader = [
  { label: "ID", up: false, down: false, sorted: false, checked: true },
  {
    label: "Indexation",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_en",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_de",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "ATC", up: false, down: false, sorted: false, checked: true },
  { label: "DCI", up: false, down: false, sorted: false, checked: true },
  { label: "Drug name", up: false, down: false, sorted: false, checked: true },

  { label: "Créé", up: false, down: false, sorted: false, checked: true },
  { label: "Modifiés", up: false, down: false, sorted: false, checked: true },
  { label: "On line", up: false, down: false, sorted: false, checked: false },
];

export const NcpeClassificationList = [
  { label: "Additional Range", value: "Complement de gamme" },
  { label: "Biosimilar", value: "Biosimilaire" },
  { label: "Generic", value: "Generic" },
  { label: "Hybrid", value: "Hybride" },
  { label: "New Combination", value: "Nouvelle association" },
  { label: "New indication", value: "Nouvelle indication" },
  { label: "New product", value: "Nouveau produit" },
  {
    label: "Change to an existing indication",
    value: "Modification de l'indication",
  },
  { label: "New molecular entity", value: "Nouvelle entité" },
  { label: "Reappraisal", value: "Reappraisal" },
  { label: "Other", value: "Other" },
];

export const usersTableHead = [
  { id: "ID", label: "ID" },
  { id: "nameComment", label: "Nom" },
  { id: "LastName", label: "Prénom" },
  { id: "Role", label: "Rôle" },
  { id: "Status", label: "Statut" },
  { id: "Company", label: "Société" },
  { id: "Email", label: "Email" },
  { id: "Country", label: "Pays" },
  { id: "days", label: "Jours restants" },
];
export const agencyHeaderItems = [
  { label: "ID", up: false, down: false, sorted: false, checked: true },
  {
    label: "Agency (EN)",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },

  {
    label: "Indexation_fr",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_en",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_de",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "Firm", up: false, down: false, sorted: false, checked: true },
  { label: "DCI", up: false, down: false, sorted: false, checked: true },
  { label: "Drug name", up: false, down: false, sorted: false, checked: true },
  { label: "Créé", up: false, down: false, sorted: false, checked: true },
  { label: "Modifiés", up: false, down: false, sorted: false, checked: true },
  { label: "On line", up: false, down: false, sorted: false, checked: false },
];

export const recommendationListCADTH = [
  { id: 1, label: "Reimburse", value: "Reimburse" },
  { id: 2, label: "Do not reimburse", value: "Do not reimburse" },
  {
    id: 3,
    label: "Reimburse with clinical criteria and/or conditions",
    value: "Reimburse with clinical criteria and/or conditions",
  },
  {
    id: 4,
    label:
      "Unable to recommend reimbursement as a submission was not filed by the manufacturer",
    value:
      "Unable to recommend reimbursement as a submission was not filed by the manufacturer",
  },
];

export const ecoEvalHeaderItems = [
  { label: "ID", up: false, down: false, sorted: false, checked: true },
  {
    label: "Indexation_fr",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_en",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_de",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "Drug name", up: false, down: false, sorted: false, checked: true },
  { label: "ATC", up: false, down: false, sorted: false, checked: true },
  {
    label: "Active ingredient",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "DCI", up: false, down: false, sorted: false, checked: true },
  { label: "Créé", up: false, down: false, sorted: false, checked: true },
  { label: "Modifiés", up: false, down: false, sorted: false, checked: true },
  { label: "On line", up: false, down: false, sorted: false, checked: false },
];

export const nhsaHeaderItems = [
  { label: "ID", up: false, down: false, sorted: false, checked: true },
  {
    label: "Indexation_fr",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_en",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_de",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "Firm", up: false, down: false, sorted: false, checked: true },
  { label: "DCI", up: false, down: false, sorted: false, checked: true },
  { label: "Drug name", up: false, down: false, sorted: false, checked: true },

  { label: "Créé", up: false, down: false, sorted: false, checked: true },
  { label: "Modifiés", up: false, down: false, sorted: false, checked: true },
];

export const niceHeaderItems = [
  { label: "ID", up: false, down: false, sorted: false, checked: true },
  {
    label: "Indexation_fr",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_en",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Indexation_de",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Laboratoire",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  { label: "Drug name", up: false, down: false, sorted: false, checked: true },
  { label: "ATC", up: false, down: false, sorted: false, checked: true },
  { label: "DCI", up: false, down: false, sorted: false, checked: true },
  {
    label: "Type of restriction",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Progress",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Referal date",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Expected date of issue",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },
  {
    label: "Date advice",
    up: false,
    down: false,
    sorted: false,
    checked: true,
  },

  { label: "Créé", up: false, down: false, sorted: false, checked: true },
  { label: "Modifiés", up: false, down: false, sorted: false, checked: true },
  { label: "On line", up: false, down: false, sorted: false, checked: true },
];

export const tasksHeaderItems = [
  { id: "Nom", label: "Nom", checked: true },
  { id: "Agence", label: "Agence", checked: true },
  { id: "Produit", label: "Produit", checked: true },
  { id: "Tâche", label: "Tâche", checked: true },
  { id: "Texte", label: "Texte", checked: true },
  { id: "Date", label: "Date", checked: true },
];

export const diseaseHeaderItems = [
  { id: "ID", label: "ID", checked: true },
  { id: "Nom fr", label: "Nom FR", checked: true },
  { id: "Nom en", label: "Nom EN", checked: true },
  { id: "Nom de", label: "Nom DE ", checked: true },
  { id: "responsable", label: "Crée par", checked: true },
  {
    id: "creation_date",
    label: "Crée le ",
    checked: true,
  },
  {
    id: "modification_date",
    label: "Date de modification",
    checked: true,
  },
];
