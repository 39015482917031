/* eslint import/no-anonymous-default-export: "off" */

import {
  GET_IQWIG_FILE_LIST,
  ADD_FILE_IQWIG,
  EDIT_FILE_IQWIG,
  DELETE_FILE_IQWIG,
} from "../../Const/ActionTypes";

const initialState = {
  listIqwig: [],
  iqwigFileToAdd: {
    id: null,
    communInformation: undefined,
    indicationUnderReview: undefined,
    title: undefined,
    commission: undefined,
    status: undefined,
    commentaire: undefined,
    background: undefined,
    informationRetrievalStudyPool: undefined,
    nctNumbersSection: undefined,
    realWorldDataSection: undefined,
    treatmentLineSection: undefined,
    qualityOfLifeSection: undefined,
    indirectComparaisonSection: undefined,
    rationaleCommentSection: undefined,
    evalutionLinks: undefined,
    keyDocumentsSection: undefined,
    pressReleaseSection: undefined,
    researchQuestionSection: undefined,
    resultsBenefitAssessment: undefined,
    patientPopulationSection: undefined,
    extentAndProbabilityAddedBenifitSection: undefined,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_IQWIG_FILE_LIST: {
      return Object.assign({}, state, {
        listIqwig: action.payload,
      });
    }
    case EDIT_FILE_IQWIG: {
      return Object.assign({}, state, {
        listIqwig: state.listIqwig.map((element) =>
          element.id === action.payload.id ? action.payload : element
        ),
        iqwigFileToAdd: { id: null },
      });
    }
    case ADD_FILE_IQWIG: {
      return Object.assign({}, state, {
        listIqwig: [action.payload].concat(state.listIqwig),
        APACFileToAdd: action.payload,
      });
    }

    case DELETE_FILE_IQWIG: {
      return Object.assign({}, state, {
        listIqwig: [
          ...state.listIqwig.filter((element) => element.id !== action.payload),
        ],
      });
    }
    default:
      return state;
  }
}
