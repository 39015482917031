import {
    ADD_FILE_PRICING_SWEDEN,
    DELETE_FILE_PRICING_SWEDEN,
    EDIT_FILE_PRICING_SWEDEN,
    GET_PRICING_SWEDEN_FILE_LIST,
  } from "../../Const/ActionTypes";
  
  const initialState = {
    listPricingSweden: [],
    pricingSwedenFileToAdd: {
      id: null,
     
    },
  };
  
  export default function pricingSweden(state = initialState, action) {
    switch (action.type) {
      case GET_PRICING_SWEDEN_FILE_LIST: {
        return Object.assign({}, state, {
          listPricingSweden: action.payload,
        });
      }
      case EDIT_FILE_PRICING_SWEDEN: {
        return Object.assign({}, state, {
          listPricingSweden: state.listPricingSweden.map((element) =>
            element.id === action.payload.id ? action.payload : element
          ),
  
          pricingSwedenFileToAdd: { id: null },
        });
      }
      case ADD_FILE_PRICING_SWEDEN: {
        return Object.assign({}, state, {
          listPricingSweden: [action.payload].concat(state.listPricingSweden),
          pricingSwedenFileToAdd: action.payload,
        });
      }
  
      case DELETE_FILE_PRICING_SWEDEN: {
        return Object.assign({}, state, {
          listPricingSweden: [
            ...state.listPricingSweden.filter(
              (element) => element.id !== action.payload
            ),
          ],
        });
      }
      default:
        return state;
    }
  }
  