/* eslint import/no-anonymous-default-export: "off" */

import {
  GET_PBAC_FILE_LIST,
  ADD_FILE_PBAC,
  EDIT_FILE_PBAC,
  DELETE_FILE_PBAC,
} from '../../Const/ActionTypes';

const initialState = {
  listPbac: [],
  pbacFileToAdd: {
    id: null,
    communInformation: undefined,
    pbacOutcomeSection: undefined,
    pbacClinicalOutcomeSection: undefined,
    pbacEconomicOutcomeSection: undefined,
    purposeOfApplicationSection: undefined,
    requestedListingSection: undefined,
    backgroundSection: undefined,
    registrationStatusSection: undefined,
    listingRequestedAndViewSection: undefined,
    summaryOfResubmissionSection: undefined,
    clinicalPlaceForProposedTherapySection: undefined,
    additionalInformationComparatorsSection: undefined,
    pbacConsiderationOfTheEvidenceSection: undefined,
    pbacKeyConclusionSection: undefined,
    additionalInformationBudgetImpactSection: undefined,
    detailsOfTheHealthEconomicStudySection: undefined,
    comparatorSection: undefined,
    populationSection: undefined,
    //sensitivityAnalysisSection: undefined,
    nctNumbersSection: undefined,
    realWorldDataSection: undefined,
    qualityOfLifeSection: undefined,
    indirectComparaisonSection: undefined,
    rationaleCommentSection: undefined,
    patientPopulationSection: undefined,
    treatmentLineSection: undefined,
    // economicEvaluationSection: undefined,
    //secondBudgetImpactSection: undefined,
    // evaluationLinksSectionSection: undefined,
  },
  userOptions: {
    id: undefined,
    userId: undefined,
    pbacSections: undefined,
    pbacHeader: undefined,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PBAC_FILE_LIST: {
      return Object.assign({}, state, {
        listPbac: action.payload,
      });
    }
    case EDIT_FILE_PBAC: {
      return Object.assign({}, state, {
        listPbac: [
          action.payload,
          ...state.listPbac.filter(element => element.id !== action.payload.id),
        ],
        pbacFileToAdd: {id: null},
      });
    }
    case ADD_FILE_PBAC: {
      return Object.assign({}, state, {
        listPbac: [action.payload].concat(state.listPbac),
        pbacFileToAdd: action.payload,
      });
    }
    case DELETE_FILE_PBAC: {
      return Object.assign({}, state, {
        listPbac: [
          ...state.listPbac.filter(element => element.id !== action.payload),
        ],
      });
    }
    default:
      return state;
  }
}
