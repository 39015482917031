/* eslint import/no-anonymous-default-export: "off" */

import {
  GET_FDA_FILE_LIST,
  ADD_FILE_FDA,
  EDIT_FILE_FDA,
  DELETE_FILE_FDA,
  // SUBMIT_NCPE_BY_SECTION,
} from '../../Const/ActionTypes';

const initialState = {
  listFda: [],
  fdaFileToAdd: {
    id: null,
    communInformation: undefined,
    advisoryCommitteeSection: undefined,
    // keyDocumentsSection: undefined,
    nctNumbersSection: undefined,
    patientPopulationSection: null,
    realWorldDataSection: undefined,
    qualityOfLifeSection: undefined,
    indirectComparaisonSection: undefined,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_FDA_FILE_LIST: {
      return Object.assign({}, state, {
        listFda: action.payload,
      });
    }
    case EDIT_FILE_FDA: {
      return Object.assign({}, state, {
        listFda: [
          action.payload,
          ...state.listFda.filter(element => element.id !== action.payload.id),
        ],
        fdaFileToAdd: {id: null},
      });
    }
    case ADD_FILE_FDA: {
      return Object.assign({}, state, {
        listFda: [action.payload].concat(state.listFda),
        fdaFileToAdd: action.payload,
      });
    }
    case DELETE_FILE_FDA: {
      return Object.assign({}, state, {
        listFda: [
          ...state.listFda.filter(element => element.id !== action.payload),
        ],
      });
    }
    default:
      return state;
  }
}
