import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import {
  getUsers,
  getUser,
  deleteUserById,
} from "../../Redux/Actions/UserActions";
import ConfirmDeleteReferentiel from "../CommunComponents/ConfirmDeleteReferentiel";
import AddUser from "./AddUser";
import UsersFileList from "./UsersFileList";

import { setFileStatus } from "../../Redux/Actions/FileSettingActions";
import HelmetComponent from "../CommunComponents/HelmetComponent";

const filePath = "user";

class UserAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openDelete: false,
      userId: null,
      userList: [],
    };

    this.handleOpenDelete = this.handleOpenDelete.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    let initialFileSetting = {
      page: 0,
      rowsPerPage: 20,
    };

    this.props.setFileStatus(initialFileSetting);

    this.props.getUsers();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userList !== this.props.userList) {
      this.setState({ userList: this.props.userList });
    }
  }

  handleOpenDelete(id = null) {
    this.setState((prevState) => ({
      ...prevState,
      userId: id,
      openDelete: !prevState.openDelete,
    }));
  }

  handleDelete = () => {
    this.props.deleteUserById(this.state.userId);

    this.handleOpenDelete();
  };

  render() {
    return (
      <main className="page">
        {this.state.openDelete && (
          <ConfirmDeleteReferentiel
            text="cette utilisateur"
            deleteFunction={this.handleDelete}
            toggleModal={this.handleOpenDelete}
            id={this.state.userId}
          />
        )}

        <HelmetComponent title={filePath} />

        <BrowserRouter>
          <Switch>
            <Route
              exact
              path={`/admin/${filePath}`}
              render={() => <Redirect to={`/admin/${filePath}/list`} />}
            />

            <Route
              exact
              path={`/admin/${filePath}/list`}
              render={() => (
                <UsersFileList
                  listItems={this.props.userList}
                  handleOpenSection={this.handleOpenSection}
                  handleOpenDelete={this.handleOpenDelete}
                  filePath={filePath}
                />
              )}
            />

            <Route
              exact
              path={`/admin/${filePath}/create`}
              render={() => (
                <AddUser
                  handleOpenSection={this.handleOpenSection}
                  type="add"
                  title="Ajout"
                  handleOpenForm={this.handleOpenForm}
                  handleCloseForm={this.handleCloseForm}
                  filePath={filePath}
                />
              )}
            />

            <Route
              exact
              path={`/admin/${filePath}/modify/:fileId`}
              render={() => (
                <UsersFileList
                  listItems={this.props.userList}
                  handleOpenSection={this.handleOpenSection}
                  handleChangeEdit={this.handleChangeEdit}
                  handleChangeDelete={this.handleChangeDelete}
                  userToEdit={this.state.userToEdit}
                  handleOpenForm={this.handleOpenForm}
                  openEdit={this.state.openEdit}
                  handleCloseForm={this.handleCloseForm}
                  type="edit"
                  fromTask={true}
                  filePath={filePath}
                />
              )}
            />
          </Switch>
        </BrowserRouter>
      </main>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userList: state.UserReducer.userList,
    userOptions: state.userOptionsReducer.userOptions,
    fileSetting: state.FileSettingReducer,
  };
};
export default connect(mapStateToProps, {
  getUsers,
  getUser,
  deleteUserById,
  setFileStatus,
})(UserAccount);
