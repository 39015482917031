/* eslint import/no-anonymous-default-export: "off" */

import {
  GET_LIST_DCI,
  ADD_DCI,
  EDIT_DCI,
  DELETE_DCI,
  GET_LIST_DCI_PAGINATED,
} from "../../Const/ActionTypes";

const initialState = {
  listDci: [],
  listDciPaginated: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LIST_DCI: {
      return Object.assign({}, state, {
        listDci: action.payload,
      });
    }
    case GET_LIST_DCI_PAGINATED: {
      return Object.assign({}, state, {
        listDciPaginated: action.payload,
      });
    }
    case ADD_DCI: {
      return Object.assign({}, state, {
        listDci: action.payload.concat(state.listDci),
        listDciPaginated: action.payload.concat(state.listDciPaginated),
      });
    }
    case EDIT_DCI: {
      return Object.assign({}, state, {
        listDci: [
          action.payload,
          ...state.listDci.filter(
            (element) => element.id !== action.payload.id
          ),
        ],
        listDciPaginated: [
          action.payload,
          ...state.listDciPaginated.filter(
            (element) => element.id !== action.payload.id
          ),
        ],
      });
    }
    case DELETE_DCI: {
      return Object.assign({}, state, {
        listDci: [
          ...state.listDci.filter((element) => element.id !== action.payload),
        ],
        listDciPaginated: [
          ...state.listDciPaginated.filter((element) => element.id !== action.payload),
        ],
      });
    }
    default:
      return state;
  }
}
