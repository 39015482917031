/* eslint import/no-anonymous-default-export: "off" */

import {
  GET_NCPE_FILE_LIST,
  ADD_FILE_NCPE,
  EDIT_FILE_NCPE,
  DELETE_FILE_NCPE,
} from '../../Const/ActionTypes';

const initialState = {
  listNcpe: [],
  ncpeFileToAdd: {
    id: null,
    communInformation: undefined,
    keyDocumentsSection: undefined,
    realWorldDataSection: undefined,
    qualityOfLifeSection: undefined,
    indirectComparaisonSection: undefined,
    rationaleCommentSection: undefined,
    secondBudgetImpactSection: undefined,
    economicEvaluationSection: undefined,
    treatmentLineSection: undefined,
    //======== specific section ===========//
    atcSection: undefined,
    linkSection: undefined,
    submissionSection: undefined,
    costSection: undefined,
    clinicalTrialsSection: undefined,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_NCPE_FILE_LIST: {
      return Object.assign({}, state, {
        listNcpe: action.payload,
      });
    }
    case EDIT_FILE_NCPE: {
      return Object.assign({}, state, {
        listNcpe: [
          action.payload,
          ...state.listNcpe.filter(element => element.id !== action.payload.id),
        ],
        ncpeFileToAdd: {id: null},
      });
    }
    case ADD_FILE_NCPE: {
      return Object.assign({}, state, {
        listNcpe: [action.payload].concat(state.listNcpe),
        ncpeFileToAdd: action.payload,
      });
    }

    case DELETE_FILE_NCPE: {
      return Object.assign({}, state, {
        listNcpe: [
          ...state.listNcpe.filter(element => element.id !== action.payload),
        ],
      });
    }
    default:
      return state;
  }
}
