/* eslint import/no-anonymous-default-export: "off" */

import {
  GET_NTCOUNCIL_FILE_LIST,
  ADD_FILE_NTCOUNCIL,
  EDIT_FILE_NTCOUNCIL,
  DELETE_FILE_NTCOUNCIL,
} from '../../Const/ActionTypes';

const initialState = {
  listNtCouncil: [],
  ntCouncilFileToAdd: {
    id: null,
    communInformation: undefined,
    atcSection: undefined,
    indicationSection: undefined,
    recommendationsSection: undefined,
    drugDescriptionSection: undefined,
    assessmentOfTheRoleInTherapySection: undefined,
    ntCouncilAssessmentOfTheDrugsSummarySection: undefined,
    severityOfTheConditionSection: undefined,
    treatmentEffectivenessSection: undefined,
    scientificDocumentationReliabilitySection: undefined,
    rarityOfTheConditionSection: undefined,
    treatmentCostEffectivenessSection: undefined,
    reliabilityOfTheHealthEconomicAssessmentSection: undefined,
    nctNumbersSection: undefined,
    rationaleCommentSection: undefined,
    treatmentLineSection: undefined,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_NTCOUNCIL_FILE_LIST: {
      return Object.assign({}, state, {
        listNtCouncil: action.payload,
      });
    }
    case EDIT_FILE_NTCOUNCIL: {
      return Object.assign({}, state, {
        listNtCouncil: [
          action.payload,
          ...state.listNtCouncil.filter(
            element => element.id !== action.payload.id,
          ),
        ],
        ntCouncilFileToAdd: {id: null},
      });
    }
    case ADD_FILE_NTCOUNCIL: {
      return Object.assign({}, state, {
        listNtCouncil: [action.payload].concat(state.listNtCouncil),
        ntCouncilFileToAdd: action.payload,
      });
    }
    case DELETE_FILE_NTCOUNCIL: {
      return Object.assign({}, state, {
        listNtCouncil: [
          ...state.listNtCouncil.filter(
            element => element.id !== action.payload,
          ),
        ],
      });
    }
    default:
      return state;
  }
}
