/* eslint import/no-anonymous-default-export: "off" */

import {
  GET_APAC_FILE_LIST,
  ADD_FILE_APAC,
  EDIT_FILE_APAC,
  DELETE_FILE_APAC,
} from "../../Const/ActionTypes";

const initialState = {
  listAPAC: [],
  APACFileToAdd: {
    id: null,
    communInformation: undefined,
    motifSection: undefined,
    externalExpertiseSection: undefined,
    initialEvaluationCommission: undefined,
    postAndInscriptionSection: undefined,
    synthesisSection: undefined,
    prescriptionAndIssuanceCond: undefined,
    keyDocumentsSection: undefined,
    contextSection: undefined,
    restrainedIndicationSection: undefined,
    ATUcSection: undefined,
    creteriaAppreciationSection: undefined,
    gravityAndRaretySection: undefined,
    therapeuticStrategySection: undefined,
    comparatorIntroductionSection: undefined,
    comparatorMedicationsSection: undefined,
    comparatorNonMedicatedSection: undefined,
    conclusionSection: undefined,
    appropiateTreatmentsIntroductionSection: undefined,
    appropiateTreatmentsConclusionSection: undefined,
    availableDataSection: undefined,
    treatmentImplementationSection: undefined,
    securityAndEfficacySection: undefined,
    specialityInnovativePresumedSection: undefined,
    innovativePresumedCreteriaSection: undefined,
    patientPopulationSection: undefined,
    commissionRecommandationsSection: undefined,
    inheretedSpecificRequestsSection: undefined,
    dataRequestsSection: undefined,
    ansmSection: undefined,
    collegeSection: undefined,
    commentSection: undefined,
    nctNumbersSection: undefined,
    treatmentLineSection: undefined,
    evalutionLinks: undefined,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_APAC_FILE_LIST: {
      return Object.assign({}, state, {
        listAPAC: action.payload,
      });
    }
    case EDIT_FILE_APAC: {
      return Object.assign({}, state, {
        listAPAC: state.listAPAC.map((element) =>
          element.id === action.payload.id ? action.payload : element
        ),

        APACFileToAdd: { id: null },
      });
    }
    case ADD_FILE_APAC: {
      return Object.assign({}, state, {
        listAPAC: [action.payload].concat(state.listAPAC),
        APACFileToAdd: action.payload,
      });
    }

    case DELETE_FILE_APAC: {
      return Object.assign({}, state, {
        listAPAC: [
          ...state.listAPAC.filter((element) => element.id !== action.payload),
        ],
      });
    }
    default:
      return state;
  }
}
