/* eslint import/no-anonymous-default-export: "off" */

import { GET_NICE_FILE_LIST, ADD_FILE_NICE, EDIT_FILE_NICE, DELETE_FILE_NICE } from "../../Const/ActionTypes";

const initialState = {
  listNice: [],
  niceFileToAdd: {
    id: null,
    communInformation: undefined,
    keyDocumentsSection: undefined,
    commercialArrangementsSection: undefined,
    appraisalConsultationSection: undefined,
    commentsNiceSection: undefined,
    projectHistorySection: undefined,
    eamsSection: undefined,
    appraisalTitleSection: undefined,
    comparatorNameSection: undefined,
    currentPracticeSection: undefined,
    technologySection: undefined,
    evidenceForClinicalEffectivenessSection: undefined,
    additionalFactorsTakenIntoAccountSection: undefined,
    detailsOfTheHealthEconomicStudySection: undefined,
    comporatorSection: undefined,
    populationSection: undefined,
    targetPopulationSection: undefined,
    nctNumbersSection: undefined,
    realWorldDataSection: undefined,
    qualityOfLifeSection: undefined,
    indirectComparaisonSection: undefined,
    rationaleCommentSection: undefined,
    patientPopulationList: undefined,
    treatmentLineSection: undefined,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_NICE_FILE_LIST: {
      return Object.assign({}, state, {
        listNice: action.payload,
      });
    }

    case ADD_FILE_NICE: {
      return Object.assign({}, state, {
        listNice: [action.payload].concat(state.listNice),
        niceFileToAdd: action.payload,
      });
    }
    case EDIT_FILE_NICE: {
      return Object.assign({}, state, {
        listNice: [action.payload, ...state.listNice.filter((element) => element.id !== action.payload.id)],
        niceFileToAdd: { id: null },
      });
    }
    case DELETE_FILE_NICE: {
      return Object.assign({}, state, {
        listNice: [...state.listNice.filter((element) => element.id !== action.payload)],
      });
    }
    default:
      return state;
  }
}
