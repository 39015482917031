/* eslint import/no-anonymous-default-export: "off" */

import {
  GET_ACE_FILE_LIST,
  ADD_FILE_ACE,
  EDIT_FILE_ACE,
  DELETE_FILE_ACE,
} from '../../Const/ActionTypes';

const initialState = {
  listAce: [],
  ACEFileToAdd: {
    id: null,
    communInformation: undefined,
    indicationUnderReviewSection: undefined,
    guidanceRecommandationSection: undefined,
    technologyEvaluationSection: undefined,
    additionalConsiderationsSection: undefined,
    recommandationSection: undefined,
    economicEvaluationSection: undefined,
    clinicalAspectsSection: undefined,
    nctNumbersSection: undefined,
    secondBudgetImpactSection: undefined,
    realWorldDataSection: undefined,
    qualityOfLifeSection: undefined,
    indirectComparaisonSection: undefined,
    rationaleCommentSection: undefined,
    treatmentLineSection: undefined,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ACE_FILE_LIST: {
      return Object.assign({}, state, {
        listAce: action.payload,
      });
    }

    case ADD_FILE_ACE: {
      return Object.assign({}, state, {
        listAce: [action.payload].concat(state.listAce),
        ACEFileToAdd: action.payload,
      });
    }
    case EDIT_FILE_ACE: {
      return Object.assign({}, state, {
        listAce: [
          action.payload,
          ...state.listAce.filter(element => element.id !== action.payload.id),
        ],
        ACEFileToAdd: {id: null},
      });
    }
    case DELETE_FILE_ACE: {
      return Object.assign({}, state, {
        listAce: [
          ...state.listAce.filter(element => element.id !== action.payload),
        ],
      });
    }
    default:
      return state;
  }
}
