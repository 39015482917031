import {
  ADD_FILE_PRICING_SPAIN,
  DELETE_FILE_PRICING_SPAIN,
  EDIT_FILE_PRICING_SPAIN,
  GET_PRICING_SPAIN_FILE_LIST,
} from "../../Const/ActionTypes";

const initialState = {
  listPricingSpain: [],
  pricingSpainFileToAdd: {
    id: null,
    communInformation: undefined,
    dataMssi: undefined,
    dataBotPlus: undefined,
    dataNomenclator: undefined,
    evalutionLinksSection: undefined,
  },
};

export default function pricingSpain(state = initialState, action) {
  switch (action.type) {
    case GET_PRICING_SPAIN_FILE_LIST: {
      return Object.assign({}, state, {
        listPricingSpain: action.payload,
      });
    }
    case EDIT_FILE_PRICING_SPAIN: {
      return Object.assign({}, state, {
        listPricingSpain: state.listPricingSpain.map((element) =>
          element.id === action.payload.id ? action.payload : element
        ),

        pricingSpainFileToAdd: { id: null },
      });
    }
    case ADD_FILE_PRICING_SPAIN: {
      return Object.assign({}, state, {
        listPricingSpain: [action.payload].concat(state.listPricingSpain),
        pricingSpainFileToAdd: action.payload,
      });
    }

    case DELETE_FILE_PRICING_SPAIN: {
      return Object.assign({}, state, {
        listPricingSpain: [
          ...state.listPricingSpain.filter(
            (element) => element.id !== action.payload
          ),
        ],
      });
    }
    default:
      return state;
  }
}
