/* eslint import/no-anonymous-default-export: "off" */

import { GET_ITALY_FILE_LIST, ADD_FILE_ITALY, EDIT_FILE_ITALY, DELETE_FILE_ITALY } from "../../Const/ActionTypes";

const initialState = {
  listItaly: [],
  italyFileToAdd: {
    id: null,
    communInformation: {
      htcDecision: "",
      htcDecisionDate: new Date(),
      reimbursement: "",
      reimbursementDate: new Date(),
    },
    //-----global sections
    treatmentLineSection: undefined,
    patientPopulationSection: undefined,
    keyDocumentsSection: undefined,
    // -------- sections communes pour AIFA et VENETO
    projectHistorySection: undefined,
    innovativeStatusSection: undefined,
    //--------------------
    nationalRegionalType: "",
    // -------- sections   AIFA
    nationalRegionalAIFA: undefined,
    gazzettaUfficialeSection: undefined,
    earlyAccessSection: undefined,
    managedEntryAgreementSection: undefined,
    indicationAndCommitSection: undefined,
    // -------- sections   VENETO
    nationalRegionalVENETO: undefined,
    evidenceAndIndicationSection: undefined,
    conclusionListSection: undefined,
    targetPopulationSection: undefined,
    nctNumbersSection: undefined,
    priceAndCostOfTreatmentSection: undefined,
    clinicalRationaleSection: undefined,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ITALY_FILE_LIST: {
      return Object.assign({}, state, {
        listItaly: action.payload,
      });
    }
    case EDIT_FILE_ITALY: {
      return Object.assign({}, state, {
        listItaly: [action.payload, ...state.listItaly.filter((element) => element.id !== action.payload.id)],
        italyFileToAdd: { id: null },
      });
    }
    case ADD_FILE_ITALY: {
      return Object.assign({}, state, {
        listItaly: [action.payload].concat(state.listItaly),
        italyFileToAdd: action.payload,
      });
    }
    case DELETE_FILE_ITALY: {
      return Object.assign({}, state, {
        listItaly: [...state.listItaly.filter((element) => element.id !== action.payload)],
      });
    }
    default:
      return state;
  }
}
