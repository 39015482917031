/* eslint import/no-anonymous-default-export: "off" */
import {
  GET_CATEGORY_LIST,
  ADD_CATEGORY,
  EDIT_CATEGORY,
  GET_CATEGORY_LIST_PAGINATED,
} from "../../Const/ActionTypes";
const initialState = {
  categoryList: [],
  categoryListPaginated: [],
};
export default function (state = initialState, action) {
  if (action.type === GET_CATEGORY_LIST) {
    return Object.assign({}, state, {
      categoryList: action.payload,
    });
  }
  if (action.type === GET_CATEGORY_LIST_PAGINATED) {
    return Object.assign({}, state, {
      categoryListPaginated: action.payload,
    });
  }
  if (action.type === ADD_CATEGORY) {
    return Object.assign({}, state, {
      categoryList: action.payload.concat(state.categoryList),
      categoryListPaginated: action.payload.concat(state.categoryList),
    });
  }
  if (action.type === EDIT_CATEGORY) {
    return Object.assign({}, state, {
      categoryList: [
        action.payload,
        ...state.categoryList.filter(
          (element) => element.id !== action.payload.id
        ),
      ],
      categoryListPaginated: [
        action.payload,
        ...state.categoryListPaginated.filter(
          (element) => element.id !== action.payload.id
        ),
      ],
    });
  }

  return state;
}
