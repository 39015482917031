import React, { Suspense, useEffect } from "react";
import { Redirect, Route, Switch, BrowserRouter } from "react-router-dom";

import ConfirmDelete from "./CommunComponents/ConfirmDelete";
import ConfirmDuplicate from "./CommunComponents/ConfirmDuplicate";
import NavBar from "./CommunComponents/NavBar";

import UserAccount from "./AccountMangement/UserAccount";

import { useDispatch, useSelector } from "react-redux";

import { getDciList } from "../Redux/Actions/DciActions";
import { getActiveIngredient } from "../Redux/Actions/ActiveIngredientActions";
import { getSpecialityList } from "../Redux/Actions/SpecialityActions";
import { getDiseaseList } from "../Redux/Actions/DiseaseActions";
import { getCategory } from "../Redux/Actions/CategoryActions";
import {
  getUserList,
  getUserOptions,
} from "../Redux/Actions/UserOptionsActions";
import { getUserInfo } from "../Redux/Actions/AuthActions";

import {
  americaRoutes,
  apacRoutes,
  ecoClinicalRoutes,
  europeRoutes,
  franceRoutes,
  pricingRoutes,
  refApplicatifRoutes,
} from "../Const/Routes";
import {
  getDataManagement,
  getFiles,
} from "../Redux/Actions/CommunFilesAction";
import Spinner from "./Loading/Spinner/Spinner";
import ErrorBoundary from "./Error/ErrorBoundary";
import NotFound from "./CommunComponents/NotFound";

const basePath = "/admin";

function AdminRoute() {
  const dispatch = useDispatch();
  const { page, rowsPerPage, getAction, fileApi } = useSelector(
    ({ FileSettingReducer }) => FileSettingReducer
  );
  const { pageModal, rowsPerPageModal, fileApiModal, getActionTypeModal } =
    useSelector(({ FileSettingReducer }) => FileSettingReducer.modalAttributes);

  useEffect(() => {
    dispatch(getDciList());
    dispatch(getActiveIngredient());
    dispatch(getSpecialityList());
    dispatch(getDiseaseList());
    dispatch(getCategory());
    dispatch(getUserOptions(0));
    dispatch(getUserList());

    dispatch(getUserInfo(localStorage.token));
  }, [dispatch]);

  useEffect(() => {
    if (getAction) {
      const isDataManagement = [
        "dcis",
        "indexations",
        "drug-categories",
        "chemical-ingredients",
        "drug-specialities",
      ].includes(fileApi);

      isDataManagement
        ? dispatch(
            getDataManagement(
              fileApi,
              getAction,
              rowsPerPage,
              page * rowsPerPage
            )
          )
        : dispatch(
            getFiles(fileApi, getAction, rowsPerPage, page * rowsPerPage)
          );
    }
  }, [dispatch, page, rowsPerPage, getAction, fileApi]);

  useEffect(() => {
    if (getActionTypeModal) {
      dispatch(
        getFiles(
          fileApiModal,
          getActionTypeModal,
          rowsPerPageModal,
          pageModal * rowsPerPageModal
        )
      );
    }
  }, [dispatch, pageModal, rowsPerPageModal, getActionTypeModal, fileApiModal]);

  return (
    <BrowserRouter>
      <NavBar />
      <ConfirmDelete />
      <ConfirmDuplicate />
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route
            exact
            path={basePath}
            render={() => <Redirect to={`${basePath}/webboard/task`} />}
          />

          {franceRoutes.map(({ path, Component }, index) => (
            <Route
              exact
              key={`${path}-${index}`}
              path={[
                `${basePath}/${path}`,
                `${basePath}/${path}/list`,
                `${basePath}/${path}/create`,
                `${basePath}/${path}/modify/:fileId`,
              ]}
            >
              <ErrorBoundary>
                <Component />
              </ErrorBoundary>
            </Route>
          ))}

          {europeRoutes.map(({ path, Component }, index) => (
            <Route
              key={`${path}-${index}`}
              path={[
                `${basePath}/${path}`,
                `${basePath}/${path}/list`,
                `${basePath}/${path}/create`,
                `${basePath}/${path}/modify/:fileId`,
              ]}
            >
              <ErrorBoundary>
                <Component />
              </ErrorBoundary>
            </Route>
          ))}

          {americaRoutes.map(({ path, Component }, index) => (
            <Route
              key={`${path}-${index}`}
              path={[
                `${basePath}/${path}`,
                `${basePath}/${path}/list`,
                `${basePath}/${path}/create`,
                `${basePath}/${path}/modify/:fileId`,
              ]}
            >
              <ErrorBoundary>
                <Component />
              </ErrorBoundary>
            </Route>
          ))}

          {apacRoutes.map(({ path, Component }, index) => (
            <Route
              key={`${path}-${index}`}
              path={[
                `${basePath}/${path}`,
                `${basePath}/${path}/list`,
                `${basePath}/${path}/create`,
                `${basePath}/${path}/modify/:fileId`,
              ]}
            >
              <ErrorBoundary>
                <Component />
              </ErrorBoundary>
            </Route>
          ))}

          {ecoClinicalRoutes.map(({ path, Component }, index) => (
            <Route
              key={`${path}-${index}`}
              path={[
                `${basePath}/${path}`,
                `${basePath}/${path}/list`,
                `${basePath}/${path}/create`,
                `${basePath}/${path}/modify/:fileId`,
              ]}
            >
              <ErrorBoundary>
                <Component />
              </ErrorBoundary>
            </Route>
          ))}

          {pricingRoutes.map(({ path, Component }, index) => (
            <Route
              key={`${path}-${index}`}
              path={[
                `${basePath}/${path}`,
                `${basePath}/${path}/list`,
                `${basePath}/${path}/create`,
                `${basePath}/${path}/modify/:fileId`,
              ]}
            >
              <ErrorBoundary>
                <Component />
              </ErrorBoundary>
            </Route>
          ))}

          {refApplicatifRoutes.map(({ path, Component }, index) => (
            <Route
              key={`${path}-${index}`}
              path={[
                `${basePath}/${path}`,
                `${basePath}/${path}/list`,
                `${basePath}/${path}/create`,
                `${basePath}/${path}/modify/:fileId`,
              ]}
            >
              <ErrorBoundary>
                <Component />
              </ErrorBoundary>
            </Route>
          ))}

          <Route
            path={[
              `${basePath}/user`,
              `${basePath}/user/list`,
              `${basePath}/user/create`,
              `${basePath}/user/modify/:fileId`,
            ]}
          >
            <ErrorBoundary>
              <UserAccount />
            </ErrorBoundary>
          </Route>

          <Route path="*" exact component={NotFound} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default AdminRoute;
