import {
  ADD_FILE_PRICING_AUSTRALIE,
  DELETE_FILE_PRICING_AUSTRALIE,
  EDIT_FILE_PRICING_AUSTRALIE,
  GET_PRICING_AUSTRALIE_FILE_LIST,
} from "../../Const/ActionTypes";

const initialState = {
  listPricingAustralie: [],
  pricingAustralieFileToAdd: {
    id: null,
    communInformation: undefined,
    pricing: undefined,
    evalutionLinksSection: undefined,
  },
};

export default function pricingAustralie(state = initialState, action) {
  switch (action.type) {
    case GET_PRICING_AUSTRALIE_FILE_LIST: {
      return Object.assign({}, state, {
        listPricingAustralie: action.payload,
      });
    }
    case EDIT_FILE_PRICING_AUSTRALIE: {
      return Object.assign({}, state, {
        listPricingAustralie: state.listPricingAustralie.map((element) =>
          element.id === action.payload.id ? action.payload : element
        ),

        pricingAustralieFileToAdd: { id: null },
      });
    }
    case ADD_FILE_PRICING_AUSTRALIE: {
      return Object.assign({}, state, {
        listPricingAustralie: [action.payload].concat(
          state.listPricingAustralie
        ),
        pricingAustralieFileToAdd: action.payload,
      });
    }

    case DELETE_FILE_PRICING_AUSTRALIE: {
      return Object.assign({}, state, {
        listPricingAustralie: [
          ...state.listPricingAustralie.filter(
            (element) => element.id !== action.payload
          ),
        ],
      });
    }
    default:
      return state;
  }
}
