import React, { Component } from "react";
import { connect } from "react-redux";
import CommunAlerte from "../CommunComponents/CommunAlerte";
import TablePagination from "../CommunComponents/TablePagination";
import {
  setFileStatus,
  displayNotFoundPage,
} from "../../Redux/Actions/FileSettingActions";
import UserAccountHeader from "./UserAccountHeader";
import UsersFileListItem from "./UsersFileListItem";
import AddUser from "./AddUser";
import UsersTableHead from "./UsersTableHead";
import { withRouter } from "react-router-dom";

class UsersFileList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userToEdit: undefined,
    };
  }

  handleChangeEdit = (row) => {
    this.setState({
      userToEdit: row,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.fromTask && prevProps.listItems !== this.props.listItems) {
      const fileId = this.props.match.params?.fileId;

      let file = this.props.listItems.find((e) => e.id === fileId);

      if (file) {
        this.setState({ userToEdit: file });
      } else {
        this.props.history.push(`/admin/${this.props.filePath}`);

        this.props.displayNotFoundPage(fileId);
      }
    }
  }

  componentDidMount() {
    if (this.props.fromTask && this.props.listItems.length > 0) {
      const fileId = this.props.match.params?.fileId;

      let file = this.props.listItems.find((e) => e.id === fileId);

      if (file) {
        this.setState({ userToEdit: file });
      } else {
        this.props.history.push(`/admin/${this.props.filePath}`);

        this.props.displayNotFoundPage(fileId);
      }
    }
  }

  render() {
    const { listItems: rows, headerItems, filePath, type } = this.props;

    const { page, rowsPerPage } = this.props.fileSetting;

    return this.props.activeAction !== false &&
      type === "edit" &&
      this.state.userToEdit?.id ? (
      <AddUser
        type="edit"
        handleOpenSection={this.props.handleOpenSection}
        userToEdit={this.state.userToEdit}
        title="Modification"
        handleOpenForm={this.props.handleOpenForm}
        handleCloseForm={this.props.handleCloseForm}
        filePath={filePath}
      />
    ) : (
      <section className="dashboard">
        <div className="dashboard__container _container">
          <div className="dashboard__wrapper">
            <CommunAlerte />
            <UserAccountHeader filePath={filePath} />
            <div className="dashboard__table-wrapper">
              <table className="dashboard__table">
                <UsersTableHead />
                <tbody className="dashboard__body">
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <UsersFileListItem
                        row={row}
                        key={index}
                        headerItems={headerItems}
                        handleChangeEdit={this.handleChangeEdit}
                        handleOpenDelete={this.props.handleOpenDelete}
                        filePath={filePath}
                      />
                    ))}
                </tbody>
              </table>
            </div>
          </div>

          <TablePagination rowsLength={rows?.length ?? 0} />
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fileSetting: state.FileSettingReducer,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setFileStatus,
    displayNotFoundPage,
  })(UsersFileList)
);
