import {
  ADD_FILE_PRICING_INAMI,
  DELETE_FILE_PRICING_INAMI,
  EDIT_FILE_PRICING_INAMI,
  GET_PRICING_INAMI_FILE_LIST,
} from "../../Const/ActionTypes";

const initialState = {
  listPricingINAMI: [],
  pricingINAMIFileToAdd: {
    id: null,
    communInformation: undefined,
    checkPerson: undefined,
    niveauUsine: undefined,
    modalitesRemboursement: undefined,
    evalutionLinks: undefined,
  },
};

export default function pricingINAMI(state = initialState, action) {
  switch (action.type) {
    case GET_PRICING_INAMI_FILE_LIST: {
      return Object.assign({}, state, {
        listPricingINAMI: action.payload,
      });
    }
    case EDIT_FILE_PRICING_INAMI: {
      return Object.assign({}, state, {
        listPricingINAMI: state.listPricingINAMI.map((element) =>
          element.id === action.payload.id ? action.payload : element
        ),

        pricingINAMIFileToAdd: { id: null },
      });
    }
    case ADD_FILE_PRICING_INAMI: {
      return Object.assign({}, state, {
        listPricingINAMI: [action.payload].concat(state.listPricingINAMI),
        pricingINAMIFileToAdd: action.payload,
      });
    }

    case DELETE_FILE_PRICING_INAMI: {
      return Object.assign({}, state, {
        listPricingINAMI: [
          ...state.listPricingINAMI.filter(
            (element) => element.id !== action.payload
          ),
        ],
      });
    }
    default:
      return state;
  }
}
