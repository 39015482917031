/* eslint import/no-anonymous-default-export: "off" */

import {
  GET_USER_OPTIONS,
  EDIT_USER_OPTIONS,
  GET_USER_LIST,
} from "../../Const/ActionTypes";

const initialState = {
  userOptions: {
    id: undefined,
    userId: undefined,

    // TLV
    tlvSections: undefined,
    tlvHeader: undefined,

    // NCPE
    ncpeSections: undefined,
    ncpeHeader: undefined,

    // NT Council
    ntCouncilSections: undefined,
    ntCouncilHeader: undefined,

    // AWMSG
    awmsgSections: undefined,
    awmsgHeader: undefined,

    // Transparecny
    transparencySections: undefined,
    transparencyHeader: undefined,

    // Pricing Germany
    pricingGermanySections: undefined,
    pricingGermanyHeader: undefined,

    // Pricing Spain
    pricingSpainSections: undefined,
    pricingSpainHeader: undefined,

    // Pricing Korea
    pricingKoreaSections: undefined,
    pricingKoreaHeader: undefined,

    // Pricing Italy
    pricingItalySections: undefined,
    pricingItalyHeader: undefined,

    // Pricing Sweeden
    pricingSweedenSections: undefined,
    pricingSweedenHeader: undefined,

    // Pricing Brésil
    pricingBresilSections: undefined,
    pricingBresilHeader: undefined,

    // Pricing Japan
    pricingJapanSections: undefined,
    pricingJapanHeader: undefined,

    // Pricing INAMI
    pricingINAMISections: undefined,
    pricingINAMIHeader: undefined,
    // Pricing Iqwig
    iqwigSections: undefined,
    iqwigHeader: undefined,
    // Pricing pCODR
    pCODRSections: undefined,
    pCODRHeader: undefined,
    // Pricing CADTH
    CADTHSections: undefined,
    CADTHHeader: undefined,
    // Pricing PERU
    PERUSections: undefined,
    PERUHeader: undefined,
     // Pricing GBA
     GBASections: undefined,
     GBAHeader: undefined,
  },
  usersList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USER_OPTIONS: {
      return Object.assign({}, state, {
        userOptions: action.payload,
      });
    }
    case EDIT_USER_OPTIONS: {
      return Object.assign({}, state, {
        userOptions: { ...state.userOptions, ...action.payload },
      });
    }
    case GET_USER_LIST: {
      return Object.assign({}, state, {
        usersList: action.payload,
      });
    }
    default: {
      return state;
    }
  }
}
