/* eslint import/no-anonymous-default-export: "off" */

import {
    GET_NMPA_FILE_LIST,
    ADD_FILE_NMPA,
    EDIT_FILE_NMPA,
    DELETE_FILE_NMPA,
  } from '../../Const/ActionTypes';
  
  const initialState = {
    listNmpa: [],
    NMPAFileToAdd: {
      id: null,
     
    },
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_NMPA_FILE_LIST: {
        return Object.assign({}, state, {
          listNmpa: action.payload,
        });
      }
  
      case ADD_FILE_NMPA: {
        return Object.assign({}, state, {
          listNmpa: [action.payload].concat(state.listNmpa),
          NMPAFileToAdd: action.payload,
        });
      }
      case EDIT_FILE_NMPA: {
        return Object.assign({}, state, {
          listNmpa: [
            action.payload,
            ...state.listNmpa.filter(element => element.id !== action.payload.id),
          ],
          NMPAFileToAdd: {id: null},
        });
      }
      case DELETE_FILE_NMPA: {
        return Object.assign({}, state, {
          listNmpa: [
            ...state.listNmpa.filter(element => element.id !== action.payload),
          ],
        });
      }
      default:
        return state;
    }
  }
  