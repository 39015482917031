import React from "react";

function TableCellValueUsers({ fileItem, columnName }) {
  
  return (
    <td >
      <div style={{maxWidth:"100%"}} className="dashboard__item">
        {columnName.label === "ID"
          ? fileItem.id
          : columnName.label === "Nom"
          ? fileItem.lastName
          : columnName.label === "Prénom"
          ? fileItem.firstName
          : columnName.label === "Rôle"
          ? fileItem.role
          : columnName.label === "Statut"
          ? fileItem.status
          : columnName.label === "Société"
          ? fileItem.society
          : columnName.label === "Email"
          ? fileItem.email
          : columnName.label === "Pays"
          ? fileItem.country
          : columnName.label === "Jours restants"
          ? fileItem.days
          : ""}
      </div>
    </td>
  );
}

export default TableCellValueUsers;
