/* eslint import/no-anonymous-default-export: "off" */

import {
  GET_AGENCY_FILE_LIST,
  ADD_FILE_AGENCY,
  EDIT_FILE_AGENCY,
  DELETE_FILE_AGENCY,
  GET_COUNTRIES,
} from "../../Const/ActionTypes";

const initialState = {
  listAgency: [],
  AgencyFileToAdd: {
    id: null,
    AgencyInformation: undefined,
    communInformation: undefined,
    atcSection: undefined,
    keyDocumentsSection: undefined,
    indicationSection: undefined,
    indicationReimbursedSection: undefined,
    nctNumbersSection: undefined,
    rationaleCommentSection: undefined,
    patientPopulationSection: undefined,
    treatmentLineSection: undefined,
    evalutionLinks: undefined,
  },

  countries: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_AGENCY_FILE_LIST: {
      return Object.assign({}, state, {
        listAgency: action.payload,
      });
    }
    case EDIT_FILE_AGENCY: {
      return Object.assign({}, state, {
        listAgency: state.listAgency.map((element) =>
          element.id === action.payload.id ? action.payload : element
        ),
        peruFileToAdd: { id: null },
      });
    }
    case ADD_FILE_AGENCY: {
      return Object.assign({}, state, {
        listAgency: [action.payload].concat(state.listAgency),
        AgencyFileToAdd: action.payload,
      });
    }

    case GET_COUNTRIES: {
      return Object.assign({}, state, {
        countries: [...action.payload]
      });
    }

    case DELETE_FILE_AGENCY: {
      return Object.assign({}, state, {
        listAgency: [
          ...state.listAgency.filter(
            (element) => element.id !== action.payload
          ),
        ],
      });
    }
    default:
      return state;
  }
}
