import axios from "axios";
import {
  GET_LIST_DCI,
  ADD_DCI,
  EDIT_DCI,
  SHOW_SUCCESS_MESSAGE,
  HIDE_SUCCESS_MESSAGE,
  SHOW_ERROR_MESSAGE,
  HIDE_ERROR_MESSAGE,
  SHOW_ERROR_MESSAGE_LIST,
  DELETE_DCI,
} from "../../Const/ActionTypes";
import baseUrl from "../../Config/Config";
import _ from "lodash";
import { setLoading, unsetLoading } from "./UIActions";
import { decrementCount, incrementCount } from "./CommunFilesAction";

export const editDci = (data) => {
  return (dispatch) => {
    axios
      .patch(`${baseUrl.baseUrl}/dcis/` + data.id, data)
      .then((res) => {
        dispatch({
          type: EDIT_DCI,
          payload: res.data,
        });
        dispatch({
          type: SHOW_SUCCESS_MESSAGE,
          payload: "La modification est effectuée avec succès",
        });
        setTimeout(() => {
          dispatch({ type: HIDE_SUCCESS_MESSAGE });
        }, 4000);
      })
      .catch((err) => {
        console.log(err.response);
        let errorMsg = err.response
          ? err.response.data?.error?.message
          : "Merci  de réessayer ultérieurement , une erreur s'est produite de notre coté";

        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: errorMsg,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      });
  };
};
export const addDci = (data) => {
  return (dispatch) => {
    dispatch(setLoading());

    axios
      .post(`${baseUrl.baseUrl}/dcis`, data)
      .then((res) => {
        if (res.data.success.length > 0) {
          dispatch({
            type: ADD_DCI,
            payload: res.data.success,
          });
          dispatch({
            type: SHOW_SUCCESS_MESSAGE,
            payload:
              "The creation of " +
              res.data.success.length +
              " element/s is successful",
          });

          dispatch(incrementCount());

          setTimeout(() => {
            dispatch({ type: HIDE_SUCCESS_MESSAGE });
          }, 4000);
        }

        if (res.data.failed.length > 0) {
          dispatch({
            type: SHOW_ERROR_MESSAGE_LIST,
            payload: _.uniq(res.data.failed),
          });
          setTimeout(() => {
            dispatch({ type: HIDE_ERROR_MESSAGE });
          }, 4000);
        }
      })
      .catch((err) => {
        let errorMsg = err.response
          ? err.response.data?.error?.message
          : "Merci  de réessayer ultérieurement , une erreur s'est produite de notre coté";

        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: errorMsg,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      })
      .finally(() => {
        dispatch(unsetLoading());
      });
  };
};

export const getDciList = (data) => {
  let filter = JSON.stringify({
    fields: {
      id: true,
      name: true,
    },
  });
  return (dispatch) => {
    axios
      .get(`${baseUrl.baseUrl}/dcis?filter=${filter}`)
      .then((res) => {
        dispatch({
          type: GET_LIST_DCI,
          payload: res.data.reverse(),
        });
      })
      .catch((err) => {
        console.log("error message", err.message);
      });
  };
};

export const deleteDci = (id) => {
  return (dispatch) => {
    dispatch(setLoading());

    axios
      .delete(`${baseUrl.baseUrl}/dcis/${id}`)
      .then((res) => {
        dispatch({
          type: DELETE_DCI,
          payload: id,
        });
        dispatch({
          type: SHOW_SUCCESS_MESSAGE,
          payload: "La suppression a été effectuée avec succès",
        });

        dispatch(decrementCount());

        setTimeout(() => {
          dispatch({ type: HIDE_SUCCESS_MESSAGE });
        }, 4000);
      })
      .catch((err) => {
        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      })
      .finally(() => {
        dispatch(unsetLoading());
        window.scrollTo(0, 0);
      });
  };
};
