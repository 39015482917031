/* eslint import/no-anonymous-default-export: "off" */

import {
  ADD_FILE_pCODR,
  DELETE_FILE_pCODR,
  EDIT_FILE_pCODR,
  GET_pCODR_FILE_LIST,
} from "../../Const/ActionTypes";

const initialState = {
  listpCODR: [],
  pCODRFileToAdd: {
    id: undefined,
    communInformation: undefined,
    strengthSection: undefined,
    initialRecommendationSection: undefined,
    commentSection: "",
    summaryOfPCODRDeliberationSection: undefined,
    pcodrEconomicOutcome: undefined,
    pcodrClinicalOutcome: undefined,
    contextOfTheResubmission: undefined,
    evidenceInBrief: undefined,
    comparatorNameSection: undefined,
    contextualInformation: undefined,
    overallClinicalBenefitSection: undefined,
    patientBasedValuesSection: undefined,
    adoptionFeasibilitySection: undefined,
    targetPopulationSection: undefined,
    additionalInformationSection: undefined,
    detailsOfTheHealthEconomicStudySection: undefined,
    keyDocumentsSection: undefined,
    realWorldDataSection: undefined,
    qualityOfLifeSection: undefined,
    indirectComparaisonSection: undefined,
    rationaleCommentSection: undefined,
    patientPopulationSection: undefined,
    treatmentLineSection: undefined,
    nctNumbersSection: undefined,
    evalutionLinks: undefined,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_pCODR_FILE_LIST: {
      return Object.assign({}, state, {
        listpCODR: action.payload,
      });
    }

    case ADD_FILE_pCODR: {
      return Object.assign({}, state, {
        listpCODR: [action.payload].concat(state.listpCODR),
        pCODRFileToAdd: action.payload,
      });
    }
    case EDIT_FILE_pCODR: {
      return Object.assign({}, state, {
        listpCODR: [
          action.payload,
          ...state.listpCODR.filter(
            (element) => element.id !== action.payload.id
          ),
        ],
        pCODRFileToAdd: { id: null },
      });
    }
    case DELETE_FILE_pCODR: {
      return Object.assign({}, state, {
        listpCODR: [
          ...state.listpCODR.filter((element) => element.id !== action.payload),
        ],
      });
    }
    default:
      return state;
  }
}
