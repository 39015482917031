/* eslint import/no-anonymous-default-export: "off" */

import { GET_EMA_FILE_LIST, ADD_FILE_EMA, EDIT_FILE_EMA, DELETE_FILE_EMA } from "../../Const/ActionTypes";

const initialState = {
  listEma: [],
  emaFileToAdd: {
    id: null,
    communInformation: undefined,
    keyDocumentsSection: undefined,
    etatSection: undefined,
    indicationSection: undefined,
    commentSection: undefined,
    realWorldDataSection: undefined,
    qualityOfLifeSection: undefined,
    indirectComparaisonSection: undefined,
    patientPopulationSection: undefined,
    treatmentLineSection: undefined,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EMA_FILE_LIST: {
      return Object.assign({}, state, {
        listEma: action.payload,
      });
    }
    case EDIT_FILE_EMA: {
      return Object.assign({}, state, {
        listEma: [action.payload, ...state.listEma.filter((element) => element.id !== action.payload.id)],
        emaFileToAdd: {
          id: null,
        },
      });
    }
    case ADD_FILE_EMA: {
      return Object.assign({}, state, {
        listEma: [action.payload].concat(state.listEma),
        emaFileToAdd: action.payload,
      });
    }
    case DELETE_FILE_EMA: {
      return Object.assign({}, state, {
        listEma: [...state.listEma.filter((element) => element.id !== action.payload)],
      });
    }
    default:
      return state;
  }
}
