/* eslint import/no-anonymous-default-export: "off" */

import { GET_AOTMIT_FILE_LIST, ADD_FILE_AOTMIT, EDIT_FILE_AOTMIT, DELETE_FILE_AOTMIT } from "../../Const/ActionTypes";

const initialState = {
  listAOTMIT: [],
  atomitFileToAdd: {
    id: null,
    communInformation: undefined,
    submissionToAotmitSection: undefined,
    icdSection: undefined,
    indicationSection: undefined,
    guidanceSection: undefined,
    statusSection: undefined,
    conclusionSection: undefined,
    rationnalJustificationSection: undefined,
    efficacySection: undefined,
    safetySection: undefined,
    costEffectivenessSection: undefined,
    otherSection: undefined,
    // keyDocumentsSection: undefined,
    rationaleCommentSection: undefined,
    nctNumbersSection: undefined,
    treatmentLineSection: undefined,
    // evaluationLinksSectionSection: undefined,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_AOTMIT_FILE_LIST: {
      return Object.assign({}, state, {
        listAOTMIT: action.payload,
      });
    }
    case EDIT_FILE_AOTMIT: {
      return Object.assign({}, state, {
        listAOTMIT: [action.payload, ...state.listAOTMIT.filter((element) => element.id !== action.payload.id)],
        atomitFileToAdd: { id: null },
      });
    }
    case ADD_FILE_AOTMIT: {
      return Object.assign({}, state, {
        listAOTMIT: [action.payload].concat(state.listAOTMIT),
        atomitFileToAdd: action.payload,
      });
    }
    case DELETE_FILE_AOTMIT: {
      return Object.assign({}, state, {
        listAOTMIT: [...state.listAOTMIT.filter((element) => element.id !== action.payload)],
      });
    }
    default:
      return state;
  }
}
