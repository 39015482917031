import React, { Component } from "react";
import { connect } from "react-redux";
import { permissionList } from "../../Const/selectListValues";
import _ from "lodash";
import {
  submitUser,
  editUser,
  deleteUserById,
} from "../../Redux/Actions/UserActions";
import AddUserGeneralInfos from "./AccountManagementComponents/AddUserGeneralInfos";
import RequiredFieldsModal from "../CommunComponents/RequiredFieldsModal";
import ConfirmSubmit from "../CommunComponents/ConfirmSubmit";
import moment from "moment";
import Region from "./AccountManagementComponents/Region";
import { Link, withRouter } from "react-router-dom";
const allTrue = {
  create: true,
  update: true,
  duplicate: true,
  delete: true,
};

const allFalse = {
  create: false,
  update: false,
  duplicate: false,
  delete: false,
};

const initialState = {
  page: 0,
  rowsPerPage: 10,
  openRegions: {
    France: false,
    Europe: false,
    APAC: false,
    America: false,
  },
  permissions: permissionList,
  allSelected: false,
  userInfos: {
    firstName: "",
    lastName: "",
    status: "inactive",
    email: "",
    occupation: "",
    society: "",
    country: "",
    role: "",
    dateSessionStart: null,
    dateSessionEnd: null,
    ipAddress: "",
    language: "",
    ipAuthentified: 0,
    shownInterface: true,
    agencyAffected: false,
    fileLimited: false,
    authorizedFileList: [],
  },
  errors: {
    status: false,
    message: "",
  },

  globalSave: false,
};

const reg = ["France", "Europe", "APAC", "America"];
class AddUser extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  componentDidMount() {
    if (!_.isEmpty(this.props.userToEdit)) {
      let newPermission = this.state.permissions.map((permission) => {
        let oldPermission = this.props.userToEdit.authorizedFileList.find(
          (e) => e.agency === permission.agency
        );
        if (oldPermission) {
          return {
            agencyLabel: permission.agencyLabel,
            region: permission.region,
            ...oldPermission,
          };
        } else {
          return permission;
        }
      });
      this.setState({
        userInfos: { ...this.props.userToEdit },
        permissions: newPermission,
        openRegions: {
          France: true,
          Europe: true,
          APAC: true,
          America: true,
        },
      });
    }
  }

  handleDateChange = (name, date) => {
    this.setState({
      userInfos: {
        ...this.state.userInfos,
        [name]: date === null ? null : moment(date).format("YYYY-MM-DD"),
      },
    });
  };

  handleChange = (event) => {
    let userInfos = {
      ...this.state.userInfos,
      [event.target.name]: event.target.value,
    };
    this.setState({ userInfos });
  };

  handleChangeCheckBox = (event) => {
    const name = event.target.name;
    const isAgencyAffected = name === "agencyAffected" ? true : false;
    const isFileLimited = name === "fileLimited" ? true : false;

    let value;

    if (isAgencyAffected || isFileLimited) {
      value = event.target.checked ? true : false;
    } else {
      value = event.target.checked ? 1 : 0;
    }

    let userInfos = {
      ...this.state.userInfos,
      [name]: value,
    };
    this.setState({ userInfos });
  };

  ValidateEmail = (email) => {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email.match(validRegex)) {
      return true;
    } else {
      return false;
    }
  };

  ValidateIPaddress = (ipaddress) => {
    if (
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
        ipaddress
      )
    ) {
      return true;
    }
    return false;
  };

  isAfter = (date1, date2) => {
    let d1 = moment(new Date(date1));
    let d2 = moment(new Date(date2));
    return d1 > d2;
  };

  isAfterToday = (date) => {
    let d = moment(new Date(date));
    const today = new Date();
    today.setHours(23, 59, 59, 998);
    return d > today;
  };

  validateDate = (date1, date2) => {
    return this.isAfter(date1, date2) && this.isAfterToday(date1);
  };

  handleModalConfirm = () => {
    let values = this.state.userInfos;
    let test =
      (!values.ipAuthentified && values.ipAddress !== "") ||
      (!values.ipAuthentified && values.ipAddress === "") ||
      (values.ipAddress && values.ipAddress !== "");
    if (
      values.firstName !== "" &&
      values.lastName !== "" &&
      values.email !== "" &&
      values.occupation !== "" &&
      values.role !== "" &&
      values.status !== "" &&
      values.language !== "" &&
      values.dateSessionStart &&
      values.dateSessionEnd &&
      test
    ) {
      if (!this.ValidateEmail(values.email)) {
        this.setState({
          errors: {
            status: true,
            message: "Email invalide!",
          },
        });
      } else if (
        values.ipAddress &&
        !this.ValidateIPaddress(values.ipAddress)
      ) {
        this.setState({
          errors: {
            status: true,
            message: "Adresse ip invalide!",
          },
        });
      } else if (
        !this.validateDate(values.dateSessionEnd, values.dateSessionStart)
      ) {
        this.setState({
          errors: {
            status: true,
            message:
              "La date de fin de la session doit être postérieure à la date de début et ne dois pas être au passée!",
          },
        });
      } else {
        this.setState({
          globalSave: true,
        });
      }
    } else {
      this.setState({
        errors: {
          status: true,
          message: "Vous n'avez pas terminé les champs obligatoires !",
        },
      });
    }
  };

  handleCancel = () => {
    this.setState({
      globalSave: false,
      partielSave: false,
      nameSectionToSubmit: "",
      labelSectionToEdit: "",
      dataSectionToSubmit: undefined,
    });
  };

  handleAllSelected = (event) => {
    this.setState({
      allSelected: event.target.checked,
    });
    let permissions = event.target.checked
      ? this.state.permissions.map((element) => {
          return { ...element, ...allTrue };
        })
      : this.state.permissions.map((element) => {
          return { ...element, ...allFalse };
        });

    this.setState({ permissions });
  };

  handleChangeOpenRegion = (name) => {
    let openRegions = {
      ...this.state.openRegions,
      [name]: !this.state.openRegions[name],
    };
    this.setState({ openRegions });
  };

  handleChangePermissions = (agencyName, event) => {
    let newPermissions = this.state.permissions.map((el) => {
      return el.agencyLabel === agencyName
        ? { ...el, [event.target.name]: event.target.checked }
        : el;
    });
    this.setState({ permissions: newPermissions });
  };

  handleGlobalAction = (region, action, event) => {
    let newPermissions = this.state.permissions.map((el) => {
      return el.region === region
        ? { ...el, [action]: event.target.checked }
        : el;
    });

    this.setState({ permissions: newPermissions });
  };

  handleSubmit = () => {
    let authorizedFileList = this.state.permissions
      .filter((e) => e.create || e.update || e.duplicate || e.delete)
      .map((permissionEle) => {
        let res = _.omit(permissionEle, ["agencyLabel", "region"]);

        return res;
      });

    let newUser =
      this.props.type === "add"
        ? {
            ...this.state.userInfos,
            authorizedFileList,
            creationDate: new Date(),
          }
        : {
            ...this.state.userInfos,
            authorizedFileList,
          };
    let finalUser =
      this.props.type === "add" ? newUser : _.omit(newUser, "id", "days");

    this.props.type === "add"
      ? this.props.submitUser(finalUser, this.state.globalSave)
      : this.props.editUser(
          this.props.userToEdit.id,
          finalUser,
          this.state.globalSave
        );

    this.props.history.push(`/admin/${this.props.filePath}`);
    window.scrollTo(0, 0);
  };

  render() {
    const values = this.state;

    const { filePath } = this.props;

    return (
      <section className="settings settings_line">
        {this.state.errors.status && (
          <RequiredFieldsModal
            handleCancel={() =>
              this.setState({
                errors: {
                  status: false,
                  message: "",
                },
              })
            }
            open={this.state.errors.status}
            modalMessage={this.state.errors.message}
          />
        )}

        {this.state.globalSave && (
          <ConfirmSubmit
            handleSubmit={this.handleSubmit}
            handleCancel={this.handleCancel}
            open={this.state.globalSave}
            modalMessage={
              "Vous allez confirmer les données saisies pour cet utilisateur"
            }
          />
        )}

        <div className="spollers__item">
          <div className="settings__container _container">
            <div className="settings__wrapper">
              <div className="dashboard__actions actions-dashboard">
                <div className="actions-dashboard__left">
                  <h1 className="dashboard__title title">
                    {this.props.title} d'un utilisateur
                  </h1>
                </div>
                <div className="actions-dashboard__right">
                  <div className="actions-settings__buttons actions-settings__buttons_line">
                    <Link
                      className="actions-settings__back button button-empty _icon-back"
                      to={`/admin/${filePath}/list`}
                    >
                      Back
                    </Link>
                  </div>
                </div>
              </div>
              <AddUserGeneralInfos
                values={values}
                handleChange={this.handleChange}
                handleChangeCheckBox={this.handleChangeCheckBox}
                handleDateChange={this.handleDateChange}
                type={this.props.type}
              />
              {values?.userInfos?.agencyAffected ? (
                <div className="form-settings__column">
                  <div className="form-settings__label">Sélectionner tout</div>
                  <div className="form-settings__checker">
                    <input
                      className="checkbox _icon-checkbox"
                      type="checkbox"
                      checked={values.permissions.every(
                        (e) => e.create && e.update && e.duplicate && e.delete
                      )}
                      onChange={(e) => this.handleAllSelected(e)}
                      name="allSelected"
                    />
                  </div>
                </div>
              ) : (
                ""
              )}

              {values.userInfos.agencyAffected && (
                <div className="form-settings__columns form-settings__columns_start">
                  <div
                    className="form-settings__column"
                    style={{ width: "150px" }}
                  />
                  <div className="form-settings__column checkbox_container">
                    Créer
                  </div>
                  <div className="form-settings__column checkbox_container">
                    Modifier
                  </div>
                  <div className="form-settings__column checkbox_container">
                    Duppliquer
                  </div>
                  <div className="form-settings__column checkbox_container">
                    Supprimer
                  </div>
                </div>
              )}

              {reg.map(
                (e, key) =>
                  values.userInfos.agencyAffected && (
                    <Region
                      key={key}
                      regionName={e}
                      values={values}
                      handleChangeOpenRegion={this.handleChangeOpenRegion}
                      handleChangePermissions={this.handleChangePermissions}
                      handleGlobalAction={this.handleGlobalAction}
                    />
                  )
              )}

              <button
                className="settings__saveall button _icon-save"
                onClick={() => this.handleModalConfirm()}
              >
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, {
    submitUser,
    editUser,
    deleteUserById,
  })(AddUser)
);
