/* eslint import/no-anonymous-default-export: "off" */

import {
  GET_GBA_FILE_LIST,
  ADD_FILE_GBA,
  EDIT_FILE_GBA,
  DELETE_FILE_GBA,
} from "../../Const/ActionTypes";

const initialState = {
  listGba: [],
  gbaFileToAdd: {
    id: null,
    communInformation: undefined,
    indicationUnderReview: undefined,
    additionalBenefitSection: undefined,
    deadline: undefined,
    commentaire: undefined,
    numberPatientsCriteriaTreatment: undefined,
    keyDocumentsSection: undefined,
    evalutionLinks: undefined,
    nctNumbersSection: undefined,
    resultsBenefitAssessment: undefined,
    conclusion: undefined,
    wasIndirectTreatmentComparisonBeingSubmitted: undefined,
    rationaleCommentSection: undefined,
    routinePracticeDataCollectionAccordingParSGB: undefined,
    qualityOfLifeSection: undefined,
    indirectComparaisonSection: undefined,
    treatmentLineSection: undefined,
    pzn: undefined,
    evidenceTransferSection: undefined,
    costsOfTreatmentSection: undefined,
    patientPopulationSection: undefined,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_GBA_FILE_LIST: {
      return Object.assign({}, state, {
        listGba: action.payload,
      });
    }
    case EDIT_FILE_GBA: {
      return Object.assign({}, state, {
        listGba: state.listGba.map((element) =>
          element.id === action.payload.id ? action.payload : element
        ),
        gbaFileToAdd: { id: null },
      });
    }
    case ADD_FILE_GBA: {
      return Object.assign({}, state, {
        listGba: [action.payload].concat(state.listGba),
        gbaFileToAdd: action.payload,
      });
    }

    case DELETE_FILE_GBA: {
      return Object.assign({}, state, {
        listGba: [
          ...state.listGba.filter((element) => element.id !== action.payload),
        ],
      });
    }
    default:
      return state;
  }
}
