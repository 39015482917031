import axios from "axios";
import moment from "moment";

import {
  SHOW_SUCCESS_MESSAGE,
  HIDE_SUCCESS_MESSAGE,
  SHOW_ERROR_MESSAGE,
  HIDE_ERROR_MESSAGE,
  SUBMIT_USER,
  GET_USERS,
  GET_USER,
  EDIT_USER_BY_ID,
  DELETE_USER_BY_ID,
} from "../../Const/ActionTypes";

import baseUrl from "../../Config/Config";
import _ from "lodash";

export const submitUser = (data, globalSave) => {
  return (dispatch) => {
    axios
      .post(`${baseUrl.baseUrl}/users/signup`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((res) => {
        let newData = makeUsers([res.data]);
        let newObject = _.head(newData);

        dispatch({
          type: SHOW_SUCCESS_MESSAGE,
          payload: "La création a été effectuée avec succès",
        });
        dispatch({
          type: SUBMIT_USER,
          payload: newObject,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_SUCCESS_MESSAGE });
        }, 4000);
      })
      .catch((err) => {
        if (err?.response?.status === 409) {
          dispatch({
            type: SHOW_ERROR_MESSAGE,
            payload: "Email déjà existant",
          });
        } else {
          dispatch({
            type: SHOW_ERROR_MESSAGE,
            payload: globalSave
              ? "Une erreur est survenue lors de la création merci d'essayer à nouveau"
              : "Veuillez remplir tous les champs obligatoires!",
          });
        }

        setTimeout(() => {
          dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      })
      .finally(() => window.scrollTo(0, 0));
  };
};

export const editUser = (id, data, globalSave) => {
  return (dispatch) => {
    axios
      .put(`${baseUrl.baseUrl}/users/${id}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then(() => {
        let b = moment(new Date(data.dateSessionStart));
        let c = moment(new Date(data.dateSessionEnd));
        let days = 1 + c.diff(b, "days");
        dispatch({
          type: EDIT_USER_BY_ID,
          payload: { id: id, days: days, ...data },
        });
        dispatch({
          type: SHOW_SUCCESS_MESSAGE,
          payload: "La modification a été effectuée avec succès",
        });

        setTimeout(() => {
          dispatch({ type: HIDE_SUCCESS_MESSAGE });
        }, 4000);
      })
      .catch((err) => {
        if (err?.response?.status === 409) {
          dispatch({
            type: SHOW_ERROR_MESSAGE,
            payload: "Email déjà existant",
          });
        } else {
          dispatch({
            type: SHOW_ERROR_MESSAGE,
            payload: globalSave
              ? "Une erreur est survenue lors de la création merci d'essayer à nouveau"
              : "Veuillez remplir tous les champs obligatoires!",
          });
        }
        setTimeout(() => {
          dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      })
      .finally(() => window.scrollTo(0, 0));
  };
};

const isActive = (str1, str2) => {
  let res = [];
  if (str1 && str1.length > 0 && str2 && str2.length > 0) {
    let b = moment(new Date(str1));
    let c = moment(new Date(str2));

    let days = 1 + c.diff(b, "days");

    days < 0 ? res.push("inactive", 0) : res.push("Active", days);
  } else {
    res.push("inactive", 0);
  }
  return res;
};
const makeUsers = (data) => {
  let users = [];
  data.map((row) => {
    let days = isActive(row.dateSessionStart, row.dateSessionEnd)[1];
    //let status = isActive(row.dateSessionStart, row.dateSessionEnd)[0];
    let newRow = Object.assign({
      ...row,
      days: days,
      //  status: status,
    });
    users.push(newRow);
    return null;
  });
  return users;
};

export const getUsers = () => {
  return (dispatch) => {
    axios
      .get(`${baseUrl.baseUrl}/users/`)
      .then((res) => {
        let newData = makeUsers(res.data);
        let dataStored = newData.sort(function (a, b) {
          return new Date(b.creationDate) - new Date(a.creationDate);
        });
        dispatch({
          type: GET_USERS,
          payload: dataStored,
        });
      })

      .catch((err) => {
        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      })
      .finally(() => window.scrollTo(0, 0));
  };
};

export const getUser = (id) => {
  return (dispatch) => {
    axios
      .get(`${baseUrl.baseUrl}/users/${id}`)
      .then((res) => {
        if (res.data !== undefined) {
          dispatch({
            type: GET_USER,
            payload: res.data,
          });
        }
      })
      // .then((res)=>{
      //   dispatch({
      //     type: GET_USER_PERMISSIONS,
      //     payload: res.data.permissons,
      //   });
      // })
      .catch((err) => {
        // console.log("error message: ", err.message);

        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      })
      .finally(() => window.scrollTo(0, 0));
  };
};

export const deleteUserById = (id) => {
  return (dispatch) => {
    axios
      .delete(`${baseUrl.baseUrl}/users/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: DELETE_USER_BY_ID,
          payload: id,
        });
        dispatch({
          type: SHOW_SUCCESS_MESSAGE,
          payload: "La suppression a été effectuée avec succès",
        });
        setTimeout(() => {
          dispatch({ type: HIDE_SUCCESS_MESSAGE });
        }, 4000);
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        // alert("An error occured: ", err.message);

        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      })
      .finally(() => window.scrollTo(0, 0));
  };
};

//--------------------------------------- unused functions --------------
