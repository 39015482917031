import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import AdminRoute from "./AdminRoute";

class MainApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: true,
      jwtToken: null,
    };
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/" exact render={() => <Redirect to="/admin" />} />
          
          <Route>
            <AdminRoute />
          </Route>
        </Switch>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.AuthReducer.authToken,
  };
};

export default connect(mapStateToProps, {})(MainApp);
